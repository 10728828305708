<template>
  <div class="summaryTablePage">
      <PocTable
        v-loading="queryLoading"
        :data="tableAllData"
        size="mini"
        :max-height="99999"
        hidepage
        stripe
        :cell-style="funclass"
        class-name="columnClass"
        ref="multipleAllTable"
      >
        <!--   -->
        <!-- :row-style="funclass"  -->
        <el-table-column
          v-for="(item,index) in columnOptions"
          :key="index"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          :align="item.align"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="isEdit(scope.row) && !['title','totalCost'].includes(item.key)" type="number"
              v-model="tableAllData[scope.$index][item.key]"  @input="compute(item.key,scope)"></el-input>

            <span v-else :class="{'title-column':item.key==='title','total-column':item.key==='total','format-number':item.dataType === 'number'}"
              >{{ item.dataType == 'number' ? currency(scope.row[item.key]):scope.row[item.key]}}</span
            >
          </template>
        </el-table-column>
      </PocTable>
    </div>
</template>

<script>
const { Vuex } = $PCommon
const { mapGetters } = Vuex

import { summaryColumnConfig,tableTitle } from '../../editionData/summaryData'
import currency from "currency.js";
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},
  props: {
    Op111FilterConfig: {
      type: Object
    },
    editionId: {
      type: Number
    },
    summaryStatus:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 260 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      tableAllData: [], //合计的列表
      tableData: [],
      columnConfig: summaryColumnConfig,

      apiAlltable: {},
      year:'2024',
      nowMonth: new Date().getMonth() + 1
    }
  },
  computed: {
    columnOptions() {
      return summaryColumnConfig(this.year)
    }
  },

  created() {
    this.year = this.$route.query.indexYear
    this.filterQuery()
  },
  methods: {
    currency(value){
      return currency(value,{symbol:''}).format()
    },
    dealDis(month) {
      return month < this.nowMonth;
    },
    isEdit(row){
      if( (['revenceAndPc',
        // 'op141',
        // 'op162',
        // 'op163',
        // 'op164',
        // 'op165',
        // 'op166',
        // 'op172',
        // 'op161',
        // 'op171',
        // 'op168',
        // 'op10RevenceAndPc',
        // 'op10TotalCostMonth',
      ].includes(row.key) )){
        return true
      }else {
        return false
      }

    },
    //计算 PC%
    compute(key,scope){
      console.log(this.tableAllData)
      if(!scope.row[key]){
        scope.row[key] = 0
      }
      let data = scope.row
      let total = Number(data.jan) + Number(data.feb) + Number(data.mar) + Number(data.apr) + Number(data.may) + Number(data.jun) + Number(data.jul) + Number(data.aug) + Number(data.sep) + Number(data.oct) + Number(data.dec) + Number(data.nov)
      scope.row.totalCost = total
      if(scope.row.key === 'revenceAndPc' && Number(data[key]) !== 0){
        let pcIndex = -1
        let totalCostMonthIndex = -1
        this.tableAllData.forEach((a,index)=>{
          if(a.key === 'pc'){
            pcIndex = index
          }else if(a.key === 'totalCostMonth'){
            totalCostMonthIndex = index
          }
        })
        if(pcIndex > 0 && totalCostMonthIndex > 0){
          this.tableAllData[pcIndex][key] = Number(Number(this.tableAllData[totalCostMonthIndex][key])/Number(data[key])).toFixed(2)
        }
        let keys = [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'dec',
          'nov',
        ]
        let tableAllData = 0
        keys.forEach(a=>{
          if(Number(this.tableAllData[pcIndex][a] !== 0 )){
            tableAllData = tableAllData + Number(this.tableAllData[pcIndex][a])
          }
        })
        this.tableAllData[pcIndex]['totalCost'] = tableAllData
      }
      // let totalCostMonth = 0
      // let revence = 0
      // let index = -1
      // let totalIndex = -1
      // for (let i = 0; i < this.tableAllData.length; i++) {
      //   if(this.tableAllData[i].key === 'totalCostMonth'){
      //     totalCostMonth = this.tableAllData[i][key]
      //   }
      //   if(this.tableAllData[i].key === 'revenceAndPc'){
      //     revence = this.tableAllData[i][key]
      //     totalIndex = i
      //   }
      //   if(this.tableAllData[i].key === 'pc'){
      //     index = i
      //   }
      // }
      // // PC%=（Fixed Personnel Costs + Freelance）/Revenue
      // this.tableAllData[index][key] = Number(revence) === 0 ? 0 : (Number(totalCostMonth) / Number(revence)).toFixed(2) * 100 + '%'
      // let data = this.tableAllData[totalIndex]
      // let total = Number(data.jan) + Number(data.feb) + Number(data.mar) + Number(data.apr) + Number(data.may) + Number(data.jun) + Number(data.jul) + Number(data.aug) + Number(data.sep) + Number(data.oct) + Number(data.dec) + Number(data.nov)
      // this.tableAllData[totalIndex].totalCost = total
      // let op10Revence = JSON.parse(JSON.stringify(this.tableAllData[index-1]))
      // let op10PC = JSON.parse(JSON.stringify(this.tableAllData[index]))
      // op10Revence.id = 'op10Revence'
      // op10Revence.key = 'op10Revence'
      // op10PC.id = 'op10PC'
      // op10PC.key = 'op10PC'
      // this.tableAllData[this.tableAllData.length-1] = op10PC
      // this.tableAllData[this.tableAllData.length-2] = op10Revence
    },
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.queryApi()
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true

      // console.log('+++++++',this.filterConfig)
      let data = []
      let code = 100
      if(this.summaryStatus){
        const res = await this.$Api.salary.getSumVersionSummaryHistoryDetail({
          ...this.Op111FilterConfig,
          id: this.editionId,
          ...this.pageConfig
        })
        data = res.data
        code = res.code
      }else {
        const res = await this.$Api.salary.getSumaryDetail({
          ...this.Op111FilterConfig,
          id: this.editionId,
          ...this.pageConfig
        })
        data = res.data
        code = res.code
      }
      if (code === 200) {
        console.log('---------', data)
        this.tableAllData = []
        let revence = ''
        let pc = ''
        let editRow = ['revenceAndPc',
          // 'op141',
          // 'op162',
          // 'op163',
          // 'op164',
          // 'op165',
          // 'op166',
          // 'op172',
          // 'op161',
          // 'op171',
          // 'op168',
          // 'op10RevenceAndPc',
          // 'op10TotalCostMonth'
        ]
        console.log(data);
        Object.keys(data).forEach(i=> {
          if(editRow.includes(i) ){
            if(i==='op10TotalCostMonth'){
              let a = 'op10t'
              data[i] = {
                jan: data.detail[`${a}Jan`] || 0,
                feb: data.detail[`${a}Feb`] || 0,
                mar: data.detail[`${a}Mar`] || 0,
                apr: data.detail[`${a}Apr`] || 0,
                may: data.detail[`${a}May`] || 0,
                jun: data.detail[`${a}Jun`] || 0,
                jul: data.detail[`${a}Jul`] || 0,
                aug: data.detail[`${a}Aug`] || 0,
                sep: data.detail[`${a}Sep`] || 0,
                oct: data.detail[`${a}Oct`] || 0,
                dec: data.detail[`${a}Dec`] || 0,
                nov: data.detail[`${a}Nov`] || 0,
                totalCost: data.detail[`${a}Total`] || 0,
              }
            }else if(i==='op10RevenceAndPc'){
              let a = 'revenueDown'
              data[i] = {
                jan: data.detail[`${a}Jan`] || 0,
                feb: data.detail[`${a}Feb`] || 0,
                mar: data.detail[`${a}Mar`] || 0,
                apr: data.detail[`${a}Apr`] || 0,
                may: data.detail[`${a}May`] || 0,
                jun: data.detail[`${a}Jun`] || 0,
                jul: data.detail[`${a}Jul`] || 0,
                aug: data.detail[`${a}Aug`] || 0,
                sep: data.detail[`${a}Sep`] || 0,
                oct: data.detail[`${a}Oct`] || 0,
                dec: data.detail[`${a}Dec`] || 0,
                nov: data.detail[`${a}Nov`] || 0,
                totalCost: data.detail[`${a}Total`] || 0,
              }
            }else {
              data[i] = {
                jan: data.detail[`${i}Jan`] || 0,
                feb: data.detail[`${i}Feb`] || 0,
                mar: data.detail[`${i}Mar`] || 0,
                apr: data.detail[`${i}Apr`] || 0,
                may: data.detail[`${i}May`] || 0,
                jun: data.detail[`${i}Jun`] || 0,
                jul: data.detail[`${i}Jul`] || 0,
                aug: data.detail[`${i}Aug`] || 0,
                sep: data.detail[`${i}Sep`] || 0,
                oct: data.detail[`${i}Oct`] || 0,
                dec: data.detail[`${i}Dec`] || 0,
                nov: data.detail[`${i}Nov`] || 0,
                totalCost: data.detail[`${i}Total`] || 0,
              }
            }
          }
          if(data[i]){
            if(i==='detail'||i==='revenueDown'||i==='revenue'||i==='totalCostMonth'||i==='op10RevenceAndPc'){
              return
            }
            if(i === 'op152'){
              let obj = {
                id: data[i].id,
                key: i,
                title: tableTitle[i],
                jan: data[i].jan || 0,
                feb: data[i].feb || 0,
                mar: data[i].mar || 0,
                apr: data[i].apr || 0,
                may: data[i].may || 0,
                jun: data[i].jun || 0,
                jul: data[i].jul || 0,
                aug: data[i].aug || 0,
                sep: data[i].sep || 0,
                oct: data[i].oct || 0,
                dec: data[i].dec || 0,
                nov: data[i].nov || 0,
                totalCost: data[i].totalCost || 0,
              }
              let a = 'revenue'
              let obj1 = {
                id: data.detail.id,
                key: 'revenceAndPc',
                title: tableTitle['revenceAndPc'],
                jan: data.detail[`${a}Jan`] || 0,
                feb: data.detail[`${a}Feb`] || 0,
                mar: data.detail[`${a}Mar`] || 0,
                apr: data.detail[`${a}Apr`] || 0,
                may: data.detail[`${a}May`] || 0,
                jun: data.detail[`${a}Jun`] || 0,
                jul: data.detail[`${a}Jul`] || 0,
                aug: data.detail[`${a}Aug`] || 0,
                sep: data.detail[`${a}Sep`] || 0,
                oct: data.detail[`${a}Oct`] || 0,
                dec: data.detail[`${a}Dec`] || 0,
                nov: data.detail[`${a}Nov`] || 0,
                totalCost: data.detail[`${a}Total`] || 0,
              }
              let obj3 = {
                id: data['totalCostMonth'].id,
                key: 'totalCostMonth',
                title: tableTitle['totalCostMonth'],
                jan: data['totalCostMonth'].jan || 0,
                feb: data['totalCostMonth'].feb || 0,
                mar: data['totalCostMonth'].mar || 0,
                apr: data['totalCostMonth'].apr || 0,
                may: data['totalCostMonth'].may || 0,
                jun: data['totalCostMonth'].jun || 0,
                jul: data['totalCostMonth'].jul || 0,
                aug: data['totalCostMonth'].aug || 0,
                sep: data['totalCostMonth'].sep || 0,
                oct: data['totalCostMonth'].oct || 0,
                dec: data['totalCostMonth'].dec || 0,
                nov: data['totalCostMonth'].nov || 0,
                totalCost: data['totalCostMonth'].totalCost || 0,
              }
              let obj2 = {
                id: 'pc',
                key: 'pc',
                title: tableTitle['pc'],
                totalCost: null,
                jan: null,
                feb: null,
                mar: null,
                apr: null,
                may: null,
                jun: null,
                jul: null,
                aug: null,
                sep: null,
                oct: null,
                dec: null,
                nov: null,
              }
              revence = obj
              pc = obj1
              this.tableAllData.push(obj)
              this.tableAllData.push(obj3)
              this.tableAllData.push(obj1)
              this.tableAllData.push(obj2)
            }else if(i==='op10RevenceAndPc'){
              let obj = {
                id: i,
                key: i,
                title: tableTitle[i],
                jan: data[i].jan || 0,
                feb: data[i].feb || 0,
                mar: data[i].mar || 0,
                apr: data[i].apr || 0,
                may: data[i].may || 0,
                jun: data[i].jun || 0,
                jul: data[i].jul || 0,
                aug: data[i].aug || 0,
                sep: data[i].sep || 0,
                oct: data[i].oct || 0,
                dec: data[i].dec || 0,
                nov: data[i].nov || 0,
                totalCost: data[i].totalCost || 0,
              }
              let obj2 = {
                id: 'pc',
                key: 'pc10',
                title: tableTitle['pc'],
                totalCost: null,
                jan: null,
                feb: null,
                mar: null,
                apr: null,
                may: null,
                jun: null,
                jul: null,
                aug: null,
                sep: null,
                oct: null,
                dec: null,
                nov: null,
              }
              // this.tableAllData.push(obj)
              // this.tableAllData.push(obj2)
            }else {
              let obj = {
                id: i,
                key: i,
                title: tableTitle[i],
                jan: data[i].jan || 0,
                feb: data[i].feb || 0,
                mar: data[i].mar || 0,
                apr: data[i].apr || 0,
                may: data[i].may || 0,
                jun: data[i].jun || 0,
                jul: data[i].jul || 0,
                aug: data[i].aug || 0,
                sep: data[i].sep || 0,
                oct: data[i].oct || 0,
                dec: data[i].dec || 0,
                nov: data[i].nov || 0,
                totalCost: data[i].totalCost || 0,
              }
              this.tableAllData.push(obj)
            }
          }else {
            if(i==='detail'||i==='revenueDown'||i==='revenue'||i==='totalCostMonth'||i==='op10RevenceAndPc'){
              return
            }
            let obj = {
              id: i,
              key: i,
              title: tableTitle[i],
              jan: 0.00,
              feb: 0.00,
              mar: 0.00,
              apr: 0.00,
              may: 0.00,
              jun: 0.00,
              jul: 0.00,
              aug: 0.00,
              sep: 0.00,
              oct: 0.00,
              dec: 0.00,
              nov: 0.00,
              totalCost: 0.00,
            }
            if(i === 'revenceAndPc'){
              this.tableAllData.push(obj)
              let obj1 = {
                id: 'pc',
                key: 'pc',
                title: tableTitle['pc'],
                jan: 0.00,
                feb: 0.00,
                mar: 0.00,
                apr: 0.00,
                may: 0.00,
                jun: 0.00,
                jul: 0.00,
                aug: 0.00,
                sep: 0.00,
                oct: 0.00,
                dec: 0.00,
                nov: 0.00,
                totalCost: 0.00,
              }
              this.tableAllData.push(obj1)
            }else if(i === 'op10RevenceAndPc'){
              this.tableAllData.push(obj)
              let obj1 = {
                id: 'op10PC',
                key: 'op10PC',
                title: tableTitle['op10PC'],

              }
              // this.tableAllData.push(obj1)
            }else{
               // this.tableAllData.push(obj)
            }
          }
        })
        // let op10Revence = JSON.parse(JSON.stringify(revence))
        // let op10PC = JSON.parse(JSON.stringify(pc))
        // if(op10Revence){
        //   op10Revence.id = 'op10Revence'
        //   op10Revence.key = 'op10Revence'
        //   op10PC.id = 'op10PC'
        //   op10PC.key = 'op10PC'
        //   this.tableAllData.push(op10Revence)
        //   this.tableAllData.push(op10PC)
        // }


        let pcIndex = -1
        let totalCostMonthIndex = -1
        let revenceAndPcIndex = -1
        this.tableAllData.forEach((a,index)=>{
          if(a.key === 'pc'){
            pcIndex = index
          }else if(a.key === 'totalCostMonth'){
            totalCostMonthIndex = index
          }else if(a.key === 'revenceAndPc'){
            revenceAndPcIndex = index
          }
        })
        let keys = [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'dec',
          'nov',
        ]
        let tableAllData = 0
        if(pcIndex > 0 && totalCostMonthIndex > 0 && revenceAndPcIndex > 0){
          keys.forEach(a=>{
            if(Number(this.tableAllData[revenceAndPcIndex][a])!== 0){
              this.tableAllData[pcIndex][a] = Number(Number(this.tableAllData[totalCostMonthIndex][a]) / Number(this.tableAllData[revenceAndPcIndex][a])).toFixed(2)
              tableAllData = tableAllData + Number(this.tableAllData[pcIndex][a])
            }
          })
          this.tableAllData[pcIndex]['totalCost'] = tableAllData
        }
        console.log(this.tableAllData);
      }
      this.queryLoading = false
    },
    getData(){
       let paramsName={hcPlugVo:'hcPlug',internHcVo:'internHc',internVo:'intern',pcPlugVo:'pcPlug',salaryIncreaseAdditionalVo:'salaryIncreaseAdditional',salaryIncreaseVo:'salaryIncrease'}
      let editSumData={}
      this.tableAllData.map(v=>{
        if(v.title!='HC in HFM'&&v.title!='Total Cost'){
          for(let a in v){
            if(a!='title'&&a!='originTitle'&&a!='salaryFullYear'){
              let frontAname=a.split('totalCost')[1];
               editSumData[`${paramsName[v.originTitle]}${frontAname}`]=v[a];
            }
          }
        }
      })


         console.log('============',this.apiAlltable.id)
        editSumData['id']=this.apiAlltable.id;
        editSumData['versionId']=this.apiAlltable.versionId;

        return editSumData

    },
    funclass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          color: 'black',
          'font-weight': 'Blob',
          'font-Size': '16px'
        }
      }
    },
    funColumn({ row, column, rowIndex, columnIndex }) {
      if (
        column.label == 'Salary Full year' ||
        column.label == 'Sub-Total Base payroll'
      ) {
        return {
          background: 'rgba(186,151,101,0.2)',
          'font-weight': 'Blob',
          'font-Size': '16px',
          color: '#ba9765',
          'font-style': 'italic'
        }
      }
    }
  },
  beforeDestroy() {},
  mounted() {
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.summaryTablePage{
  margin-top: 16px;
}
.title-column{
  color: #BA9765;
}
.total-column{
  color: #000000;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.AlCenter {
  align-items: center;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.iconImg180 {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.flex {
  display: flex;
}
.columnClass {
}
.editGreenData {
  color: #538a51;
}
.editRedData {
  color: red;
}
.editAfterData {
  color: #ba9765;
}
.opeartionBox {
  padding: 7px 0 16px 0px;
  box-sizing: border-box;
}
.scopeItemBox {
  width: 170px;
}
.scopeItem {
  width: 130px;
  // padding: 0 20px 0 20px;
  // margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;

  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}
.active {
  color: #303133;
  text-align: center;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 170px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.format-number{
  margin-right: 31px;
}
</style>
<style lang="scss">
.el-table__indent {
  padding-left: 0!important;
}
</style>
