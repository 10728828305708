<template>
  <el-dialog
    title="IC Charge"
    :visible.sync="dialogTableVisible"
    v-if="dialogTableVisible"
    width="65%"
    custom-class="ChargeOp131"
    append-to-body
    :before-close="close"
    :close-on-click-modal="false"
  >
    <div class="ChargeOp131__content">
      <div style="margin-bottom: 18px">
        <el-radio v-model="formData.chargeType" :label="0">Charge In</el-radio>
        <el-radio v-model="formData.chargeType" :label="1">Charge Out</el-radio>
      </div>
      <el-form  :model="formData"
             inline
             label-position="right"
             label-width="80px"
             size="small"
             ref="selectIcChargeTableForm">
        <el-form-item label="选择HC" v-if="formData.chargeType===1">
          <el-input
            suffix-icon="el-icon-search"
            @click.native="openHc"
            placeholder="选择HC"
            v-model="formData.hcName"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="formData.isOverseasChargeIn" v-if="formData.chargeType===0">海外 Charge In</el-checkbox>
          <el-checkbox v-model="formData.isOverseasChargeOut" v-if="formData.chargeType===1">海外 Charge Out</el-checkbox>
        </el-form-item>
      </el-form>
      <el-form  :model="formData"
                inline
                label-position="right"
                label-width="80px"
                size="small"
      >
        <div class="flexBet">
          <el-form-item label="From HRBP" class="flex-1">
            <el-input
              suffix-icon="el-icon-search"
              v-model="formData.chargeFromBpName"
              placeholder="From HRBP"
              clearable
              @focus="selectPersonal('HRBP','from')"
              @clear="clearHrbp('from')"
            >
            <!-- :disabled="formData.chargeType===1" -->
            </el-input>
          </el-form-item>
          <el-form-item label="From HFM" class="flex-1">
            <el-select
              v-model="formData.chargeFromHfm"
              clearable
              filterable
              placeholder="From HFM"
              @change="changeFromHfm"
            >
              <!-- :disabled="formData.chargeType===1" -->
              <el-option
                v-for="item in hfmCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="From Entity" class="flex-1">
            <el-select
              v-model="formData.chargeFromEntity"
              clearable
              filterable
              placeholder="From Entity"
            >
              <!-- :disabled="formData.chargeType===1" -->
              <el-option
                v-for="item in legalEntitys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="From CEO" class="flex-1">
            <el-input
              v-model="formData.fromCeoName"
              suffix-icon="el-icon-search"
              @click.native="selectPersonal('CEO','from','ceo')"
              placeholder="请选择"
              readonly
            />
          </el-form-item>
        </div>
        <div class="flexBet">
          <el-form-item label="To HRBP" class="flex-1">
            <el-input
              suffix-icon="el-icon-search"
              v-model="formData.chargeToBpName"
              placeholder="To HRBP"
              clearable
              @focus="selectPersonal('HRBP','to')"
              @clear="clearHrbp('to')" 
            >
            <!-- :disabled="formData.chargeType===0" -->
            </el-input>
          </el-form-item>
          <el-form-item label="To HFM" class="flex-1">
            <el-select
              v-model="formData.chargeToHfm"
              clearable
              filterable
              placeholder="To HFM"
              @change="changeToHfm"
            >
            <!-- :disabled="formData.chargeType===0 && chooseTab != '1'" -->
              <el-option
                v-for="item in hfmCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="To Entity" class="flex-1">
            <el-select
              v-model="formData.chargeToEntity"
              clearable
              filterable
              placeholder="To Entity"
            >
              <el-option
                v-for="item in legalEntitys1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="To CEO" class="flex-1">
            <el-input
              v-model="formData.toCeoName"
              suffix-icon="el-icon-search"
              @click.native="selectPersonal('CEO','to','ceo')"
              placeholder="请选择"
              readonly
            />
          </el-form-item>
        </div>
      </el-form>
      <el-form  :model="formData"
                label-position="right"
                label-width="80px"
                size="small"
                class="flexBet"
      >
        <el-form-item label="Description" class="flex-1">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="formData.description">
          </el-input>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div>
        <div style="margin-bottom: 12px;font-size: 14px;color: #F56C6C">
          Charge In 填写正数；Charge Out填写负数；
        </div>
        <div style="margin-bottom: 12px">
          <el-radio v-model="formData.fillType" :label="0">填数值</el-radio>
          <el-radio v-model="formData.fillType" :label="1">填比例</el-radio>
        </div>
        <div class="flexBet">
          <div class="flex-1 ">
            <div class="number_box">
              <span class="number_label"></span>
              <span class="number_title">Cost</span>
              <span class="number_title">HC</span>
            </div>
            <div class="number_box">
              <span class="number_label">Jan</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.janCostScale" v-if="formData.fillType===1" @change="countScale($event,'jan','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.janCost" v-if="formData.fillType===0" @change="$event?formData.janCost=$event:formData.janCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.janCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.janHcScale"  v-if="formData.fillType===1" @change="countScale($event,'jan','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.janHc" v-if="formData.fillType===0" @change="$event?formData.janHc=$event:formData.janHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.janHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Mar</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.marCostScale" v-if="formData.fillType===1" @change="countScale($event,'mar','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.marCost" v-if="formData.fillType===0" @change="$event?formData.marCost=$event:formData.marCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.marCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.marHcScale"  v-if="formData.fillType===1" @change="countScale($event,'mar','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.marHc" v-if="formData.fillType===0" @change="$event?formData.marHc=$event:formData.marHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.marHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">May</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.mayCostScale" v-if="formData.fillType===1" @change="countScale($event,'may','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.mayCost" v-if="formData.fillType===0" @change="$event?formData.mayCost=$event:formData.mayCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.mayCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.mayHcScale"  v-if="formData.fillType===1" @change="countScale($event,'may','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.mayHc" v-if="formData.fillType===0" @change="$event?formData.mayHc=$event:formData.mayHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.mayHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Jul</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.julCostScale" v-if="formData.fillType===1" @change="countScale($event,'jul','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.julCost" v-if="formData.fillType===0" @change="$event?formData.julCost=$event:formData.julCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.julCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.julHcScale"  v-if="formData.fillType===1" @change="countScale($event,'jul','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.julHc" v-if="formData.fillType===0" @change="$event?formData.julHc=$event:formData.julHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.julHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Sep</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.sepCostScale" v-if="formData.fillType===1" @change="countScale($event,'sep','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.sepCost" v-if="formData.fillType===0"  @change="$event?formData.sepCost=$event:formData.sepCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.sepCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.sepHcScale"  v-if="formData.fillType===1" @change="countScale($event,'sep','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.sepHc" v-if="formData.fillType===0" @change="$event?formData.sepHc=$event:formData.sepHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.sepHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Nov</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.novCostScale" v-if="formData.fillType===1" @change="countScale($event,'nov','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.novCost" v-if="formData.fillType===0"  @change="$event?formData.novCost=$event:formData.novCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.novCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.novHcScale"  v-if="formData.fillType===1" @change="countScale($event,'nov','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.novHc" v-if="formData.fillType===0" @change="$event?formData.novHc=$event:formData.novHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.novHc}}</div>
              </div>
            </div>

          </div>
          <div class="flex-1 ">
            <div class="number_box">
              <span class="number_label"></span>
              <div class="number_title">Cost</div>
              <div class="number_title">HC</div>
            </div>
            <div class="number_box">
              <span class="number_label">Feb</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.febCostScale" v-if="formData.fillType===1" @change="countScale($event,'feb','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.febCost" v-if="formData.fillType===0" @change="$event?formData.febCost=$event:formData.febCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.febCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.febHcScale"  v-if="formData.fillType===1" @change="countScale($event,'feb','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.febHc" v-if="formData.fillType===0" @change="$event?formData.febHc=$event:formData.febHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.febHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Apr</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.aprCostScale" v-if="formData.fillType===1" @change="countScale($event,'apr','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.aprCost" v-if="formData.fillType===0" @change="$event?formData.aprCost=$event:formData.aprCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.aprCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.aprHcScale"  v-if="formData.fillType===1" @change="countScale($event,'apr','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.aprHc" v-if="formData.fillType===0" @change="$event?formData.aprHc=$event:formData.aprHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.aprHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Jun</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.junCostScale" v-if="formData.fillType===1" @change="countScale($event,'jun','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.junCost" v-if="formData.fillType===0" @change="$event?formData.junCost=$event:formData.junCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.junCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.junHcScale"  v-if="formData.fillType===1" @change="countScale($event,'jun','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.junHc" v-if="formData.fillType===0" @change="$event?formData.junHc=$event:formData.junHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.junHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Aug</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.augCostScale" v-if="formData.fillType===1" @change="countScale($event,'aug','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.augCost" v-if="formData.fillType===0" @change="$event?formData.augCost=$event:formData.augCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.augCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.augHcScale"  v-if="formData.fillType===1" @change="countScale($event,'aug','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.augHc" v-if="formData.fillType===0" @change="$event?formData.augHc=$event:formData.augHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.augHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Oct</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.octCostScale" v-if="formData.fillType===1" @change="countScale($event,'oct','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.octCost" v-if="formData.fillType===0" @change="$event?formData.octCost=$event:formData.octCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.octCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.octHcScale"  v-if="formData.fillType===1" @change="countScale($event,'oct','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.octHc" v-if="formData.fillType===0" @change="$event?formData.octHc=$event:formData.octHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.octHc}}</div>
              </div>
            </div>
            <div class="number_box">
              <span class="number_label">Dec</span>
              <div class="number_box_div">
                <el-input-number v-model="formData.decCostScale" v-if="formData.fillType===1" @change="countScale($event,'dec','cost')"  data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.decCost" v-if="formData.fillType===0" @change="$event?formData.decCost=$event:formData.decCost=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.decCost}}</div>
              </div>
              <div class="number_box_div">
                <el-input-number v-model="formData.decHcScale"  v-if="formData.fillType===1" @change="countScale($event,'dec','hc')" data-unit="%" :class="{'my-el-input-number':formData.fillType===1}" :min="-100" :max="100"></el-input-number>
                <el-input-number v-model="formData.decHc" v-if="formData.fillType===0" @change="$event?formData.decHc=$event:formData.decHc=0"></el-input-number>
                <div class="number_text" v-if="formData.fillType===1">{{formData.decHc}}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 18px">
          <div style="margin-bottom: 8px">Total intercompany recharge (Cost): <span style="font-weight: bold">{{formData.totalCost}}</span></div>
          <div>Total intercompany recharge (HC): <span style="font-weight: bold">{{formData.totalHc}}</span></div>
        </div>

      </div>
    </div>

    <div class="ChargeOp131__footer">
<!--      <div v-if="activeStep === 0">-->
<!--        <el-button @click="next">下一步</el-button>-->
<!--      </div>-->
<!--      <div v-if="activeStep === 1">-->
<!--        <el-button-->
<!--          @click="pre"-->
<!--          style="margin-right: 12px; position: relative; right: 12px"-->
<!--          >上一步</el-button-->
<!--        >-->
<!--        <el-button type="primary" @click="submit" class="submit-btn"-->
<!--          >保存</el-button-->
<!--        >-->

<!--      </div>-->
      <el-button @click="close" class="submit-btn"
      >取消</el-button>
      <el-button type="primary" @click="submit" class="submit-btn" v-loading.fullscreen.lock="fullscreenLoading"
       >保存</el-button>
    </div>

<!--    <selectIcChargeTable-->
<!--      ref="selectIcChargeTableRef"-->
<!--      v-if="activeStep === 1"-->
<!--    />-->
  <CharageHcTable ref="CharageHcTableRef" @selectHc="selectHc" />
    <PersonnelSelectionDialog ref="PersonnelSelectionDialogRef" @onConfirm="onConfirm" />
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import chargeOp131 from '@/views/salaryManagement/components/modal/ChargeOp131Dialog/components/SelectionVersion.vue'
// import SelectHcTable from '@/views/salaryManagement/components/modal/ChargeOp131/components/SelectHcTable.vue'
import selectIcChargeTable from '@/views/salaryManagement/components/modal/ChargeOp131Dialog/components/selectIcChargeTable.vue'
import { getLoginUserInfo, getOp131One, saveHcList, saveOp131, saveOp131List } from "@/api/pctrakerop131";
import { check, typeRange } from '@/utils/util'
import { getLegalEntity,getEntityHfmCode } from "@/api/personnelRequisition";
import CharageHcTable from "@/views/salaryManagement/components/modal/ChargeOp131Dialog/CharageHcTable.vue";
import PersonnelSelectionDialog from "@/components/PersonnelSelectionDialog/PersonnelSelectionDialog.vue";
export default {
  name: 'ChargeOp131',
  components: {
    PersonnelSelectionDialog,
    CharageHcTable,
    chargeOp131,
    selectIcChargeTable
  },
  data() {
    return {
      dialogTableVisible: false,
      type: '',
      formData:{
        chargeToBpName:'',
        chargeFromBpName:'',
        totalCost:0,
        totalHc:0,
      },
      hfmCodeList:[],
      legalEntitys:[],
      legalEntitys1:[],
      isTo:'',
      hcData:'',
      fullscreenLoading: false,
      dialogStatus: '',
      chooseTab:'',
    }
  },
  watch: {
    formData: {
      handler(val) {
        this.sumTotal()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState(['chargeOp131','ChargeOut']),
    activeStep: {
      set(val) {
        this.$store.dispatch('chargeOp131/changeVersionActive', val)
      },
      get() {
        return this.chargeOp131.active
      }
    }
  },
  created() {
    this.formData.versionId = this.$route.query.id
    this.chooseTab = this.$route.query.chooseTab
    this.initFormData()
  },
  methods: {
    show(val) {
      this.dialogTableVisible = true
      if (val) {
        this.fullscreenLoading = true
        getOp131One({id:val}).then(res=>{
          this.fullscreenLoading = false
          if(res.code === 200){
            this.formData = res.data
          }
        })
      }else {
        this.hcData = ''
        this.formData = {
          "aprCost": 0,
          "aprCostScale": 0,
          "aprHc": 0,
          "aprHcScale": 0,
          "augCost": 0,
          "augCostScale": 0,
          "augHc": 0,
          "augHcScale": 0,
          "bpInHand": 0,
          "chargeFromBp": '',
          "chargeFromEntity": "",
          "chargeFromHfm": "",
          "chargeToBp": '',
          "toCeo": "",
          "toCeoName": "",
          "fromCeo": "",
          "fromCeoName": "",
          "chargeToEntity": "",
          "chargeToHfm": this.$route.query.hfmCode,
          "chargeType": 0,
          "createTime": "",
          "createUser": 0,
          "decCost": 0,
          "decCostScale": 0,
          "decHc": 0,
          "decHcScale": 0,
          "description": "",
          "febCost": 0,
          "febCostScale": 0,
          "febHc": 0,
          "febHcScale": 0,
          "fillType": 0,
          "hcId": 0,
          "hcName": '',
          "hfmCode": "",
          "id": null,
          "isDeleted": true,
          "isEffective": true,
          "isOverseasChargeIn": false,
          "isOverseasChargeOut": false,
          "janCost": 0,
          "janCostScale": 0,
          "janHc": 0,
          "janHcScale": 0,
          "julCost": 0,
          "julCostScale": 0,
          "julHc": 0,
          "julHcScale": 0,
          "junCost": 0,
          "junCostScale": 0,
          "junHc": 0,
          "junHcScale": 0,
          "marCost": 0,
          "marCostScale": 0,
          "marHc": 0,
          "marHcScale": 0,
          "mayCost": 0,
          "mayCostScale": 0,
          "mayHc": 0,
          "mayHcScale": 0,
          "novCost": 0,
          "novCostScale": 0,
          "novHc": 0,
          "novHcScale": 0,
          "octCost": 0,
          "octCostScale": 0,
          "octHc": 0,
          "octHcScale": 0,
          "rafId": 0,
          "sepCost": 0,
          "sepCostScale": 0,
          "sepHc": 0,
          "sepHcScale": 0,
          "toBp": "",
          "totalCost": 0,
          "totalHc": 0,
          "updateTime": "",
          "updateUser": 0,
          "versionId": 0
        }
        this.changeToHfm(this.formData.chargeToHfm)
        getLoginUserInfo().then(res=>{
          if(res.code === 200){
            this.formData.chargeToBp = res.data.id
            this.formData.chargeToBpName = res.data.username
            this.$forceUpdate()
          }
        })
      }
    },
    changeFromHfm(val){
      getLegalEntity({hfmCode:val}).then((res) => {
        this.legalEntitys = res.data
      })
    },
    changeToHfm(val){
      getLegalEntity({hfmCode:val}).then((res) => {
        this.legalEntitys1 = res.data
      })
    },
    openHc(){
      this.$refs.CharageHcTableRef.show()
    },
    openPop(){

    },
    selectPersonal(val3, type, val='') {
      this.isTo = type
      this.dialogStatus = val
      this.$refs.PersonnelSelectionDialogRef.show({
        type: val3,
        radio: true
      })
    },
    clearHrbp(val){
      if(val === 'to'){
        this.formData.chargeToBp = ''
        this.formData.chargeToBpName =''
      }else {
        this.formData.chargeFromBp = ''
        this.formData.chargeFromBpName =''
      }
      this.$forceUpdate()
    },
    selectHc(){
      if(this.ChargeOut.selectVal.length >0){
        console.log(this.ChargeOut.selectVal);
        let values = this.ChargeOut.selectVal[0]
        this.formData.hcName = `${values.englishName}_${values.position}`
        this.formData.hcId = values.id
        this.formData.chargeFromHfm = values.hfmCode
        this.formData.chargeFromBp = values.bpInHand
        this.formData.chargeFromBpName = values.bpInHandName
        this.formData.chargeFromEntity = values.legalEntityCn

        this.formData.aprCost = -Number(values.totalCostApr)
        this.formData.augCost = -Number(values.totalCostAug)
        this.formData.decCost = -Number(values.totalCostDec)
        this.formData.febCost = -Number(values.totalCostFeb)
        this.formData.janCost = -Number(values.totalCostJan)
        this.formData.julCost = -Number(values.totalCostJul)
        this.formData.junCost = -Number(values.totalCostJun)
        this.formData.marCost = -Number(values.totalCostMar)
        this.formData.mayCost = -Number(values.totalCostMay)
        this.formData.novCost = -Number(values.totalCostNov)
        this.formData.octCost = -Number(values.totalCostOct)
        this.formData.sepCost = -Number(values.totalCostSep)

        this.formData.aprHc = -Number(values.hcApr)
        this.formData.augHc = -Number(values.hcAug)
        this.formData.decHc = -Number(values.hcDec)
        this.formData.febHc = -Number(values.hcFeb)
        this.formData.janHc = -Number(values.hcJan)
        this.formData.julHc = -Number(values.hcJul)
        this.formData.junHc = -Number(values.hcJun)
        this.formData.marHc = -Number(values.hcMar)
        this.formData.mayHc = -Number(values.hcMay)
        this.formData.novHc = -Number(values.hcNov)
        this.formData.octHc = -Number(values.hcOct)
        this.formData.sepHc = -Number(values.hcSep)

        this.changeFromHfm(this.formData.chargeFromHfm)
        this.$forceUpdate()
      }
    },
    countScale(val, key, type){
      if(!val){
        this.formData[key + this.titleCase(type) + 'Scale'] = 0
        this.formData[key + this.titleCase(type)] = 0
      }
      if(type === 'cost'){
        let value = Number(val)
        let totalCost = Number(0)
        if(this.ChargeOut.selectVal.length > 0){
          let itemKey = this.titleCase(key)
          totalCost = Number(this.ChargeOut.selectVal[0][`totalCost${itemKey}`])
        }
        let propData = ((value / 100) * totalCost).toFixed(2)
        this.formData[`${key}Cost`] = propData
      }else{
        let value = Number(val)
        let totalCost = Number(0)
        if(this.ChargeOut.selectVal.length > 0){
          let itemKey = this.titleCase(key)
          totalCost = Number(this.ChargeOut.selectVal[0][`hc${itemKey}`])
        }
        let propData = ((value / 100) * totalCost).toFixed(2)
        this.formData[`${key}Hc`] = propData
      }
    },
    sumTotal(){
      if(!this.formData.aprCost){this.formData.aprCost=0}
      if(!this.formData.augCost){this.formData.augCost=0}
      if(!this.formData.decCost){this.formData.decCost=0}
      if(!this.formData.febCost){this.formData.febCost=0}
      if(!this.formData.janCost){this.formData.janCost=0}
      if(!this.formData.julCost){this.formData.julCost=0}
      if(!this.formData.junCost){this.formData.junCost=0}
      if(!this.formData.marCost){this.formData.marCost=0}
      if(!this.formData.mayCost){this.formData.mayCost=0}
      if(!this.formData.novCost){this.formData.novCost=0}
      if(!this.formData.octCost){this.formData.octCost=0}
      if(!this.formData.sepCost){this.formData.sepCost=0}

      if(!this.formData.aprHc){this.formData.aprHc=0}
      if(!this.formData.augHc){this.formData.augHc=0}
      if(!this.formData.decHc){this.formData.decHc=0}
      if(!this.formData.febHc){this.formData.febHc=0}
      if(!this.formData.janHc){this.formData.janHc=0}
      if(!this.formData.julHc){this.formData.julHc=0}
      if(!this.formData.junHc){this.formData.junHc=0}
      if(!this.formData.marHc){this.formData.marHc=0}
      if(!this.formData.mayHc){this.formData.mayHc=0}
      if(!this.formData.novHc){this.formData.novHc=0}
      if(!this.formData.octHc){this.formData.octHc=0}
      if(!this.formData.sepHc){this.formData.sepHc=0}

      this.formData.totalCost =
        (Number(this.formData.aprCost) + Number(this.formData.augCost) + Number(this.formData.decCost) +
          Number(this.formData.febCost) + Number(this.formData.janCost) + Number(this.formData.julCost) +
            Number(this.formData.junCost) + Number(this.formData.marCost) + Number(this.formData.mayCost) +
              Number(this.formData.novCost) + Number(this.formData.octCost) + Number(this.formData.sepCost)).toFixed(2)
      this.formData.totalHc =
        (Number(this.formData.aprHc) + Number(this.formData.augHc) + Number(this.formData.decHc) +
          Number(this.formData.febHc) + Number(this.formData.janHc) + Number(this.formData.julHc) +
            Number(this.formData.junHc) + Number(this.formData.marHc) + Number(this.formData.mayHc) +
              Number(this.formData.novHc) + Number(this.formData.octHc) + Number(this.formData.sepHc)).toFixed(2)
    },
    initFormData() {
      getLegalEntity().then((res) => {
        this.legalEntitys = res.data
        this.legalEntitys1 = res.data
      })
      getEntityHfmCode().then((res) => {
        this.hfmCodeList = res.data
      })
    },
    close() {
      this.dialogTableVisible = false
      this.$emit('close')
      this.$store.dispatch('chargeOp131/changeChargeOp131FormData', {})
      this.activeStep = 0
      this.$store.dispatch('chargeOp131/changeType', '')
      this.$store.dispatch('ChargeOut/changeSelectVal', [])
    },
    showButtonCall() {},
    submit() {
      if(Number(this.formData.totalCost)===0 && Number(this.formData.totalHc)===0){
        this.$message.error('请填写必填项')
        return
      }
      this.fullscreenLoading = true
      this.formData.versionId = this.$route.query.id
      saveOp131(this.formData).then(res=>{
        this.fullscreenLoading = false
        if(res.code === 200){
          this.$message.success(res.msg)
          this.$store.dispatch('ChargeOut/changeSelectVal', [])
          this.close()
        }
      })

      // let isAll = this.$refs.selectIcChargeTableRef.checked
      // let returnData = {periodDate:this.chargeOp131.chargeOp131FormData.period}
      // if (this.$route.query.id) {
      //   returnData.id = this.$route.query.id
      // } else {
      //   this.$message.warning('未获取id')
      // }
      // if (isAll) {
      //   returnData = {
      //     ...returnData,
      //     ids: [],
      //     isAll
      //   }
      // } else {
      //   let list = this.$refs.selectIcChargeTableRef.getCheckBoxData()
      //   if (list && check(list) === typeRange.array && list.length) {
      //     list = list.map((item) => item.id)
      //   } else {
      //     list = []
      //   }
      //   returnData = {
      //     ...returnData,
      //     ids: list,
      //     isAll
      //   }
      // }
      // saveOp131List(returnData).then((res) => {
      //   if (res && res.code === 200) {
      //     this.$message.success('生成成功')
      //     this.close()
      //   }
      // })
    },
    onConfirm(val) {
      console.log(val);
      if (val && val.length) {
        if(this.dialogStatus === 'ceo'){
          if(this.isTo === 'to'){
            this.formData.toCeo = val[0].id
            this.formData.toCeoName = val[0].userName
            // this.$set(this.formData,'chargeToBpName',val[0].userName)
          }else {
            this.formData.fromCeo = val[0].id
            this.formData.fromCeoName = val[0].userName
            // this.$set(this.formData,'chargeFromBpName',val[0].userName)
          }
          return
        }
        if(this.isTo === 'to'){
          this.formData.chargeToBp = val[0].id
          this.formData.chargeToBpName = val[0].userName
          // this.$set(this.formData,'chargeToBpName',val[0].userName)
        }else {
          this.formData.chargeFromBp = val[0].id
          this.formData.chargeFromBpName = val[0].userName
          // this.$set(this.formData,'chargeFromBpName',val[0].userName)
        }
      }
      this.$forceUpdate();
    },
    next() {
      this.$refs.chargeOp131Ref.checkFrom().then((res) => {
        if (res) {
          this.$store.dispatch(
            'chargeOp131/changeChargeOp131FormData',
            this.$refs.chargeOp131Ref.formData
          )
          this.activeStep++
        }
      })
    },
    pre() {
      this.activeStep--
    },
    titleCase(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
    },
  }
}
</script>

<style lang="scss">
@mixin bodyCenter {
  position: relative;
  width: 1300px;
  left: 50%;
  transform: translateX(-50%);
}
.ChargeOp131 {
  .el-drawer__header {
    width: 1300px;
    margin-bottom: 20px !important;
    font-size: 21px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #303133;
    @include bodyCenter;
  }
  .el-drawer__body {
    @include bodyCenter;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__rightComponent {
      overflow: hidden;
      width: 100%;
    }
  }
  &__footer {
    height: 12vh;
    text-align: right;
    .el-button {
      margin-left: 12px !important;
    }
  }
  .el-steps {
    &:nth-child(1) {
      .el-step__main {
        .el-step__title {
          position: relative;
          left: -2px;
        }
      }
    }
  }
  .el-steps {
    height: 30%;
    margin-right: 40px;
    .el-step__head {
      width: 50px;
      position: relative;
      left: 16px;
    }
    .el-step__main {
      position: relative;
      left: -50px;
      top: 24px;
      .el-step__title {
        background: white;
      }
    }
    .el-step__line {
      width: 1px;
    }
    .el-step__head.is-process {
      .is-text {
        width: 28px;
        height: 28px;
        background: #2490e3;
        color: white;
        border-color: white;
      }
    }
    .el-step__title {
      width: 60px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
    }
  }
}

.number_box{
  margin-bottom: 8px;
  display: flex;
  .number_text{
    //position: relative;
    //top: 30px;
    //left: -125px;
    //font-size: 12px;

  }
  .number_box_div{
    width: 100%;
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    .number_text{
      width: 100%;
      text-align: center;
      font-size: 12px;
      padding-right: 16px;
      box-sizing: border-box;
    }
  }
  .number_label{
    width: 75px;
    display: inline-block;
    line-height: 25px;
  }
  .number_title{
    width: 100%;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    margin-right: 18px;
  }
  .el-input-number{
    width: 100%;
    margin-right: 18px;
  }
  .my-el-input-number[data-unit] {
    --el-input-number-unit-offset-x: 35px;
    position: relative;
  }
  .my-el-input-number[data-unit]::after {
    content: attr(data-unit);
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: var(--el-input-number-unit-offset-x);
    color: #999999;
  }
  .my-el-input-number[data-unit] .el-input__inner {
    padding-left: 30px;
    padding-right: calc(var(--el-input-number-unit-offset-x) + 12px);
  }
}
.submit-btn{

}
</style>
