export const totalCostColumn = [
  {
    key: 'title',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: 'left', // 固定列的位置(left, right)
    align: 'center',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'totalCostJan',
    monthName: 1,
    name: 'Total Cost Jan-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostFeb',
    monthName: 2,
    name: 'Total Cost Feb-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostMar',
    monthName: 3,
    name: 'Total Cost Mar-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostApr',
    monthName: 4,
    name: 'Total Cost Apr-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostMay',
    monthName: 5,
    name: 'Total Cost May-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },

  {
    key: 'totalCostJun',
    monthName: 6,
    name: 'Total Cost Jun-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostJul',
    monthName: 7,
    name: 'Total Cost Jul-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostAug',
    monthName: 8,
    name: 'Total Cost Aug-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostSep',
    monthName: 9,
    name: 'Total Cost Sep-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostOct',
    monthName: 10,
    name: 'Total Cost Oct-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostNov',
    monthName: 11,
    name: 'Total Cost Nov-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostDec',
    monthName: 12,
    name: 'Total Cost Dec-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'salaryFullYear',
    name: 'Salary Full year',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  }
]
export const leaderColumnConfig = [
  {
    key: 'totalBasePay',
    name: 'Sub-Total Base payroll',
    dataType: 'number',
    visible: true,
    sortable: false,

    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalThirteenSalaryPay',
    name: 'Sub-Total 13th payroll',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalHolidayPay',
    name: 'Sub-Total Holiday pay',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalSalesCommPay',
    name: 'Sub-Total Sales Comm-OP113',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 230 // 默认宽度，像素
  },
  // {
  //   key: 'subTotalSocialChargesBase',
  //   name: 'Sub-Total Social Charges',
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 200 // 默认宽度，像素
  // },
  {
    key: 'totalSocialChargesOp121Pay',
    name: 'Sub-Total Social ChargesOp121',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 240 // 默认宽度，像素
  },

  {
    key: 'totalMedicalInsurance',
    name: 'Sub-Total Medical Insurance',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 230 // 默认宽度，像素
  },
  {
    key: 'totalCommInsuranceIitPay',
    name: 'Sub-Total CommInsuranceIITOp126',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 280 // 默认宽度，像素
  },
  // {
  //   key: '',
  //   name: 'Sub-total Social Commerical',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   width: 230 // 默认宽度，像素
  // },
  {
    key: 'totalHomeLeavePay',
    name: 'Sub-Total HomeLeave',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalHousingSchoolFeeOthersPay',
    name: 'Sub-Total Housing School Fee',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 230 // 默认宽度，像素
  },
  {
    key: 'totalRelocationPay',
    name: 'Sub-Total Relocation',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 170 // 默认宽度，像素
  },
  {
    key: 'totalFellingfeeOthersPay',
    name: 'Sub-Total Filling Fee&Others',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 230 // 默认宽度，像素
  },
  {
    key: 'totalSeverancePay',
    name: 'Sub-Total Severance',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalBonusPay',
    name: 'Sub-Total Bonus',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  }
]
