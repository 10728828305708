import { changeBasePayRollDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/basePayRollData'
import { changeThirteenSalaryDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/thirteenSalaryData'
import { changeHCDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/HCData'
import { changeHolidayPayDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/holidayPayData'
import { changeSalesCommDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/salesCommData'
import { changeSocialChargesDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/socialChargesData'
import { changeSocialChargesOp121DataYear } from '@/views/salaryManagement/editionData/addColumnConfig/socialCharges121Data'
import { changeMedicalInsuranceDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/socialMedicalData'
import { changeHomeLeaveDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/homeLeaveData'
import { changeSchoolFeeDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/schoolFeeData'
import { changeRelocationDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/relocationData'
import { changeOthersDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/othersData'
import { changeSeveranceDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/severanceData'
import { changeBonusDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/bonusData'
import { changeCommInsurancOPDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/comnInsureceData'

export const changeAllYear = () => {
  changeBasePayRollDataYear()
  changeThirteenSalaryDataYear()
  changeHCDataYear()
  changeHolidayPayDataYear()
  changeSalesCommDataYear()
  changeSocialChargesDataYear()
  changeSocialChargesOp121DataYear()
  changeMedicalInsuranceDataYear()
  changeHomeLeaveDataYear()
  changeSchoolFeeDataYear()
  changeRelocationDataYear()
  changeOthersDataYear()
  changeSeveranceDataYear()
  changeBonusDataYear()
  changeCommInsurancOPDataYear()
}
