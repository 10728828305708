<template>
  <div>
    <!-- <div @click="a">aaaa</div> -->
    <div v-show="!adjustmentFlag" class="card-table">
      <!--  100, 500, 1000, 2000, 5000 -->
      <transition name="fade-transform" mode="out-in">
        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          size="mini"
          stripe
          :max-height="100000000000000000000000"
          :page-sizes="[10, 20, 50]"
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          :cell-style="funColumn"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          @selection-change="handleSelectionChange"
          @header-click="headerExpend"
          :summary-method="getSummaries"
          show-summary
          ref="multipleTable"
          row-key="id"
        >
          <el-table-column fixed="left" type="selection" width="55">
          </el-table-column>
          <el-table-column
            label="Remark"
            prop="remark"
            width="140"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.remark"
                @blur="remarkBlur(scope.row)"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item, index) in columnOptions"
            :key="item.key + '' + index"
            :row-key="item.key + '' + index"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :width="item.width"
            :align="item.align"
            show-overflow-tooltip
          >
            <!--               :sortable="item.sortable"-->
            <template slot="header" slot-scope="scope">
              <div
                v-if="scope.column.label == 'English Name'"
                class="flexRow AlCenter"
              >
                English<br />Name
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="nameFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!nameFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Start Date'"
                class="flexRow AlCenter"
              >
                Start<br />Date
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="dateFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!dateFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>


              <div
                v-else-if="scope.column.label == 'Salary Full year'"
                class="flexRow AlCenter"
              >
                Salary<br />Full year
              </div>

              <div
                v-else-if="scope.column.label == 'Sub-Total Base payroll'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Base payroll
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="payRollFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!payRollFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'HC Total'"
                class="flexRow AlCenter JuRight"
              >
                {{ scope.column.label }}
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="HCFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!HCFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total 13th payroll'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />13th payroll
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="thirteenSalaryFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!thirteenSalaryFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Holiday pay'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Holiday pay
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="holidayPayFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!holidayPayFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Sales Comm-OP113'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Sales Comm-OP113
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="salesCommFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!salesCommFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <!-- <div
                v-else-if="scope.column.label == 'Social Charges base'"
                class="flexRow AlCenter"
              >
                Social<br />Charges base
              </div> -->
              <div
                v-else-if="scope.column.label == 'Sub-Total Social Charges'"
                class="flexRow AlCenter"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="socialChargesFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!socialChargesFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total Social ChargesOp121'
                "
                class="flexRow AlCenter"
              >
                Sub-Total<br />Social ChargesOp121
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="socialCharges121Flag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!socialCharges121Flag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Medical Insurance'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Medical Insurance
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="socialMedicalFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!socialMedicalFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <!-- <div
              v-else-if="scope.column.label == 'Sub-total Social Commerical'"
              class="flexRow AlCenter"
            >
              {{ scope.column.label }}
              <img
                class="iconImg180"
                v-if="socialCommericalFlag"
                @click="expendCounfig(scope)"
                src="@/assets/img/dropDown.png"
                alt=""
              />
              <img
                class="iconImg"
                v-if="!socialCommericalFlag"
                @click="expendCounfig(scope)"
                src="@/assets/img/dropDown.png"
                alt=""
              />
            </div> -->
              <div
                v-else-if="scope.column.label == 'Sub-Total HomeLeave'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />HomeLeave
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="homeLeaveFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!homeLeaveFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Housing School Fee'"
                class="flexRow AlCenter"
              >
                <!-- {{ scope.column.label }} -->
                Sub-Total<br />Housing School Fee
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="schoolFeeFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!schoolFeeFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Relocation'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Relocation
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="relocationFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!relocationFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>

              <div
                v-else-if="scope.column.label == 'Sub-Total Filling Fee&Others'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Filling Fee&Others
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="othersFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!othersFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>

              <div
                v-else-if="scope.column.label == 'Sub-Total Severance'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Severance
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="severanceFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!severanceFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Bonus'"
                class="flexRow AlCenter"
              >
                Sub-Total<br />Bonus
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="bonusFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!bonusFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                class="flexRow AlCenter"
              >
                Sub-Total<br />CommInsuranceIITOp126
                <!-- <div class="expand-type" @click="expendCounfig(scope)">
                  <div class="type-1"></div>
                  <div class="type-2"></div>
                </div> -->
                <img
                  class="iconImg180"
                  v-if="CommInsurancOPFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!CommInsurancOPFlag"
                  @click="expendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Total Cost') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Total Cost')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('basePayRoll') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'basePayRoll')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Holiday Pay') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Holiday Pay')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Sales Comm') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Sales Comm')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Social Charge') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Social Charge')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('medical Insurance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'medical Insurance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('commInsuranceIITOp126') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'commInsuranceIITOp126')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Home Leave') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Home Leave')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Housing School FeeOthers') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Housing School FeeOthers')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Relocation') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Relocation')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Filling Fee Others') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Filling Fee Others')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Severance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Severance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Bonus') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Bonus')"
              >

              </div>
              <div v-else>
                {{ scope.column.label }}
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="item.moreRow" class="orgName">
                <div
                  class="editData"
                  v-if="
                    item.key.includes('Total Cost') &&
                    scope.row[item.childrenKey]
                  "
                >
                  {{
                    item.dataType == 'number'
                      ? currency(scope.row[item.key])
                      : scope.row[item.key]
                  }}
                  <span class="editRedData">
                    <img
                      v-if="scope.row[item.childrenKey] > 0"
                      class="iconImg editRedDataImg"
                      src="@/assets/img/better.png"
                    />
                    <img
                      v-if="scope.row[item.childrenKey] < 0"
                      class="iconImg editRedDataImg"
                      src="@/assets/img/lower.png"
                    />
                    <span
                      :class="{
                        editBlueData: scope.row[item.childrenKey] < 0,
                        editRedSpan: scope.row[item.childrenKey] > 0,
                        editGreyData:
                          scope.row[item.childrenKey] === 0 ||
                          scope.row[item.childrenKey]
                      }"
                      v-if="scope.row[item.childrenKey]"
                    >
                      ({{ scope.row[item.childrenKey] }})%</span
                    >
                    <br /><span
                      class="editDataSpan"
                      v-if="scope.row[item.childrenKey]"
                      >{{ scope.row[item.childrenForKey] || 0 }}</span
                    >
                  </span>
                  <!--                 <span class="editBlueData">-->
                  <!--                  {{ scope.row[item.childrenKey] }}-->
                  <!--                </span>-->
                </div>
                <div v-else>
                  {{
                    item.dataType == 'number'
                      ? currency(scope.row[item.key])
                      : scope.row[item.key]
                  }}
                </div>
                <!-- <div class="editAfterData">0</div> -->
              </div>
              <span
                v-else-if="item.hcNatureFlag == 'true' && buttonActive == 1"
                @click="editTime(scope.row)"
              >
                <span class="flexRow AlCenter"
                  >{{
                    item.dataType == 'number'
                      ? currency(scope.row[item.key])
                      : scope.row[item.key]
                  }}
                  <img
                    v-if="!scope.row.staffId"
                    class="iconImg"
                    src="@/assets/img/edit.png"
                    alt=""
                  />
                </span>
              </span>

              <span v-else>{{
                item.dataType == 'number'
                  ? currency(scope.row[item.key])
                  : scope.row[item.key]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonActive != 3"
            label="Operation"
            width="150"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-row
                :gutter="24"
                style="display: flex; justify-content: center"
              >
                <span
                  class="editSpan pointer"
                  role="adjust"
                  v-if="adjustDis()"
                  style="margin-right: 16px"
                  v-permission
                  @click.stop="editdRow(scope.row)"
                  >调整
                </span>
                <!-- <el-tooltip content="调整" placement="top">
                  <img
                    class="iconImg pointer"
                    src="@/assets/img/edit.png"
                    @click.stop="editdRow(scope.row)"
                    alt=""
                  />
                </el-tooltip> -->

                <span
                  class="editSpan pointer"
                  v-if="adjustDis()"
                  @click.stop="editHistory(scope.row)"
                  >调整历史
                </span>

                <!--              <el-col :span="12">-->
                <!--                <span class="editSpan pointer" role="adjustHistory"-->
                <!--              v-permission @click.stop="editHistory(scope.row)">调整历史 </span>-->
                <!--                &lt;!&ndash; <el-tooltip content="调整历史" placement="top">-->
                <!--                  <img-->
                <!--                    class="iconImg pointer"-->
                <!--                    src="@/assets/img/edit.png"-->
                <!--                    @click.stop="editdRow(scope.row)"-->
                <!--                    alt=""-->
                <!--                  />-->
                <!--                </el-tooltip> &ndash;&gt;-->
                <!--              </el-col>-->
              </el-row>
            </template>
          </el-table-column>
        </PocTable>
      </transition>
      <br />
      <!-- new--------------2.15 -->
      <transition name="fade-transform" mode="out-in">
        <PocTable
          class="PocTablemtop"
          style="margin-bottom: 8px"
          v-loading="queryLoading"
          :data="topData"
          size="mini"
          hidepage
          stripe
          :cell-style="funclass"
          class-name="columnClass"
          :max-height="100000000000000000000000"
          ref="newMultipleAllTable"
          @header-click="newHeaderExpend"
        >
          <el-table-column
            :cell-style="{ 'text-align': 'center' }"
            fixed="left"
            style="text-align: center"
            width="85"
          >
            <!-- <template #header>
              <div class="flex AlCenter upContainer pointer">
                <div class="up" @click.stop="upRow">
                  <div class="strangle" v-if="!upFlag"></div>
                  <div class="strangle down" v-if="upFlag"></div>
                </div>
                <div class="upWord" @click.stop="upRow">
                  <span v-if="!upFlag">收起</span>
                  <span v-else>展开</span>
                </div>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column
            v-for="(item, index) in sumTotalCostColumn"
            :key="index"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :width="item.width"
            :align="item.align"
          >
            <template slot="header" slot-scope="scope">
              <div
                v-if="scope.column.label == 'Sub-Total Base payroll'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newpayRollFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newpayRollFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'HC Total'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newHCFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newHCFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total 13th payroll'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newthirteenSalaryFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newthirteenSalaryFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Holiday pay'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newholidayPayFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newholidayPayFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Sales Comm-OP113'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsalesCommFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsalesCommFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Social Charges'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialChargesFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialChargesFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total Social ChargesOp121'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialCharges121Flag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialCharges121Flag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>

              <div
                v-else-if="scope.column.label == 'Sub-Total Medical Insurance'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialMedicalFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialMedicalFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total HomeLeave'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newhomeLeaveFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newhomeLeaveFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Housing School Fee'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newschoolFeeFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newschoolFeeFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Relocation'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newrelocationFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newrelocationFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Filling Fee&Others'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newothersFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newothersFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Severance'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newseveranceFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newseveranceFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Bonus'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newbonusFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newbonusFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('basePayRoll') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'basePayRoll')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Holiday Pay') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Holiday Pay')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Sales Comm') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Sales Comm')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Social Charge') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Social Charge')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('medical Insurance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'medical Insurance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('commInsuranceIITOp126') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'commInsuranceIITOp126')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Home Leave') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Home Leave')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Housing School FeeOthers') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Housing School FeeOthers')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Relocation') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Relocation')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Filling Fee Others') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Filling Fee Others')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Severance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Severance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Bonus') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Bonus')"
              >

              </div>
              <div v-else>
                {{ scope.column.label }}
              </div>
            </template>
            <template slot-scope="scope">
              <el-input
                v-if="
                  buttonActive !== 3 &&
                  scope.row.title != 'Total Cost' &&
                  item.key !== 'title' &&
                  item.key !== 'totalCostJan' &&
                  item.key !== 'totalCostFeb' &&
                  item.key !== 'totalCostMar' &&
                  item.key !== 'totalCostApr' &&
                  item.key !== 'totalCostMay' &&
                  item.key !== 'totalCostJun' &&
                  item.key !== 'totalCostJul' &&
                  item.key !== 'totalCostAug' &&
                  item.key !== 'totalCostSep' &&
                  item.key !== 'totalCostOct' &&
                  item.key !== 'totalCostNov' &&
                  item.key !== 'totalCostDec' &&
                  item.key !== 'totalCommInsuranceIitPay' &&
                  item.key !== 'salaryFullYear' &&
                  item.key !== 'totalBasePay' &&
                  item.key !== 'totalThirteenSalaryPay' &&
                  item.key !== 'totalHolidayPay' &&
                  item.key !== 'totalSalesCommPay' &&
                  item.key !== 'totalSocialChargesOp121Pay' &&
                  item.key !== 'totalMedicalInsurance' &&
                  item.key !== 'totalHomeLeavePay' &&
                  item.key !== 'totalHousingSchoolFeeOthersPay' &&
                  item.key !== 'totalRelocationPay' &&
                  item.key !== 'totalFellingfeeOthersPay' &&
                  item.key !== 'totalSeverancePay' &&
                  item.key !== 'totalBonusPay' &&
                  item.key !== 'hfmCode' &&
                  item.key !== 'ceo'
                "
                @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                v-model="scope.row[item.key]"
                :disabled="!titleArr.includes(scope.row.title)"
                type="number"
              ></el-input>

              <el-input
                v-else-if="item.key == 'title' && (scope.row['newFlag'] == 1 || scope.row['newFlag'] == 2)"
                v-model="scope.row[item.key]"
              ></el-input>

              <el-select v-else-if="item.key == 'hfmCode'" v-model="scope.row.hfmCode" placeholder="请选择">
                <el-option
                  v-for="item in hfmCodeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-else-if="item.key == 'ceo'" v-model="scope.row.ceo" placeholder="请选择">
                <el-option
                  v-for="item in ceoOoptions"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id">
                </el-option>
              </el-select>

              <el-input
                v-else-if="
                  buttonActive !== 3 &&
                  item.showInput &&
                  !titleArr.includes(scope.row.title)
                "
                @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                type="number"
                v-model="scope.row[item.key]"
              ></el-input>
              <!-- <div v-else-if="titleArr.includes(scope.row.title)">111{{ scope.row[item.key] }}</div> -->
              <div v-else class="format-number">{{ scope.row[item.key] }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button @click="deleteItem(scope)" :disabled="!scope.row.newFlag" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </PocTable>
      </transition>
      <div>
        <el-button size="mini" v-if="extendList.length === 0" @click="addExtendList" style="width: 100px">新增</el-button>
      </div>
      <div v-for="(extend,index) in extendList" :key="index">
        <transition name="fade-transform" mode="out-in">
          <PocTable
            class="PocTablemtop"
            style="margin-bottom: 8px"
            v-loading="queryLoading"
            :data="extend['val']"
            size="mini"
            hidepage
            stripe
            :cell-style="funclass"
            class-name="columnClass"
            :max-height="100000000000000000000000"
            :ref=extend.config.ref
          >
            <el-table-column
              :cell-style="{ 'text-align': 'center' }"
              fixed="left"
              style="text-align: center"
              width="85"
            >
              <!-- <template #header>
                <div class="flex AlCenter upContainer pointer">
                  <div class="up" @click.stop="upRow">
                    <div class="strangle" v-if="!upFlag"></div>
                    <div class="strangle down" v-if="upFlag"></div>
                  </div>
                  <div class="upWord" @click.stop="upRow">
                    <span v-if="!upFlag">收起</span>
                    <span v-else>展开</span>
                  </div>
                </div>
              </template> -->
            </el-table-column>
            <el-table-column
              v-for="(item, index) in extend.config.sumTotalCostColumn"
              :key="index"
              :prop="item.key"
              :label="item.name"
              :fixed="item.fixed"
              :width="item.width"
              :align="item.align"
            >
              <template slot="header" slot-scope="scope">
                <div
                  v-if="scope.column.label == 'Sub-Total Base payroll'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newpayRollFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newpayRollFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'HC Total'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newHCFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newHCFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total 13th payroll'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newthirteenSalaryFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newthirteenSalaryFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Holiday pay'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newholidayPayFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newholidayPayFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Sales Comm-OP113'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newsalesCommFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newsalesCommFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Social Charges'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newsocialChargesFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newsocialChargesFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="
                  scope.column.label == 'Sub-Total Social ChargesOp121'
                "
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newsocialCharges121Flag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newsocialCharges121Flag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>

                <div
                  v-else-if="scope.column.label == 'Sub-Total Medical Insurance'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newsocialMedicalFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newsocialMedicalFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newCommInsurancOPFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newCommInsurancOPFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total HomeLeave'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newhomeLeaveFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newhomeLeaveFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Housing School Fee'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newschoolFeeFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newschoolFeeFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Relocation'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newrelocationFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newrelocationFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Filling Fee&Others'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newothersFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newothersFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Severance'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newseveranceFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newseveranceFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="scope.column.label == 'Sub-Total Bonus'"
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newbonusFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newbonusFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                  class="flexRow AlCenter"
                  :data-tag="scope.column.label"
                >
                  {{ scope.column.label }}
                  <img
                    class="iconImg180"
                    v-if="extend.config.newCommInsurancOPFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                  <img
                    class="iconImg"
                    v-if="!extend.config.newCommInsurancOPFlag"
                    @click="dtExpendCounfig(scope,extend.config)"
                    src="@/assets/img/dropDown.png"
                    alt=""
                  />
                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('basePayRoll') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'basePayRoll')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Holiday Pay') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Holiday Pay')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Sales Comm') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Sales Comm')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Social Charge') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Social Charge')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('medical Insurance') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'medical Insurance')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('commInsuranceIITOp126') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'commInsuranceIITOp126')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Home Leave') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Home Leave')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Housing School FeeOthers') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Housing School FeeOthers')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Relocation') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Relocation')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Filling Fee Others') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Filling Fee Others')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Severance') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Severance')"
                >

                </div>
                <div
                  v-else-if="
                  scope.column.label.indexOf('Bonus') != -1
                "
                  class="flexRow AlCenter"
                  v-html="formatLabel(scope.column.label, 'Bonus')"
                >

                </div>
                <div v-else>
                  {{ scope.column.label }}
                </div>
              </template>
              <template slot-scope="scope">
                <el-input
                  v-if="
                  buttonActive !== 3 &&
                  scope.row.title != 'Total Cost' &&
                  item.key !== 'title' &&
                  item.key !== 'totalCostJan' &&
                  item.key !== 'totalCostFeb' &&
                  item.key !== 'totalCostMar' &&
                  item.key !== 'totalCostApr' &&
                  item.key !== 'totalCostMay' &&
                  item.key !== 'totalCostJun' &&
                  item.key !== 'totalCostJul' &&
                  item.key !== 'totalCostAug' &&
                  item.key !== 'totalCostSep' &&
                  item.key !== 'totalCostOct' &&
                  item.key !== 'totalCostNov' &&
                  item.key !== 'totalCostDec' &&
                  item.key !== 'totalCommInsuranceIitPay' &&
                  item.key !== 'salaryFullYear' &&
                  item.key !== 'totalBasePay' &&
                  item.key !== 'totalThirteenSalaryPay' &&
                  item.key !== 'totalHolidayPay' &&
                  item.key !== 'totalSalesCommPay' &&
                  item.key !== 'totalSocialChargesOp121Pay' &&
                  item.key !== 'totalMedicalInsurance' &&
                  item.key !== 'totalHomeLeavePay' &&
                  item.key !== 'totalHousingSchoolFeeOthersPay' &&
                  item.key !== 'totalRelocationPay' &&
                  item.key !== 'totalFellingfeeOthersPay' &&
                  item.key !== 'totalSeverancePay' &&
                  item.key !== 'totalBonusPay'
                "
                  @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                  v-model="scope.row[item.key]"
                  :disabled="!titleArr.includes(scope.row.title)"
                  type="number"
                ></el-input>
                <el-input
                  v-else-if="
                  buttonActive !== 3 &&
                  item.showInput &&
                  !titleArr.includes(scope.row.title)
                "
                  @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                  type="number"
                  v-model="scope.row[item.key]"
                ></el-input>
                <!-- <div v-else-if="titleArr.includes(scope.row.title)">111{{ scope.row[item.key] }}</div> -->
                <div v-else class="format-number">{{ scope.row[item.key] }}</div>
              </template>
            </el-table-column>
          </PocTable>
        </transition>
        <div>
          <el-button size="mini" v-if="extendList.length - 1 === index" @click="addExtendList" style="width: 100px">新增</el-button>
          <el-button size="mini" @click="delExtendList" style="width: 100px">删除</el-button>
        </div>
      </div>

      <!-- Total table -->
      <transition name="fade-transform" mode="out-in">
        <PocTable
          class="PocTablemtop"
          v-loading="queryLoading"
          :data="totalData"
          size="mini"
          hidepage
          stripe
          :cell-style="funclass"
          class-name="columnClass"
          :max-height="100000000000000000000000"
          ref="newMultipleAllTable"
          @header-click="newHeaderExpend"
        >
          <el-table-column
            :cell-style="{ 'text-align': 'center' }"
            fixed="left"
            style="text-align: center"
            width="85"
          >
            <!-- <template #header>
              <div class="flex AlCenter upContainer pointer">
                <div class="up" @click.stop="upRow">
                  <div class="strangle" v-if="!upFlag"></div>
                  <div class="strangle down" v-if="upFlag"></div>
                </div>
                <div class="upWord" @click.stop="upRow">
                  <span v-if="!upFlag">收起</span>
                  <span v-else>展开</span>
                </div>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column
            v-for="(item, index) in sumTotalCostColumn"
            :key="index"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :width="item.width"
            :align="item.align"
            show-overflow-tooltip
          >
            <template slot="header" slot-scope="scope">
              <div
                v-if="scope.column.label == 'Sub-Total Base payroll'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newpayRollFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newpayRollFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'HC Total'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newHCFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newHCFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total 13th payroll'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newthirteenSalaryFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newthirteenSalaryFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Holiday pay'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newholidayPayFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newholidayPayFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Sales Comm-OP113'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsalesCommFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsalesCommFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Social Charges'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialChargesFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialChargesFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total Social ChargesOp121'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialCharges121Flag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialCharges121Flag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>

              <div
                v-else-if="scope.column.label == 'Sub-Total Medical Insurance'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newsocialMedicalFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newsocialMedicalFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total HomeLeave'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newhomeLeaveFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newhomeLeaveFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Housing School Fee'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newschoolFeeFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newschoolFeeFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Relocation'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newrelocationFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newrelocationFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Filling Fee&Others'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newothersFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newothersFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Severance'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newseveranceFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newseveranceFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="scope.column.label == 'Sub-Total Bonus'"
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newbonusFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newbonusFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label == 'Sub-Total CommInsuranceIITOp126'
                "
                class="flexRow AlCenter"
                :data-tag="scope.column.label"
              >
                {{ scope.column.label }}
                <img
                  class="iconImg180"
                  v-if="newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
                <img
                  class="iconImg"
                  v-if="!newCommInsurancOPFlag"
                  @click="newExpendCounfig(scope)"
                  src="@/assets/img/dropDown.png"
                  alt=""
                />
              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('basePayRoll') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'basePayRoll')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Holiday Pay') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Holiday Pay')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Sales Comm') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Sales Comm')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Social Charge') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Social Charge')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('medical Insurance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'medical Insurance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('commInsuranceIITOp126') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'commInsuranceIITOp126')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Home Leave') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Home Leave')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Housing School FeeOthers') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Housing School FeeOthers')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Relocation') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Relocation')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Filling Fee Others') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Filling Fee Others')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Severance') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Severance')"
              >

              </div>
              <div
                v-else-if="
                  scope.column.label.indexOf('Bonus') != -1
                "
                class="flexRow AlCenter"
                v-html="formatLabel(scope.column.label, 'Bonus')"
              >

              </div>
              <div v-else>
                {{ scope.column.label }}
              </div>
            </template>
            <template slot-scope="scope">
              <el-input
                v-if="
                  buttonActive !== 3 &&
                  scope.row.title != 'Total Cost' &&
                  item.key !== 'title' &&
                  item.key !== 'totalCostJan' &&
                  item.key !== 'totalCostFeb' &&
                  item.key !== 'totalCostMar' &&
                  item.key !== 'totalCostApr' &&
                  item.key !== 'totalCostMay' &&
                  item.key !== 'totalCostJun' &&
                  item.key !== 'totalCostJul' &&
                  item.key !== 'totalCostAug' &&
                  item.key !== 'totalCostSep' &&
                  item.key !== 'totalCostOct' &&
                  item.key !== 'totalCostNov' &&
                  item.key !== 'totalCostDec' &&
                  item.key !== 'totalCommInsuranceIitPay' &&
                  item.key !== 'salaryFullYear' &&
                  item.key !== 'totalBasePay' &&
                  item.key !== 'totalThirteenSalaryPay' &&
                  item.key !== 'totalHolidayPay' &&
                  item.key !== 'totalSalesCommPay' &&
                  item.key !== 'totalSocialChargesOp121Pay' &&
                  item.key !== 'totalMedicalInsurance' &&
                  item.key !== 'totalHomeLeavePay' &&
                  item.key !== 'totalHousingSchoolFeeOthersPay' &&
                  item.key !== 'totalRelocationPay' &&
                  item.key !== 'totalFellingfeeOthersPay' &&
                  item.key !== 'totalSeverancePay' &&
                  item.key !== 'totalBonusPay'
                "
                @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                v-model="scope.row[item.key]"
                :disabled="!titleArr.includes(scope.row.title)"
                type="number"
              ></el-input>
              <el-input
                v-else-if="
                  buttonActive !== 3 &&
                  item.showInput &&
                  !titleArr.includes(scope.row.title)
                "
                @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                type="number"
                v-model="scope.row[item.key]"
              ></el-input>
              <!-- <div v-else-if="titleArr.includes(scope.row.title)">111{{ scope.row[item.key] }}</div> -->
              <div v-else class="format-number">{{ scope.row[item.key] }}</div>
            </template>
          </el-table-column>
        </PocTable>
      </transition>

      <transition name="fade-transform" mode="out-in">
        <PocTable
          class="PocTablemtop"
          v-loading="queryLoading"
          :data="botData"
          size="mini"
          hidepage
          stripe
          :cell-style="funclass"
          class-name="columnClass"
          :max-height="100000000000000000000000"
        >
          <!-- @header-click="newHeaderExpend" -->

          <el-table-column
            :cell-style="{ 'text-align': 'center' }"
            fixed="left"
            style="text-align: center"
            width="85"
          >
            <!-- <template #header>
              <div class="flex AlCenter upContainer pointer">
                <div class="up" @click.stop="upRow">
                  <div class="strangle" v-if="!upFlag"></div>
                  <div class="strangle down" v-if="upFlag"></div>
                </div>
                <div class="upWord" @click.stop="upRow">
                  <span v-if="!upFlag">收起</span>
                  <span v-else>展开</span>
                </div>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column
            v-for="(item, index) in sumColumnConfig"
            :key="index"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :width="item.width"
            :align="item.align"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input
                style="text-align: right"
                v-if="
                  buttonActive !== 3 &&
                  scope.row.title != 'Total Cost' &&
                  item.key !== 'title' &&
                  item.key !== 'salaryFullYear'
                "
                @input="$event?scope.row[item.key] = $event:scope.row[item.key] = 0"
                v-model="scope.row[item.key]"
                type="number"
              ></el-input>
              <div v-else class="format-number">{{ scope.row[item.key] }}</div>
            </template>
          </el-table-column>
        </PocTable>
      </transition>
      <!-- 新增end -->
    </div>
    <!-- 修改时间区域 -->
    <edit-time-management-modal
      v-if="editTimeFlag"
      :rowId="this.rowId"
      @closeModal="closeModal"
    ></edit-time-management-modal>
  </div>
</template>

<script>
// new start---------
import {
  totalCostColumn,
  leaderColumnConfig
} from '../../editionData/addColumnConfig/totalCostColumn'

// new end-------

import { getItem, setItem } from '@/utils/storage'

const { Vuex } = $PCommon
const { mapGetters } = Vuex

import {
  getNowEditionColumnConfig,
  sumColumnConfig
} from '../../editionData/nowEditionData'
import { addNameData } from '../../editionData/addColumnConfig/addNameData'
import { addDateData } from '../../editionData/addColumnConfig/addDateData'
import { basePayRollData } from '../../editionData/addColumnConfig/basePayRollData'
import { HCData } from '../../editionData/addColumnConfig/HCData'

import { thirteenSalaryData } from '../../editionData/addColumnConfig/thirteenSalaryData'
import { holidayPayData } from '../../editionData/addColumnConfig/holidayPayData'
import { salesCommData } from '../../editionData/addColumnConfig/salesCommData'
import { socialChargesData } from '../../editionData/addColumnConfig/socialChargesData'
import { socialChargesOp121Data } from '../../editionData/addColumnConfig/socialCharges121Data'
import { medicalInsuranceData } from '../../editionData/addColumnConfig/socialMedicalData'
import { homeLeaveData } from '../../editionData/addColumnConfig/homeLeaveData'
import { schoolFeeData } from '../../editionData/addColumnConfig/schoolFeeData'
import { relocationData } from '../../editionData/addColumnConfig/relocationData'
import { othersData } from '../../editionData/addColumnConfig/othersData'
import { severanceData } from '../../editionData/addColumnConfig/severanceData'
import { bonusData } from '../../editionData/addColumnConfig/bonusData'
import { CommInsurancOPData } from '../../editionData/addColumnConfig/comnInsureceData'
import editTimeManagementModal from '../modal/editTimeManagementModal.vue'
import currency from 'currency.js'
import {
  saveOp111Remark,
  getOpsum,
  getOpHistorysum,
  editOpNowSummary,
  getEntityHfmCode,
  getOrganizationsUser,
} from '@/api/pctrakerop131'
import { changeAllYear } from '@/views/salaryManagement/changeAllYear'
import { titleCase } from '@/utils/util'
import { reject } from '@/api/quto'
import { initOps111SumTableData } from "@/views/salaryManagement/editionData/op111SumTableData";

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    editTimeManagementModal
  },
  props: {
    buttonActive: {
      type: Number
    },
    changeActive: {
      type: Number
    },
    // editionId: {
    //   type: Number
    // },
    indexYear: {
      type: String
    },
    indexMonth: {
      type: String
    }
  },
  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 760 + 'px'
    const h2 =
      document.getElementsByTagName('body')[0].clientHeight - 100 + 'px'
    return {
      hfmCodeOptions: [],
      ceoOoptions: [],

      // new start---------
      totalCostLabel: [''],

      topData: [],
      totalData: [],
      extendList: [],
      titleArr: ['PC Plug', 'HC Plug', 'Total Cost'],
      botData: [],
      sumTotalCostColumn: [],
      totalCostColumn: totalCostColumn,
      leaderColumnConfig: leaderColumnConfig,
      newClickIndex: '',
      newColumnConfig: [],
      editHistoryArr: [
        '待提交',
        '待汇总',
        '已汇总',
        'BP leader驳回',
        'Finance审批不通过'
      ],
      // 控制表格按钮方向
      newadjustmentFlag: false,
      newnameFlag: false,
      newdateFlag: false,
      newHCFlag: false,
      newSalaryFullyearFlag: false,
      newpayRollFlag: false,
      newthirteenSalaryFlag: false,
      newholidayPayFlag: false,
      newsalesCommFlag: false,
      newsocialChargesFlag: false,
      newsocialCharges121Flag: false,
      newsocialMedicalFlag: false,
      newhomeLeaveFlag: false,
      newschoolFeeFlag: false,
      newrelocationFlag: false,
      newothersFlag: false,
      newseveranceFlag: false,
      newbonusFlag: false,
      newCommInsurancOPFlag: false,
      // new end-------
      maxHeight: h,
      maxHeight2: h2,
      queryLoading: false,
      upFlag: false,
      tableAllData: [], //合计的列表
      tableData: [],
      // columnConfig: nowEditionColumnConfig,
      columnConfig: [],
      addNameArr: addNameData,
      addDateArr: addDateData,
      basePayRollArr: basePayRollData,
      HCArr: HCData,
      // TotalCostData: totalCostData,
      thirteenSalaryArr: thirteenSalaryData,
      holidayPayArr: holidayPayData,
      salesCommArr: salesCommData,
      socialChargesArr: socialChargesData,
      socialChargesOp121Arr: socialChargesOp121Data,
      medicalInsurancArr: medicalInsuranceData,
      // socialCommericalArr: socialCommericalData,
      homeLeaveArr: homeLeaveData,
      schoolFeeArr: schoolFeeData,
      relocationArr: relocationData,
      othersArr: othersData,
      severanceArr: severanceData,
      bonusArr: bonusData,
      CommInsurancOPArr: CommInsurancOPData,
      adjustmentFlag: false,
      nameFlag: false,
      dateFlag: false,
      HCFlag: false,
      SalaryFullyearFlag: false,
      payRollFlag: false,
      thirteenSalaryFlag: false,
      holidayPayFlag: false,
      salesCommFlag: false,
      socialChargesFlag: false,
      socialCharges121Flag: false,
      socialMedicalFlag: false,
      // socialCommericalFlag: false,
      homeLeaveFlag: false,
      schoolFeeFlag: false,
      relocationFlag: false,
      othersFlag: false,
      severanceFlag: false,
      bonusFlag: false,
      CommInsurancOPFlag: false,
      clickIndex: '',
      sumColumnConfig: sumColumnConfig,
      apiAlltable: {},
      sumFlag: '',
      summaries: {
        totalCost: {}
      },
      editTimeFlag: false,
      jumpParams: {},
      addFlag: { addFlag: 'add' },
      selectedItems: [],
      rowId: '',
      editionId: '',
      id: '',
      nowMonth: new Date().getMonth() + 1,
      Op111FilterConfig: {},
      scrollLeftList: [],
      selectVal: [],
      expendObj: {
        tag: '',
        arr: [],
        index: null
      },
      tableLeftFixDomLength: 0,
      tableHeaderDic: {
        'newpayRollFlag': 'Sub-Total Base payroll$15',
        'newthirteenSalaryFlag': 'Sub-Total 13th payroll$16',
        'newholidayPayFlag': 'Sub-Total Holiday pay$17',
        'newsalesCommFlag': 'Sub-Total Sales Comm-OP113$18',
        'newsocialChargesFlag': 'Sub-Total Social Charges$-1',
        'newsocialCharges121Flag': 'Sub-Total Social ChargesOp121$19',
        'newsocialMedicalFlag': 'Sub-Total Medical Insurance$20',
        'newhomeLeaveFlag': 'Sub-Total HomeLeave$22',
        'newschoolFeeFlag': 'Sub-Total Housing School Fee$23',
        'newrelocationFlag': 'Sub-Total Relocation$24',
        'newothersFlag': 'Sub-Total Filling Fee&Others$25',
        'newseveranceFlag': 'Sub-Total Severance$26',
        'newbonusFlag': 'Sub-Total Bonus$27',
        'newCommInsurancOPFlag': 'Sub-Total CommInsuranceIITOp126$21'
      },
      tabnleHeaderOffsetLeftList: [],
      chooseTab:0,
    }
  },
  computed: {
    // new start---------
    newColumnOptions() {
      return this.newColumnConfig.filter((item) => item.visible)
    },
    // new end--------
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    columnOptions2() {
      return this.sumColumnConfig.filter((item) => item.visible)
    }
  },
  watch: {
    buttonActive(newValue, oldValue) {
      const res = getNowEditionColumnConfig()
      res.forEach((item) => {
        if (item.isYear) {
          item.name = item.name + this.indexYear
          this.columnConfig.push(JSON.parse(item))
        } else {
          this.columnConfig.push(JSON.parse(item))
        }
      })
      if (
        this.buttonActive === 1 ||
        (this.buttonActive == 2 && this.changeActive == 1)
      ) {
        this.sumFlag = 0
      } else if (this.buttonActive == 2 && this.changeActive == 2) {
        this.sumFlag = 1
      } else if (this.buttonActive == 3) {
        this.sumFlag = '2'
      } else {
        this.sumFlag = ''
      }
      this.sumColumnConfig.map((v) => {
        if (v.key != 'salaryFullYear' && v.key != 'title') {
          let a = ''
          a = v.name.slice(0, 14)
          v.name = a + '-' + this.indexYear
        }
      })

      this.filterQuery()
      this.getOp111Sum()
    }
  },
  created() {
    this.id = this.$route.query.id
    this.editionId = this.$route.query.id
    this.chooseTab = this.$route.query.chooseTab
    console.log(this.chooseTab);
    changeAllYear()
    const res = getNowEditionColumnConfig()
    res.forEach((item) => {
      item = JSON.parse(item)
      if (item.isYear) {
        item.name = item.name + this.indexYear
        this.columnConfig.push(item)
      } else {
        this.columnConfig.push(item)
      }
    })
    if (
      this.buttonActive == 1 ||
      (this.buttonActive == 2 && this.changeActive == 1)
    ) {
      this.sumFlag = 0
    } else if (this.buttonActive == 2 && this.changeActive == 2) {
      this.sumFlag = 1
    } else if (this.buttonActive == 3) {
      this.sumFlag = '2'
    } else {
      this.sumFlag = ''
    }
    this.sumColumnConfig.map((v) => {
      if (v.key != 'salaryFullYear' && v.key != 'title') {
        let a = ''
        a = v.name.slice(0, 14)
        v.name = a + '-' + this.indexYear
      }
    })
    let indexMonth = this.$route.query.indexMonth
    this.nowMonth = indexMonth
    // this.filterQuery()
    // this.getOp111Sum()

    // this.tableAllData.push(...Object.values(this.apiAlltable))
    // this.tableAllData.map((v,index)=>{
    //    v.title=Object.keys(this.apiAlltable)[index]
    // })
    console.log('aaa', currency(220000, { symbol: '' }).format())

    // 获取hfmcode
    getEntityHfmCode({}).then((res) => {
      if (res && res.code === 200) {
        // console.log(111111, res.data)
        this.hfmCodeOptions = res.data
      }
    })

    // 获取ceo接口
    getOrganizationsUser({}).then((res) => {
      if (res && res.code === 200) {
        // console.log(222222, res.data)
        this.ceoOoptions = res.data
      }
    })
  },

  methods: {
    formatLabel(val, type = 'Total Cost') {
      if(!val) return;
      if(type == 'Total Cost') {
        let arr = val.split(' ');
        return arr[0] + ' ' + arr[1] + '<br />' + arr[2]
      } else if (type == 'basePayRoll') {
        let arr = val.split('basePayRoll');
        return 'basePayRoll' + '<br />' + arr[1]
      } else if (type == 'Holiday Pay') {
        let arr = val.split('Holiday Pay');
        return 'Holiday Pay' + '<br />' + arr[1]
      } else if (type == 'Sales Comm') {
        let arr = val.split('Sales Comm');
        return 'Sales Comm' + '<br />' + arr[1]
      } else if (type == 'Social Charge') {
        let arr = val.split('Social Charge');
        return 'Social Charge' + '<br />' + arr[1]
      } else if (type == 'medical Insurance') {
        let arr = val.split('medical Insurance');
        return 'medical Insurance' + '<br />' + arr[1]
      } else if (type == 'commInsuranceIITOp126') {
        let arr = val.split('commInsuranceIITOp126');
        return 'commInsuranceIITOp126' + '<br />' + arr[1]
      } else if (type == 'Home Leave') {
        let arr = val.split('Home Leave');
        return 'Home Leave' + '<br />' + arr[1]
      } else if (type == 'Housing School FeeOthers') {
        let arr = val.split('Housing School FeeOthers');
        return 'Housing School FeeOthers' + '<br />' + arr[1]
      } else if (type == 'Relocation') {
        let arr = val.split('Relocation');
        return 'Relocation' + '<br />' + arr[1]
      } else if (type == 'Filling Fee Others') {
        let arr = val.split('Filling Fee Others');
        return 'Filling Fee Others' + '<br />' + arr[1]
      } else if (type == 'Severance') {
        let arr = val.split('Severance');
        return 'Severance' + '<br />' + arr[1]
      } else if (type == 'Bonus') {
        let arr = val.split('Bonus');
        return 'Bonus' + '<br />' + arr[1]
      }
      else {
        return val
      }
    },
    addExtendList(){
      console.log(1111111111, this.extendList, this.topData);

      let rowVo = JSON.parse(JSON.stringify(initOps111SumTableData))
      rowVo.title = ''
      // 1: 之前添加; 2: 本次添加;
      rowVo.newFlag = 2
      this.topData.push(rowVo)








      
      // let salaryIncreaseVo = JSON.parse(JSON.stringify(initOps111SumTableData))
      // salaryIncreaseVo.title = 'Salary Increase(2%)'
      // salaryIncreaseVo.type = '1'
      // salaryIncreaseVo.versionId = this.editionId
      // let salaryIncreaseAdditionalVo = JSON.parse(JSON.stringify(initOps111SumTableData))
      // salaryIncreaseAdditionalVo.title = 'Salary Increase (additional)'
      // salaryIncreaseAdditionalVo.type = '2'
      // salaryIncreaseAdditionalVo.versionId = this.editionId
      // let internVo = JSON.parse(JSON.stringify(initOps111SumTableData))
      // internVo.title = 'Intern'
      // internVo.type = '3'
      // internVo.versionId = this.editionId
      // let hcPlugVo = JSON.parse(JSON.stringify(initOps111SumTableData))
      // hcPlugVo.title = 'HC Plug'
      // hcPlugVo.type = '5'
      // hcPlugVo.versionId = this.editionId
      // let pcPlugVo =  JSON.parse(JSON.stringify(initOps111SumTableData))
      // pcPlugVo.title = 'PC Plug'
      // pcPlugVo.type = '4'
      // pcPlugVo.versionId = this.editionId
      // let topData = [
      //   salaryIncreaseVo,
      //   salaryIncreaseAdditionalVo,
      //   internVo,
      //   pcPlugVo,
      //   hcPlugVo,
      // ]
      // let config = {
      //   'sumTotalCostColumn' : [],
      //   ref:`multipleAllTable${this.extendList.length + 1}Ref`,
      //   newadjustmentFlag: false,
      //   newnameFlag: false,
      //   newdateFlag: false,
      //   newHCFlag: false,
      //   newSalaryFullyearFlag: false,
      //   newpayRollFlag: false,
      //   newthirteenSalaryFlag: false,
      //   newholidayPayFlag: false,
      //   newsalesCommFlag: false,
      //   newsocialChargesFlag: false,
      //   newsocialCharges121Flag: false,
      //   newsocialMedicalFlag: false,
      //   newhomeLeaveFlag: false,
      //   newschoolFeeFlag: false,
      //   newrelocationFlag: false,
      //   newothersFlag: false,
      //   newseveranceFlag: false,
      //   newbonusFlag: false,
      //   newCommInsurancOPFlag: false,
      //   newClickIndex:'',
      //   expendObj: {
      //     tag: '',
      //     arr: [],
      //     index: null
      //   },
      // }
      // this.dtsumColumn(config)
      // this.extendList.push({'val':topData,config:config})
      // console.log(this.extendList);
    },
    delExtendList(index){
      this.extendList.splice(index,1)
    },
    adjustDis() {
      return true
      // 2023-12
      // this.submitStatus = this.$route.query.submitStatus
      // if (this.buttonActive == '1') {
      //   return ['待提交'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '1') {
      //   return ['待提交', '待汇总'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '2') {
      //   return ['已汇总', 'BPLeader驳回', 'Finance审批不通过'].includes(
      //     this.submitStatus
      //   )
      // } else {
      //   return false
      // }
    },
    // newstart----
    a() {
      // editOpNowSummary({
      //   flag:0,
      //   list:[...this.topData,...this.botData]
      // })
      // this.getOpsum()
      // console.log("------",this.sumTotalCostColumn,this.topData)
      console.log('------', this.tableAllData, this.columnOptions2)
      console.log('------top', this.topData)
    },
    /**
     * 删除一条
     */
    deleteItem(item) {
      this.$confirm('次操作将删除此条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.topData.splice(item.$index, 1);
          this.$message({
            type: 'success',
            message: '数据已删除，保存后生效!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    save() {
      let extendList = []
      let topData = []
      this.topData.forEach(element => {
        if (element.newFlag) {
          extendList.push(element)
        } else {
          topData.push(element)
        }
      });

      // 当前版本0 汇总版本明细版本0 汇总版本汇总版本1
      let flag
      // if(this.buttonActive==2&&this.changeActive==2 || this.buttonActive==1){
      //   flag=this.buttonActive-1
      // }else if(this.buttonActive==2&&this.changeActive==1){
      //   flag = 0;
      // }
      if (this.buttonActive == 2 && this.changeActive == 1) {
        flag = 0
      } else {
        flag = this.buttonActive - 1
      }
      editOpNowSummary({
        flag: flag,
        versionId: this.editionId,
        list: [...topData, ...this.botData],
        extendList: extendList,
      }).then((res) => {
        if (res && res.code === 200) {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.getOpsum()
        }
      })
    },
    getOpsum() {
      let flag
      // if(this.buttonActive==2&&this.changeActive==2 || this.buttonActive==1){
      //   flag=this.buttonActive-1
      // }else if(this.buttonActive==2&&this.changeActive==1){
      //   flag = 0;
      // }
      if (this.buttonActive == 2 && this.changeActive == 1) {
        flag = 0
      } else {
        flag = this.buttonActive - 1
      }
      let data = {
        versionId: this.editionId,
        type: flag,
        id:this.editionId,
        isRfVersion:this.buttonActive === 1,
        year:this.indexYear,
      }
      if (this.buttonActive == 3) {
        getOpHistorysum(data).then((res) => {
          if (res && res.code === 200) {
            let salaryIncreaseVo = res.data.salaryIncreaseVo || {}
            salaryIncreaseVo.title = 'Salary Increase(2%)'
            let salaryIncreaseAdditionalVo =
              res.data.salaryIncreaseAdditionalVo || {}
            salaryIncreaseAdditionalVo.title = 'Salary Increase (additional)'
            let internVo = res.data.internVo || {}
            internVo.title = 'Intern'
            let pcPlugVo = res.data.pcPlugVo || {}
            pcPlugVo.title = 'PC Plug'
            let hcPlugVo = res.data.hcPlugVo || {}
            hcPlugVo.title = 'HC Plug'
            let totalCostVo = res.data.totalCostVo || {}
            totalCostVo.title = 'Total Cost'
            let hcInHfmVo = res.data.hcInHfmVo || {}
            hcInHfmVo.title = 'HC in HFM'
            let internHcVo = res.data.internHcVo || {}
            internHcVo.title = 'Intern HC'
            this.topData = [
              salaryIncreaseVo,
              salaryIncreaseAdditionalVo,
              internVo,
              pcPlugVo,
              hcPlugVo,
            ]
            this.totalData = [
              totalCostVo
            ]
            this.botData = [hcInHfmVo, internHcVo]
          }
        })
      } else {
        this.queryLoading = true
        getOpsum(data).then((res) => {
          this.queryLoading = false
          if (res && res.code === 200) {
            let salaryIncreaseVo = res.data.salaryIncreaseVo || {}
            salaryIncreaseVo.title = 'Salary Increase(2%)'
            let salaryIncreaseAdditionalVo =
              res.data.salaryIncreaseAdditionalVo || {}
            salaryIncreaseAdditionalVo.title = 'Salary Increase (additional)'
            let internVo = res.data.internVo || {}
            internVo.title = 'Intern'
            let pcPlugVo = res.data.pcPlugVo || {}
            pcPlugVo.title = 'PC Plug'
            let hcPlugVo = res.data.hcPlugVo || {}
            hcPlugVo.title = 'HC Plug'
            let totalCostVo = res.data.totalCostVo || {}
            totalCostVo.title = 'Total Cost'
            let hcInHfmVo = res.data.hcInHfmVo || {}
            hcInHfmVo.title = 'HC in HFM'
            let internHcVo = res.data.internHcVo || {}
            internHcVo.title = 'Intern HC'
            this.topData = [
              salaryIncreaseVo,
              salaryIncreaseAdditionalVo,
              internVo,
              pcPlugVo,
              hcPlugVo,
            ]
            this.extendList = []
            if(res.data.extendList){
              res.data.extendList.forEach(a => {
                for (var key in a) {
                  a[key][0].newFlag = 1
                  this.topData.push(a[key][0])
                }
              })
              // res.data.extendList.forEach(a=>{
              //   for (const key of Object.keys(a)) {
              //     let obj = a[key]
              //     obj.map(o=>{
              //       if(o.type=='1'){
              //         o.title = 'Salary Increase(2%)'
              //       }else if(o.type=='2'){
              //         o.title = 'Salary Increase (additional)'
              //       }else if(o.type=='3'){
              //         o.title = 'Intern'
              //       }else if(o.type=='4'){
              //         o.title = 'PC Plug'
              //       }else if(o.type=='5'){
              //         o.title = 'HC Plug'
              //       }
              //     })
              //     let config = {
              //       sumTotalCostColumn : [],
              //       ref:`multipleAllTable${this.extendList.length + 1}Ref`,
              //       newadjustmentFlag: false,
              //       newnameFlag: false,
              //       newdateFlag: false,
              //       newHCFlag: false,
              //       newSalaryFullyearFlag: false,
              //       newpayRollFlag: false,
              //       newthirteenSalaryFlag: false,
              //       newholidayPayFlag: false,
              //       newsalesCommFlag: false,
              //       newsocialChargesFlag: false,
              //       newsocialCharges121Flag: false,
              //       newsocialMedicalFlag: false,
              //       newhomeLeaveFlag: false,
              //       newschoolFeeFlag: false,
              //       newrelocationFlag: false,
              //       newothersFlag: false,
              //       newseveranceFlag: false,
              //       newbonusFlag: false,
              //       newCommInsurancOPFlag: false,
              //       newClickIndex:'',
              //       expendObj: {
              //         tag: '',
              //         arr: [],
              //         index: null
              //       },
              //     }
              //     this.dtsumColumn(config)
              //     this.extendList.push({'val':obj,config:config})
              //     console.log(this.extendList);
              //   }
              // })
              // console.log(this.extendList);
            }
            this.totalData = [
              totalCostVo
            ]
            this.botData = [hcInHfmVo, internHcVo]
          }
        })
      }
    },
    sumColumn() {
      let arr
      this.sumColumnConfig.map((v) => {
        if (v.key != 'salaryFullYear' && v.key != 'title') {
          let a = ''
          a = v.name.slice(0, 14)
          v.name = a + '-' + this.indexYear
        }
      })
      arr = this.sumColumnConfig

      this.sumTotalCostColumn = arr.concat(this.leaderColumnConfig)
      this.sumTotalCostColumn.splice(1, 0, {
        key: 'ceo',
        name: 'CEO',
        align: "right",
        dataType: "number",
        fixed: false,
        sortable: false,
        visible: true,
        width: 180,
      });
      this.sumTotalCostColumn.splice(1, 0, {
        key: 'hfmCode',
        name: 'HFM Code',
        align: "right",
        dataType: "number",
        fixed: false,
        sortable: false,
        visible: true,
        width: 180,
      });
    },
    dtsumColumn(config) {
      let arr
      this.sumColumnConfig.map((v) => {
        if (v.key != 'salaryFullYear' && v.key != 'title') {
          let a = ''
          a = v.name.slice(0, 14)
          v.name = a + '-' + this.indexYear
        }
      })
      arr = this.sumColumnConfig

      config.sumTotalCostColumn = arr.concat(this.leaderColumnConfig)
    },
    newHeaderExpend(row) {
      if (row.label == 'Sub-Total Base payroll') {
        this.newCutExpendHeader('newpayRollFlag', this.basePayRollArr)
      } else if (row.label == 'Sub-Total 13th payroll') {
        this.newCutExpendHeader('newthirteenSalaryFlag', this.thirteenSalaryArr)
      } else if (row.label == 'Sub-Total Holiday pay') {
        this.newCutExpendHeader('newholidayPayFlag', this.holidayPayArr)
      } else if (row.label == 'Sub-Total Sales Comm-OP113') {
        this.newCutExpendHeader('newsalesCommFlag', this.salesCommArr)
      } else if (row.label == 'Sub-Total Social Charges') {
        this.newCutExpendHeader('newsocialChargesFlag', this.socialChargesArr)
      } else if (row.label == 'Sub-Total Social ChargesOp121') {
        this.newCutExpendHeader(
          'newsocialCharges121Flag',
          this.socialChargesOp121Arr
        )
      } else if (row.label == 'Sub-Total Medical Insurance') {
        this.newCutExpendHeader('newsocialMedicalFlag', [
          ...this.medicalInsurancArr
          // ...this.CommInsurancOPArr
        ])
      } else if (row.label == 'Sub-Total HomeLeave') {
        this.newCutExpendHeader('newhomeLeaveFlag', this.homeLeaveArr)
      } else if (row.label == 'Sub-Total Housing School Fee') {
        this.newCutExpendHeader('newschoolFeeFlag', this.schoolFeeArr)
      } else if (row.label == 'Sub-Total Relocation') {
        this.newCutExpendHeader('newrelocationFlag', this.relocationArr)
      } else if (row.label == 'Sub-Total Filling Fee&Others') {
        this.newCutExpendHeader('newothersFlag', this.othersArr)
      } else if (row.label == 'Sub-Total Severance') {
        this.newCutExpendHeader('newseveranceFlag', this.severanceArr)
      } else if (row.label == 'Sub-Total Bonus') {
        this.newCutExpendHeader('newbonusFlag', this.bonusArr)
      } else if (row.label == 'Sub-Total CommInsuranceIITOp126') {
        this.newCutExpendHeader('newCommInsurancOPFlag', this.CommInsurancOPArr)
      }
    },
    newCutExpendHeader(expendflag, getAddArr) {
      if (!this[expendflag]) {
        this.newExpendHeader(expendflag, getAddArr)
      } else {
        this.newCutHeader(expendflag, getAddArr)
      }
    },
    async newExpendHeader(expendflag, getAddArr) {
      // console.log('调用...expend',getAddArr.length)
      await this.newCutExpendata();
      let newIndex = Number(this.newClickIndex) > this.expendObj.index ? Number(this.newClickIndex) - this.expendObj.arr.length : Number(this.newClickIndex)
      if (this.newClickIndex) {
        this.expendObj.tag = expendflag;
        this.expendObj.index = newIndex;
        this.expendObj.arr = JSON.parse(JSON.stringify(getAddArr));
        this[expendflag] = !this[expendflag]
        Number(this.newClickIndex)
        this.sumTotalCostColumn.splice(
          newIndex,
          0,
          ...getAddArr
        )
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          this.$refs.newMultipleAllTable.doLayout()
          const expendDomList = this.$refs.newMultipleAllTable.$el.querySelector('.el-table__header').querySelectorAll('[data-tag]');
          let tabelDicArr = this.tableHeaderDic[expendflag].split('$')
          expendDomList.forEach(dom => {
            if (dom.getAttribute('data-tag') == tabelDicArr[0]) {
              let parentDom = dom.offsetParent
              // console.log('111**0', parentDom.offsetParent, parentDom)
              let scrollDom = this.$refs.newMultipleAllTable.$el.querySelector('.el-table__body-wrapper')
              let leftL = (tabelDicArr[1] != '-1' ? this.tabnleHeaderOffsetLeftList[tabelDicArr[1]] : parentDom.offsetParent.offsetLeft) - this.tableLeftFixDomLength
              scrollDom.scroll({left:leftL})
            }
          })
          this.newClickIndex = ''
        })
      }
    },
    newCutHeader(expendflag, getAddArr) {
      // 11是这个增加的长度
      if (this.newClickIndex) {
        let getAddArrLength = getAddArr.length
        this[expendflag] = !this[expendflag]
        this.sumTotalCostColumn.splice(
          Number(this.newClickIndex),
          getAddArrLength
        )
        this.expendObj.tag = '';
        this.expendObj.arr = [];
        this.expendObj.index = null;
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          this.$refs.newMultipleAllTable.doLayout()
          this.newClickIndex = ''
        })
      }
    },
    newCutExpendata() {
      new Promise((resolve, reject) => {
        if(!this.expendObj.tag || !this.expendObj.arr || (!this.expendObj.index && this.expendObj.index != 0) || this.expendObj.arr.length == 0) {
        } else {
          let length = this.expendObj.arr.length;
          this[this.expendObj.tag] = false;
          this.sumTotalCostColumn.splice(
            Number(this.expendObj.index),
            length
          )
          this.$nextTick(() => {
            //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
            this.$refs.newMultipleAllTable.doLayout()
            resolve()
          })
        }
      })

    },
    newExpendCounfig(row) {
      this.newClickIndex = row.$index
      this.$refs.newMultipleAllTable.doLayout()
    },

    //动态 2023-12 新增

    dtHeaderExpend(row,config) {
      console.log(row);
      if (row.label == 'Sub-Total Base payroll') {
        this.dtCutExpendHeader('newpayRollFlag', this.basePayRollArr,config,row)
      } else if (row.label == 'Sub-Total 13th payroll') {
        this.dtCutExpendHeader('newthirteenSalaryFlag', this.thirteenSalaryArr,config,row)
      } else if (row.label == 'Sub-Total Holiday pay') {
        this.dtCutExpendHeader('newholidayPayFlag', this.holidayPayArr,config,row)
      } else if (row.label == 'Sub-Total Sales Comm-OP113') {
        this.dtCutExpendHeader('newsalesCommFlag', this.salesCommArr,config,row)
      } else if (row.label == 'Sub-Total Social Charges') {
        this.dtCutExpendHeader('newsocialChargesFlag', this.socialChargesArr,config,row)
      } else if (row.label == 'Sub-Total Social ChargesOp121') {
        this.dtCutExpendHeader(
            'newsocialCharges121Flag',
            this.socialChargesOp121Arr,config,row
        )
      } else if (row.label == 'Sub-Total Medical Insurance') {
        this.dtCutExpendHeader('newsocialMedicalFlag', [
          ...this.medicalInsurancArr
          // ...this.CommInsurancOPArr
        ],config,row)
      } else if (row.label == 'Sub-Total HomeLeave') {
        this.dtCutExpendHeader('newhomeLeaveFlag', this.homeLeaveArr,config,row)
      } else if (row.label == 'Sub-Total Housing School Fee') {
        this.dtCutExpendHeader('newschoolFeeFlag', this.schoolFeeArr,config,row)
      } else if (row.label == 'Sub-Total Relocation') {
        this.dtCutExpendHeader('newrelocationFlag', this.relocationArr,config,row)
      } else if (row.label == 'Sub-Total Filling Fee&Others') {
        this.dtCutExpendHeader('newothersFlag', this.othersArr,config,row)
      } else if (row.label == 'Sub-Total Severance') {
        this.dtCutExpendHeader('newseveranceFlag', this.severanceArr,config,row)
      } else if (row.label == 'Sub-Total Bonus') {
        this.dtCutExpendHeader('newbonusFlag', this.bonusArr,config,row)
      } else if (row.label == 'Sub-Total CommInsuranceIITOp126') {
        this.dtCutExpendHeader('newCommInsurancOPFlag', this.CommInsurancOPArr,config,row)
      }
    },
    dtCutExpendHeader(expendflag, getAddArr,config,row) {
      if (!config[expendflag]) {
        this.dtExpendHeader(expendflag, getAddArr, config,row)
      } else {
        this.dtCutHeader(expendflag, getAddArr, config)
      }
    },
    async dtExpendHeader(expendflag, getAddArr,config,row) {
      // console.log('调用...expend',getAddArr.length)
      await  this.dtCutExpendata(config)
      let newIndex = Number(config.newClickIndex) > config.expendObj.index ? Number(config.newClickIndex) - config.expendObj.arr.length : Number(config.newClickIndex)
      if (config.newClickIndex) {
        // config.sumTotalCostColumn.splice(
        //     newIndex,
        //     0,
        //     ...getAddArr
        // )
        for (let i = 0; i < this.extendList.length; i++) {
          let ls =  this.extendList[i]
          if(ls.config.ref === config.ref){
            this.extendList[i].config.expendObj.tag = expendflag;
            this.extendList[i].config.expendObj.index = newIndex;
            this.extendList[i].config.expendObj.arr = JSON.parse(JSON.stringify(getAddArr));
            this.extendList[i].config[expendflag] = !config[expendflag]
            Number(config.newClickIndex)
            console.log(config.sumTotalCostColumn.length);
            this.extendList[i].config.sumTotalCostColumn.splice(
              newIndex,
              0,
              ...getAddArr
            )
          }
        }
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          console.log(config.sumTotalCostColumn.length);
          let table = this.$refs[config.ref][0]
          table.doLayout()
          const expendDomList = table.$el.querySelector('.el-table__header').querySelectorAll('[data-tag]');
          let tabelDicArr = this.tableHeaderDic[expendflag].split('$')
          expendDomList.forEach(dom => {
            if (dom.getAttribute('data-tag') == tabelDicArr[0]) {
              let parentDom = dom.offsetParent
              // console.log('111**0', parentDom.offsetParent, parentDom)
              let scrollDom = table.$el.querySelector('.el-table__body-wrapper')
              let leftL = (tabelDicArr[1] != '-1' ? this.tabnleHeaderOffsetLeftList[tabelDicArr[1]] : parentDom.offsetParent.offsetLeft) - this.tableLeftFixDomLength
              scrollDom.scroll({left:leftL})
            }
          })
          config.newClickIndex = ''
          console.log(this.extendList);
        })
      }

    },
    dtCutHeader(expendflag, getAddArr,config) {
      // 11是这个增加的长度
      if (config.newClickIndex) {
        let getAddArrLength = getAddArr.length
        config[expendflag] = !config[expendflag]
        config.sumTotalCostColumn.splice(
            Number(config.newClickIndex),
            getAddArrLength
        )
        config.expendObj.tag = '';
        config.expendObj.arr = [];
        config.expendObj.index = null;
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          this.$refs[config.ref][0].doLayout()
          config.newClickIndex = ''
        })
      }
    },
    dtCutExpendata(config) {
      new Promise((resolve, reject) => {
        if(!config.expendObj.tag || !config.expendObj.arr || (!config.expendObj.index && config.expendObj.index != 0) || config.expendObj.arr.length == 0) {
        } else {
          let length = config.expendObj.arr.length;
          // config.expendObj.tag = '';
          // config.sumTotalCostColumn.splice(
          //     Number(config.expendObj.index),
          //     length
          // )
          for (let i = 0; i < this.extendList.length; i++) {
            let ls = this.extendList[i]
            if (ls.config.ref === config.ref) {
              this.extendList[i].config.sumTotalCostColumn.splice(
                Number(this.extendList[i].config.expendObj.index),
                length
              )
            }
          }
          this.$nextTick(() => {
            //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
            this.$refs[config.ref][0].doLayout()
            resolve()
          })
        }
      })

    },
    dtExpendCounfig(row,config) {
      config.newClickIndex = row.$index
      this.$refs[config.ref][0].doLayout()
      this.dtHeaderExpend(row.column,config)
    },

    // endstart----
    currency(value) {
      return currency(value, { symbol: '' }).format()
    },
    inputMoney() {
      let temp = Number(el.target.value) || null
      this.totalprice = this.priceFormat(temp)
    },
    // 计算有问题，暂时注释
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      const le = columns.length
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        if (this.selectVal.length) {
          const values = this.selectVal.map((item) =>
            Number(item[column.property])
          )
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values
              .reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              .toFixed(2)
          } else {
            sums[index] = '- -'
          }
        } else {
          sums[index] = this.currency(
            parseFloat(this.summaries.totalCost[column.property]) || 0
          )
        }
      })
      sums[1] = null
      sums[2] = null
      sums[3] = null
      sums[4] = null
      sums[le - 1] = null
      if (this.nameFlag && this.dateFlag) {
        let arr = [...this.addDateArr, ...this.addNameArr]
        console.log(arr)
        arr.forEach((item, index) => {
          sums[index + 5] = null
        })
      } else if (this.nameFlag) {
        this.addNameArr.forEach((item, index) => {
          sums[index + 5] = null
        })
      } else if (this.dateFlag) {
        this.addDateArr.forEach((item, index) => {
          sums[index + 5] = null
        })
      }

      return sums
    },
    // getSummaries({ columns, data }) {
    //   const sums = [];
    //   if (columns.length > 0) {
    //     columns.forEach((column, index) => {
    //       if (
    //         [
    //           "remark",
    //           "hcNature",
    //           "englishName",
    //           "startDate",
    //           "type",
    //           "ratio",
    //         ].includes(column.property)
    //       ) {
    //         //过滤某些字段不参与计算
    //         sums[index] = "-";
    //       } else {
    //         let values = data.map((item) => Number(item[column.property]));
    //         if (!values.every((value) => isNaN(value))) {
    //           sums[index] = this.moneyFormat(values.reduce((prev, curr) => {
    //             const value = Number(curr);
    //             if (!isNaN(value)) {
    //               let num = Number(prev) + Number(curr);
    //               return num.toFixed(2);
    //             } else {
    //               return Number(prev).toFixed(2);
    //             }
    //           }, 0.0));
    //         }
    //       }
    //     });
    //   }
    //   return sums;
    // },
    // // 千分位（应该是只支持第一个）
    // moneyFormat(num) {
    //   num = String(num)
    //   const len = num.length
    //   return len <= 6 ? num : num.substr(0, len - 6) + ',' + num.substr(len - 6, 6)
    // },
    uninputMoney() {
      if (!!el.target.value) {
        this.totalprice = this.delcommafy(el.target.value)
      } else {
        this.totalprice = null
      }
    },
    upRow() {
      this.upFlag = !this.upFlag
      if (this.upFlag) {
        this.maxHeight2 = '90px'
        // this.maxHeight = document.getElementsByTagName('body')[0].clientHeight - 450 + 'px'
      } else {
        this.maxHeight2 =
          document.getElementsByTagName('body')[0].clientHeight - 100 + 'px'
        // this.maxHeight = document.getElementsByTagName('body')[0].clientHeight - 780 + 'px'
      }
      this.$refs.newMultipleAllTable.doLayout()
      this.$forceUpdate()
    },
    dealDis(month) {
      return Number(month) < Number(this.nowMonth)
    },
    expendCounfig(row) {
      this.clickIndex = row.$index
      // console.log('下表', row, this.clickIndex)
      this.$refs.newMultipleAllTable.doLayout()
    },
    filterQuery(Op111FilterConfig) {
      this.Op111FilterConfig = Op111FilterConfig
      this.pageConfig.pageNum = 1
      // 取消历史
      // if (this.buttonActive != '3') {
        this.getOp1512Totalcost()
      // }
      this.queryApi(Op111FilterConfig)
    },
    // 获取基础数据维护的列表
    queryApi(Op111FilterConfig) {
      Op111FilterConfig = Op111FilterConfig || this.Op111FilterConfig
      this.queryLoading = true
      if (this.buttonActive == 3) {
        this.getHistoryOp111Table(Op111FilterConfig)
        // this.op111HistorySum()
      } else if (this.buttonActive == 2 && this.changeActive == 2) {
        //汇总版本里得汇总
        this.getSumInSumOp111Table()
        // this.getOp111Sum()
      } else {
        //当前版本进来得时候
        this.getOp111Table(Op111FilterConfig)
        // this.getOp111Sum()
      }

      //查询明细表
    },
    async getOp111Table(Op111FilterConfig) {
      let data = null
      let code = null
      console.log(this.chooseTab);
      if(this.chooseTab == '0'){
        const res = await this.$Api.salary.getOp111TableNew({
          ...Op111FilterConfig,
          id: this.editionId,
          isRfVersion:this.buttonActive === 1,
          year:this.indexYear,
          ...this.pageConfig
        })
        data = res.data
        code = res.code
      }else {
        const res = await this.$Api.salary.getOp111TableNewCEO({
          ...Op111FilterConfig,
          id: this.editionId,
          isRfVersion:this.buttonActive === 1,
          year:this.indexYear,
          ...this.pageConfig
        })
        data = res.data
        code = res.code
      }
      if (code === 200) {
        this.tableData = data.list || []
        if (this.tableData) {
          this.tableData.map((v) => {
            if (
              (v.approvedStatus == 'Approved(New Hire)' ||
                v.approvedStatus == 'Approved(Replacement)') &&
              (v.hcStatus == 'Active' ||
                v.hcStatus == 'Leaver' ||
                v.hcStatus == 'Transfer In' ||
                v.hcStatus == 'Transfer Out' ||
                v.hcStatus == 'Recruiting')
            ) {
              //说明不可修改
              v['hcNatureFlag'] = false
            } else {
              v['hcNatureFlag'] = true
            }
          })
        }
        this.pageConfig.total = data.total
        if (!data.list) {
          this.topData = []
          this.botData = []
          this.totalData = []
        } else {
          this.getOpsum()
        }
        let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
        if (pcTrackerOp111Row__yc) {
          let row = this.tableData.find(
            (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
          )
          if (row) {
            this.$refs.multipleTable.setCurrentRow(row, true)
          }
        }
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getHistoryOp111Table() {
      const { data, code } = await this.$Api.salary.getHistoryOp111Table({
        ...this.Op111FilterConfig,
        versionId: this.editionId,
        type: '2', //历史版本type传2
        ...this.pageConfig
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        if (!data.list) {
          this.topData = []
          this.botData = []
          this.totalData = []
        } else {
          this.getOpsum()
        }
        let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
        if (pcTrackerOp111Row__yc) {
          let row = this.tableData.find(
            (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
          )
          if (row) {
            this.$refs.multipleTable.setCurrentRow(row, true)
          }
        }
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getSumInSumOp111Table() {
      const { data, code } = await this.$Api.salary.getSumInSumOp111Table({
        ...this.Op111FilterConfig,
        versionId: this.editionId,
        type: this.sumFlag,
        ...this.pageConfig
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        if (!data.list) {
          this.topData = []
          this.botData = []
          this.totalData = []
        } else {
          this.getOpsum()
        }
        let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
        if (pcTrackerOp111Row__yc) {
          let row = this.tableData.find(
            (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
          )
          if (row) {
            this.$refs.multipleTable.setCurrentRow(row, true)
          }
        }
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    //获取Op111合计的值    查看此接口与新街口获取数据是一个 不确定需要删除不
    async getOp111Sum() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.getOp111Sum({
        versionId: this.editionId,
        type: this.sumFlag
      })
      if (code === 200) {
        let leftName = {
          totalCostVo: 'Total Cost',
          hcVo: 'HC in HFM',
          internHcVo: 'Intern HC',
          internVo: 'Intern',
          salaryIncreaseVo: 'Salary Increase(2%)',
          salaryIncreaseAdditionalVo: 'Salary Increase (additional)',
          pcPlugVo: 'PC Plug',
          hcPlugVo: 'HC Plug'
        }
        this.apiAlltable = data
        this.tableAllData = []
        for (let i in this.apiAlltable) {
          if (i != 'id' && i != 'versionId') {
            this.tableAllData.push({
              title: leftName[i],
              originTitle: i,
              ...this.apiAlltable[i]
            })
          }
        }
      }
    },
    //获取Op111合计的值
    async op111HistorySum() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.op111HistorySum({
        versionId: this.editionId
      })
      if (code === 200) {
        let leftName = {
          totalCostVo: 'Total Cost',
          hcVo: 'HC in HFM',
          internHcVo: 'Intern HC',
          internVo: 'Intern',
          salaryIncreaseVo: 'Salary Increase(2%)',
          salaryIncreaseAdditionalVo: 'Salary Increase (additional)',
          pcPlugVo: 'PC Plug',
          hcPlugVo: 'HC Plug'
        }
        this.apiAlltable = data
        for (let i in this.apiAlltable) {
          if (i != 'id' && i != 'versionId') {
            this.tableAllData.push({
              title: leftName[i],
              originTitle: i,
              ...this.apiAlltable[i]
            })
          }
        }
      }
    },
    getData() {
      let paramsName = {
        hcPlugVo: 'hcPlug',
        internHcVo: 'internHc',
        internVo: 'intern',
        pcPlugVo: 'pcPlug',
        salaryIncreaseAdditionalVo: 'salaryIncreaseAdditional',
        salaryIncreaseVo: 'salaryIncrease'
      }
      let editSumData = {}
      this.tableAllData.map((v) => {
        if (v.title != 'HC in HFM' && v.title != 'Total Cost') {
          for (let a in v) {
            if (a != 'title' && a != 'originTitle' && a != 'salaryFullYear') {
              let frontAname = a.split('totalCost')[1]
              editSumData[`${paramsName[v.originTitle]}${frontAname}`] = v[a]
            }
          }
        }
      })
      editSumData['id'] = this.apiAlltable.id
      editSumData['versionId'] = this.apiAlltable.versionId

      return editSumData
    },
    handleSelectionChange(val) {
      this.selectVal = val
      // 选的第一个
      // if (val.length) {
      //   t
      //   this.selectedItems = val
      //   this.selecthcIdIds = this.selectedItems.map((v) => v.hcId).join()
      //   this.selectofferProposalIds = this.selectedItems
      //     .map((v) => v.offerProposalId)
      //     .join()
      // } else {
      //   this.selecthcIdIds = ''
      //   this.selectofferProposalIds = ''
      // }
    },
    headerExpend(row) {
      if (row.label == 'English Name') {
        this.cutExpendHeader('nameFlag', this.addNameArr)
      } else if (row.label == 'Start Date') {
        this.cutExpendHeader('dateFlag', this.addDateArr)
      } else if (row.label == 'HC Total') {
        this.cutExpendHeader('HCFlag', this.HCArr)
      } else if (row.label == 'Salary Full year') {
        this.cutExpendHeader('SalaryFullyearFlag', this.TotalCostData)
      } else if (row.label == 'Sub-Total Base payroll') {
        this.cutExpendHeader('payRollFlag', this.basePayRollArr)
      } else if (row.label == 'Sub-Total 13th payroll') {
        this.cutExpendHeader('thirteenSalaryFlag', this.thirteenSalaryArr)
      } else if (row.label == 'Sub-Total Holiday pay') {
        this.cutExpendHeader('holidayPayFlag', this.holidayPayArr)
      } else if (row.label == 'Sub-Total Sales Comm-OP113') {
        this.cutExpendHeader('salesCommFlag', this.salesCommArr)
      } else if (row.label == 'Sub-Total Social Charges') {
        this.cutExpendHeader('socialChargesFlag', this.socialChargesArr)
      } else if (row.label == 'Sub-Total Social ChargesOp121') {
        this.cutExpendHeader('socialCharges121Flag', this.socialChargesOp121Arr)
      } else if (row.label == 'Sub-Total Medical Insurance') {
        this.cutExpendHeader('socialMedicalFlag', this.medicalInsurancArr)
      } else if (row.label == 'Sub-Total HomeLeave') {
        this.cutExpendHeader('homeLeaveFlag', this.homeLeaveArr)
      } else if (row.label == 'Sub-Total Housing School Fee') {
        this.cutExpendHeader('schoolFeeFlag', this.schoolFeeArr)
      } else if (row.label == 'Sub-Total Relocation') {
        this.cutExpendHeader('relocationFlag', this.relocationArr)
      } else if (row.label == 'Sub-Total Filling Fee&Others') {
        this.cutExpendHeader('othersFlag', this.othersArr)
      } else if (row.label == 'Sub-Total Severance') {
        this.cutExpendHeader('severanceFlag', this.severanceArr)
      } else if (row.label == 'Sub-Total Bonus') {
        this.cutExpendHeader('bonusFlag', this.bonusArr)
      } else if (row.label == 'Sub-Total CommInsuranceIITOp126') {
        this.cutExpendHeader('CommInsurancOPFlag', this.CommInsurancOPArr)
      }

      // else if (row.label == 'Sub-total Social Commerical') {
      //   this.cutExpendHeader('socialCommericalFlag', this.socialCommericalArr)
      // }
    },
    cutExpendHeader(expendflag, getAddArr) {
      if (!this[expendflag]) {
        this.expendHeader(expendflag, getAddArr)
      } else {
        this.cutHeader(expendflag, getAddArr)
      }
    },
    expendHeader(expendflag, getAddArr) {
      if (this.clickIndex) {
        this[expendflag] = !this[expendflag]
        this.columnConfig.splice(Number(this.clickIndex - 1), 0, ...getAddArr)
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          this.$refs.multipleTable.doLayout()
          this.clickIndex = ''
        })
      }
    },
    cutHeader(expendflag, getAddArr) {
      // 11是这个增加的长度
      if (this.clickIndex) {
        let getAddArrLength = getAddArr.length
        this[expendflag] = !this[expendflag]
        this.columnConfig.splice(Number(this.clickIndex - 1), getAddArrLength)
        this.$nextTick(() => {
          //  对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
          this.$refs.multipleTable.doLayout()
          this.clickIndex = ''
        })
      }
    },
    async getOp1512Totalcost() {
      const data1 = {
        id: this.id,
        tabOneType: this.buttonActive - 1
      }
      if (this.buttonActive == '2') {
        if (this.changeActive == '1') {
          data1.tabOneType = 0
        } else if (this.changeActive == '2') {
          data1.tabOneType = 1
        }
      }
      const { data, code } = await this.$Api.salary.getOp1512Totalcost(data1)
      if (code === 200) {
        if (data.op111TotalCost) {
          this.summaries.totalCost = data.op111TotalCost
        }
      }
    },
    editTime(row) {
      this.editTimeFlag = true
      this.rowId = row.id
    },
    closeModal() {
      this.editTimeFlag = false
    },
    // 编辑信息
    editdRow(row) {
      setItem('pcTrackerOp111Row__yc', row.id)
      this.addFlag.addFlag = 'edit'
      // this.adjustmentFlag = true
      let op111PageConfig = {
        ...this.Op111FilterConfig,
        versionId: this.editionId,
        type: this.sumFlag,
        ...this.pageConfig
      }
      let paramsData = {
        id: row.id,
        buttonActive: this.buttonActive,
        // op111PageConfig: op111PageConfig,
        params: this.$route.query,
        indexMonth: this.nowMonth,
        year:this.indexYear,
      }
      this.$router.push({
        path: '/home/adjustment',
        query: paramsData
      })
    },
    pushScrollLeft() {
      let scrollLeft = this.$refs.multipleTable.$el.scrollLeft
      this.scrollLeftList.push(scrollLeft)
      console.log(scrollLeft)
    },
    setScrollLeft() {
      setTimeout(() => {
        document.getElementsByClassName(
          'el-table__body-wrapper'
        )[0].scrollLeft = Number(this.scrollLeftList[0])
      }, 1000) //必须是1000以上 才能生效
    },
    editHistory(row) {
      setItem('pcTrackerOp111Row__yc', row.id)
      let tabOneType = ''
      if (this.$route.query.buttonActive == '2') {
        if (this.$route.query.changeActive == '2') {
          tabOneType = '1'
        } else {
          tabOneType = '0'
        }
      }
      const tabTwoType = 'op111'
      const id = row.id
      if (id) {
        this.$router.push({
          path: '/pcTracker/editHistory',
          query: {
            tabOneType,
            tabTwoType,
            id,
            target: 'op111',
            versionId: this.$route.query.id
          }
        })
      } else {
        this.$message.error('缺少id')
      }
    },
    remarkBlur(val) {
      const data = {
        id: val.id,
        remark: val.remark,
        tabOneType: this.buttonActive - 1
      }
      saveOp111Remark(data).then((res) => {
        if (res && res.code === 200) {
          // this.$notify({
          //   title: '保存成功',
          //   message: '',
          //   type: 'success'
          // })
        }
      })
    },
    funclass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          color: 'black',
          'font-weight': 'Blob',
          'font-Size': '16px'
        }
      }
    },
    funColumn({ row, column, rowIndex, columnIndex }) {
      if (
        column.label == 'Salary Full year' ||
        column.label == 'Sub-Total Base payroll'
      ) {
        return {
          // "background":"rgba(186,151,101,0.2)",
          'font-weight': 'Blob',
          'font-Size': '16px',
          color: '#ba9765',
          'font-style': 'italic'
        }
      }
    }
  },
  beforeDestroy() {},
  mounted() {
    // newstart----
    this.sumColumn()
    this.getOpsum()
    // endstart----
    this.$nextTick(() => {
      var widthKK = this.$refs.multipleTable.$el.scrollLeft

      // console.log('scroll样式', this.$refs.newMultipleAllTable.$el.querySelector('.el-table__body-wrapper'))
      const tableCellDom = document.getElementsByClassName('PocTablemtop')[0].getElementsByClassName('el-table__header-wrapper')[0].getElementsByClassName('el-table__cell')
      this.tableLeftFixDomLength = tableCellDom[2].offsetLeft;
      for(let i in tableCellDom) {
        if(!isNaN(parseInt(i))) {
          this.tabnleHeaderOffsetLeftList.push(tableCellDom[i].offsetLeft)
        }
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.PocTablemtop {
  margin: 20px 0;
}
.card-table {
  margin-top: 16px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.AlCenter {
  align-items: center;
}
.JuRight {
  justify-content: flex-end;
}
.iconImg {
  width: 16px;
  height: 16px;
  cursor: pointer;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.editSpan {
  font-size: 12px;
  color: #ba9765;
}
.iconImg180 {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
  cursor: pointer;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.flex {
  display: flex;
}
.columnClass {
}
.editGreenData {
  color: #538a51;
  width: 32px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
}
.editRedData {
  color: #de3934;
  width: 32px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
}
.editGreyData {
  width: 18px;
  height: 14px;
  font-size: 12px;
  color: #c4c8cf;
  line-height: 14px;
}
.editDataSpan {
  width: 43px;
  height: 16px;
  font-size: 14px;
  color: #c4c8cf;
  line-height: 16px;
}
.editRedDataImg {
  position: relative;
  top: 4px;
}
.editBlueData {
  width: 29px;
  height: 14px;
  font-size: 12px;
  color: #6ebaaf;
  line-height: 14px;
}
.editData {
  width: 43px;
  font-size: 14px;
  color: #4e4e50;
  line-height: 16px;
  vertical-align: middle;
}
.editAfterData {
  width: 43px;
  height: 16px;
  font-size: 14px;
  font-family: ArialMT;
  color: #c4c8cf;
  line-height: 16px;
}
.opeartionBox {
  padding: 7px 0 16px 0px;
  box-sizing: border-box;
}
.scopeItemBox {
  width: 170px;
}
.scopeItem {
  width: 130px;
  // padding: 0 20px 0 20px;
  // margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;

  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}
.active {
  color: #303133;
  text-align: center;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 170px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.upWord {
  color: #ba9765;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  span {
    font-weight: 400;
    color: #303133;
  }
}
.up {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(186, 151, 101, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .strangle {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: transparent transparent #ba9765 transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;

    &.down {
      border-color: #ba9765 transparent transparent transparent;
      top: 3px;
    }
  }
}
:deep(::-webkit-scrollbar-corner) {
  background-color: white;
  z-index: 99999;
}
.columnClass {
  .el-table__cell {
    background: rebeccapurple;
  }
}

.pointer {
  cursor: pointer;
}

// 新展开样式
.expand-type{
  display: flex;
  align-items: center;
  margin-left: 2px;
  .type-1{
    width: 2px;
    height: 32px;
    border-radius: 1px;
    background-color: #fff;
    margin-right: 1px;
  }
  .type-2{
    width: 1px;
    height: 32px;
    border-radius: 0.5px;
    background-color: #fff;
  }
}

.format-number{
  margin-right: 31px;
}

:deep(.el-table__body-wrapper) {
       z-index: 2
}
</style>
