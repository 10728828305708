export const initOps111SumTableData = {
  "apr": "",
  "aug": "",
  "basePayrollOp111Apr": 0,
  "basePayrollOp111Aug": 0,
  "basePayrollOp111Dec": 0,
  "basePayrollOp111Feb": 0,
  "basePayrollOp111Jan": 0,
  "basePayrollOp111Jul": 0,
  "basePayrollOp111Jun": 0,
  "basePayrollOp111Mar": 0,
  "basePayrollOp111May": 0,
  "basePayrollOp111Nov": 0,
  "basePayrollOp111Oct": 0,
  "basePayrollOp111Sep": 0,
  "bonusOp162Op164Apr": 0,
  "bonusOp162Op164Aug": 0,
  "bonusOp162Op164Dec": 0,
  "bonusOp162Op164Feb": 0,
  "bonusOp162Op164Jan": 0,
  "bonusOp162Op164Jul": 0,
  "bonusOp162Op164Jun": 0,
  "bonusOp162Op164Mar": 0,
  "bonusOp162Op164May": 0,
  "bonusOp162Op164Nov": 0,
  "bonusOp162Op164Oct": 0,
  "bonusOp162Op164Sep": 0,
  "commInsuranceIitOp126Apr": 0,
  "commInsuranceIitOp126Aug": 0,
  "commInsuranceIitOp126Dec": 0,
  "commInsuranceIitOp126Feb": 0,
  "commInsuranceIitOp126Jan": 0,
  "commInsuranceIitOp126Jul": 0,
  "commInsuranceIitOp126Jun": 0,
  "commInsuranceIitOp126Mar": 0,
  "commInsuranceIitOp126May": 0,
  "commInsuranceIitOp126Nov": 0,
  "commInsuranceIitOp126Oct": 0,
  "commInsuranceIitOp126Sep": 0,
  "dec": "",
  "feb": "",
  "fellingfeeOthersOp132Apr": 0,
  "fellingfeeOthersOp132Aug": 0,
  "fellingfeeOthersOp132Dec": 0,
  "fellingfeeOthersOp132Feb": 0,
  "fellingfeeOthersOp132Jan": 0,
  "fellingfeeOthersOp132Jul": 0,
  "fellingfeeOthersOp132Jun": 0,
  "fellingfeeOthersOp132Mar": 0,
  "fellingfeeOthersOp132May": 0,
  "fellingfeeOthersOp132Nov": 0,
  "fellingfeeOthersOp132Oct": 0,
  "fellingfeeOthersOp132Sep": 0,
  "hcApr": "",
  "hcAug": "",
  "hcDec": "",
  "hcFeb": "",
  "hcJan": "",
  "hcJul": "",
  "hcJun": "",
  "hcMar": "",
  "hcMay": "",
  "hcNov": "",
  "hcOct": "",
  "hcSep": "",
  "holidayPayOp112Apr": 0,
  "holidayPayOp112Aug": 0,
  "holidayPayOp112Dec": 0,
  "holidayPayOp112Feb": 0,
  "holidayPayOp112Jan": 0,
  "holidayPayOp112Jul": 0,
  "holidayPayOp112Jun": 0,
  "holidayPayOp112Mar": 0,
  "holidayPayOp112May": 0,
  "holidayPayOp112Nov": 0,
  "holidayPayOp112Oct": 0,
  "holidayPayOp112Sep": 0,
  "homeLeaveOp132Apr": 0,
  "homeLeaveOp132Aug": 0,
  "homeLeaveOp132Dec": 0,
  "homeLeaveOp132Feb": 0,
  "homeLeaveOp132Jan": 0,
  "homeLeaveOp132Jul": 0,
  "homeLeaveOp132Jun": 0,
  "homeLeaveOp132Mar": 0,
  "homeLeaveOp132May": 0,
  "homeLeaveOp132Nov": 0,
  "homeLeaveOp132Oct": 0,
  "homeLeaveOp132Sep": 0,
  "housingSchoolFeeOthersOp132Apr": 0,
  "housingSchoolFeeOthersOp132Aug": 0,
  "housingSchoolFeeOthersOp132Dec": 0,
  "housingSchoolFeeOthersOp132Feb": 0,
  "housingSchoolFeeOthersOp132Jan": 0,
  "housingSchoolFeeOthersOp132Jul": 0,
  "housingSchoolFeeOthersOp132Jun": 0,
  "housingSchoolFeeOthersOp132Mar": 0,
  "housingSchoolFeeOthersOp132May": 0,
  "housingSchoolFeeOthersOp132Nov": 0,
  "housingSchoolFeeOthersOp132Oct": 0,
  "housingSchoolFeeOthersOp132Sep": 0,
  "id": 0,
  "isExtend": true,
  "jan": "",
  "jul": "",
  "jun": "",
  "mar": "",
  "may": "",
  "medicalInsuranceOp126Apr": 0,
  "medicalInsuranceOp126Aug": 0,
  "medicalInsuranceOp126Dec": 0,
  "medicalInsuranceOp126Feb": 0,
  "medicalInsuranceOp126Jan": 0,
  "medicalInsuranceOp126Jul": 0,
  "medicalInsuranceOp126Jun": 0,
  "medicalInsuranceOp126Mar": 0,
  "medicalInsuranceOp126May": 0,
  "medicalInsuranceOp126Nov": 0,
  "medicalInsuranceOp126Oct": 0,
  "medicalInsuranceOp126Sep": 0,
  "nov": "",
  "oct": "",
  "relocationOp132Apr": 0,
  "relocationOp132Aug": 0,
  "relocationOp132Dec": 0,
  "relocationOp132Feb": 0,
  "relocationOp132Jan": 0,
  "relocationOp132Jul": 0,
  "relocationOp132Jun": 0,
  "relocationOp132Mar": 0,
  "relocationOp132May": 0,
  "relocationOp132Nov": 0,
  "relocationOp132Oct": 0,
  "relocationOp132Sep": 0,
  "salaryFullYear": 0,
  "salesCommOp113Apr": 0,
  "salesCommOp113Aug": 0,
  "salesCommOp113Dec": 0,
  "salesCommOp113Feb": 0,
  "salesCommOp113Jan": 0,
  "salesCommOp113Jul": 0,
  "salesCommOp113Jun": 0,
  "salesCommOp113Mar": 0,
  "salesCommOp113May": 0,
  "salesCommOp113Nov": 0,
  "salesCommOp113Oct": 0,
  "salesCommOp113Sep": 0,
  "sep": "",
  "severanceOp141Apr": 0,
  "severanceOp141Aug": 0,
  "severanceOp141Dec": 0,
  "severanceOp141Feb": 0,
  "severanceOp141Jan": 0,
  "severanceOp141Jul": 0,
  "severanceOp141Jun": 0,
  "severanceOp141Mar": 0,
  "severanceOp141May": 0,
  "severanceOp141Nov": 0,
  "severanceOp141Oct": 0,
  "severanceOp141Sep": 0,
  "socialChargesOp121Apr": 0,
  "socialChargesOp121Aug": 0,
  "socialChargesOp121Dec": 0,
  "socialChargesOp121Feb": 0,
  "socialChargesOp121Jan": 0,
  "socialChargesOp121Jul": 0,
  "socialChargesOp121Jun": 0,
  "socialChargesOp121Mar": 0,
  "socialChargesOp121May": 0,
  "socialChargesOp121Nov": 0,
  "socialChargesOp121Oct": 0,
  "socialChargesOp121Sep": 0,
  "thirteenPayrollOp111Apr": 0,
  "thirteenPayrollOp111Aug": 0,
  "thirteenPayrollOp111Dec": 0,
  "thirteenPayrollOp111Feb": 0,
  "thirteenPayrollOp111Jan": 0,
  "thirteenPayrollOp111Jul": 0,
  "thirteenPayrollOp111Jun": 0,
  "thirteenPayrollOp111Mar": 0,
  "thirteenPayrollOp111May": 0,
  "thirteenPayrollOp111Nov": 0,
  "thirteenPayrollOp111Oct": 0,
  "thirteenPayrollOp111Sep": 0,
  "totalBasePay": 0,
  "totalBonusPay": 0,
  "totalCommInsuranceIitPay": 0,
  "totalCostApr": 0,
  "totalCostAprCol": "",
  "totalCostAug": 0,
  "totalCostAugCol": "",
  "totalCostDec": 0,
  "totalCostDecCol": "",
  "totalCostFeb": 0,
  "totalCostFebCol": "",
  "totalCostJan": 0,
  "totalCostJanCol": "",
  "totalCostJul": 0,
  "totalCostJulCol": "",
  "totalCostJun": 0,
  "totalCostJunCol": "",
  "totalCostMar": 0,
  "totalCostMarCol": "",
  "totalCostMay": 0,
  "totalCostMayCol": "",
  "totalCostNov": 0,
  "totalCostNovCol": "",
  "totalCostOct": 0,
  "totalCostOctCol": "",
  "totalCostSep": 0,
  "totalCostSepCol": "",
  "totalFellingfeeOthersPay": 0,
  "totalHolidayPay": 0,
  "totalHomeLeavePay": 0,
  "totalHousingFundPay": 0,
  "totalHousingSchoolFeeOthersPay": 0,
  "totalMedicalInsurance": 0,
  "totalRelocationPay": 0,
  "totalSalesCommPay": 0,
  "totalSeverancePay": 0,
  "totalSocialChargesOp121Pay": 0,
  "totalThirteenSalaryPay": 0,
  "type": "",
  "versionId": 0
}
