<template>
  <div class="pcTrackerClass">
    <PocCardTable class="filterContainer" ref="filterContainer">
      <div class="flexRow flexBetw">
        <div class="pcth-title-left flexLeft">
          PC Tracker
          <span v-html="'&nbsp;'"></span>
          <span style="color: #303133; font-weight: 500">/ 详情</span>
        </div>
        <div class="pcth-title-right flexRight">
          <el-button :loading="queryLoading" class="buttonrefresh" icon="el-icon-refresh-left" @click="refresh"
            size="mini">
          </el-button>
          <!--            <el-button-->
          <!--              v-if="buttonActive == '1'"-->
          <!--              role="download"-->
          <!--              v-permission-->
          <!--              @click="downloadTrackerData"-->
          <!--              size="mini"-->
          <!--              class="buttonActive"-->
          <!--            >下载</el-button-->
          <!--            >-->
          <el-button size="mini" @click="backIndex">返回</el-button>
          <el-button @click="downloadTrackerData" size="mini">下载</el-button>
<!--          <el-button v-if="buttonActive == 1 && submitStatus !== '已提交'" type="primary" role="submitSummary" v-permission-->
<!--            @click="submitAll" size="mini">提交汇总</el-button>-->

          <el-button type="primary" :loading="queryLoading" size="mini" @click="filterQuery">查询</el-button>
        </div>
      </div>
      <!-- 二级页查询条件一直存在 -->
      <now-edition-management ref="refsConfig1" :chooseTab="chooseTab" v-show="![0,5].includes(chooseTab)"/>
    </PocCardTable>
    <PocCardTable class="contentTable" id="contentTable" ref="contentTable">
      <!-- 操作按钮区域 -->
      <div :class="['opeartionBox', isFixed ? 'fixBox' : '']" id="opeartionBox">
        <div class="flexRow flexBetw">
          <div :class="['flexRow']">
            <div class="scopeItemBox" v-for="i in tabLabelArr" :key="'key' + i + Math.random()" @click="changeTab(i)">
              <el-tooltip v-if="i == 1" :content="tabLabel[i]" placement="top">
                <div class="scopeItem">
                  {{ tabLabel[i] }}
                </div>
              </el-tooltip>
              <div v-else class="scopeItem">
                {{ tabLabel[i] }}
              </div>
              <div style="font-size: 0" class="guidaoactive" v-if="chooseTab == i">
                <img src="@/assets/img/guidaoactive.png" alt="" />
              </div>
            </div>
          </div>
          <div v-if="chooseTab == 2 && buttonActive != 3">
            <!--            <el-button-->
            <!--              type="primary"-->
            <!--              role="inCharge"-->
            <!--              v-permission-->
            <!--              @click="newIcCharge"-->
            <!--              >IC Charge</el-button-->
            <!--            >-->
            <!--               role="inCharge"
              v-permission-->
            <el-button type="primary" @click="chargeOut">IC Charge</el-button>
          </div>
          <div v-if="chooseTab == 3 && buttonActive != 3">
              <el-button :loading="queryLoading" type="primary" role="addContract" v-permission @click="addContract">Add
                  Contractor</el-button>
          </div>
          <div v-if="chooseTab == 4 && buttonActive != 3">
              <el-button :loading="queryLoading" type="primary" role="addFreeLancer" v-permission @click="addFreelancer">Add
                  Freelancer</el-button>
          </div>
          <div v-if="chooseTab == 1 && buttonActive != 3" class="flex AlCenter">
            <!-- 只有op111编辑功能 -->
            <el-button size="mini" type="primary" class="buttonActive" role="op111Save" v-permission
              @click="saveOp111">保存</el-button>
          </div>
          <div v-if="chooseTab == 0 && buttonActive != 3" class="flex AlCenter">
            <!-- summary有编辑功能 -->
            <el-button size="mini" type="primary" class="buttonActive" role="summarySave" v-permission
              @click="summarySave">保存</el-button>
          </div>
          <div v-if="chooseTab == 5 && buttonActive != 3" class="flex AlCenter">
            <el-button size="mini" type="primary" class="buttonActive" role="op111Save"
                       @click="rfSave">保存</el-button>
          </div>
        </div>
        <div style="font-size: 0" class="guidao">
          <img class="guidaoImg" src="@/assets/img/guidao.png" alt="" />
        </div>
      </div>
      <rf-related-table v-if="chooseTab == 5" ref="rfRelatedRef" :editionId="editionId" ></rf-related-table>
      <summary-table v-if="chooseTab == 0" :editionId="editionId" :summaryStatus="summaryStatus" ref="refSummary"
        class="show-table" />
      <op111-table v-if="chooseTab == 1" :buttonActive="buttonActive" :changeActive="changeActive" :indexYear="indexYear"
        :indexMonth="indexMonth" :editionId="editionId" ref="refOp111" class="show-table" />
      <op131-table v-if="chooseTab == 2" v-bind:editionId="editionId" :buttonActive="buttonActive" :indexYear="indexYear"
        :changeActive="changeActive" ref="refOp131" class="show-table" />
      <op1512-table v-if="chooseTab == 3" v-bind:editionId="editionId" :buttonActive="buttonActive"
        :changeActive="changeActive" v-bind:chooseTab="chooseTab" ref="refOp151" class="show-table" />
      <opsContractorTable v-if="chooseTab == 4" :buttonActive="buttonActive" v-bind:editionId="editionId"
        :changeActive="changeActive" v-bind:chooseTab="chooseTab" ref="refOp152" class="show-table" />
      <!--        v-if="gatherFlag"-->
      <finance-modal :fromSumType="'nowSubmit'" ref="finance-modalRef" :selectParentIds="editionId" :userRole="'1'"
        @closeModal="closeModal" />
      <ChargeOp131Dialog ref="ChargeOp131DialogRef" @close="filterQuery" />
      <div v-show="tabChangeShow" class="tab-change-btn-left" ref="tabLeft" id="tab-left"
        :style="{ top: tabChangeTop + 'px', left: tabChangeLeft + 'px' }" @click="changeTabBtn('left')">
        <!-- , this.tabLabelArr[0] == this.chooseTab ? 'no-active-icon': '' -->
        <!-- <i :class="['el-icon-arrow-left', 'tab-left-icon']"></i> -->
        <img class="tab-change-img" src="@/assets/img/turn_left.png" alt="">
      </div>
      <div v-show="tabChangeShow" class="tab-change-btn-right" ref="tabRight" :style="{ top: tabChangeTop + 'px' }" id="tab-right"
        @click="changeTabBtn('right')">
        <!-- , this.tabLabelArr[tabLabelArr.length - 1] == this.chooseTab ? 'no-active-icon': '' -->
        <!-- <i :class="['el-icon-arrow-right', 'tab-right-icon']"></i> -->
        <img class="tab-change-img" src="@/assets/img/turn_right.png" alt="">
      </div>
    </PocCardTable>
  </div>
</template>

<script>
import { getItem, setItem } from '@/utils/storage'
import { debounce, ScrollRaf, throttle } from '@/utils/util';
import { getIsFinance } from "@/api/dynamicPctracker";

const { Vuex } = $PCommon
const { mapGetters } = Vuex

import nowEditionManagement from './components/edition/nowEdition.vue'
import op111Table from './components/table/op111Table.vue'
import op131Table from './components/table/ops131Table'
import op1512Table from './components/table/ops1512Table'
import opsContractorTable from './components/table/opsContractorTable'
import summaryTable from './components/table/summaryTable.vue'

import financeModal from './components/modal/financeModal.vue'
import ChargeOp131Dialog from '@/views/salaryManagement/components/modal/ChargeOp131Dialog/ChargeOp131Dialog.vue'
import RfRelatedTable from "@/views/pcTrackerTrends/components/rfRelatedTable.vue";

export default {
  name: 'pcTracker',
  mixins: [$PCommon.TableMixin],
  components: {
    RfRelatedTable,
    op111Table,
    op131Table,
    op1512Table,
    opsContractorTable,
    summaryTable,
    nowEditionManagement,
    financeModal,
    ChargeOp131Dialog
  },
  props: {
    detailFilterConfig: {
      type: Object
    }
  },

  data() {
    return {
      queryLoading: false,
      tabLabel: {
        5:'RF related',
        0:'Summary',
        1: 'OP111,112,121,126,132,141,162',
        2: 'OP131',
        3: 'OP151',
        4: 'OP152'
      },
      tabLabelArr: [], // [5, 0, 1, 2, 3, 4]
      buttonActive: 1,
      changeActive: '', //汇总版本2，明细版本1
      editionType: 'indexEdition',
      buttonData: {
        1: '当前版本',
        2: '汇总版本',
        3: '历史版本'
      },
      chooseTab: '', // 1
      freelancerFlag: false,
      selectParentIds: '',
      editionId: '',
      fromDetailFlag: '',
      indexYear: '',
      indexMonth: '',
      manageShow: false,
      jumpParams: {},
      selectedItems: [],
      gatherFlag: '',
      empType: '',
      summaryStatus: false,
      submitStatus: '',

      // 页面元素
      leftmenuDom: null, //页面左边菜单
      firstDom: null, // 页面内第一个表格
      filterContainerDom: null, //页面内筛选区域
      scrollDom: null, //页面内滚动条
      opeartionDom: null, //页面操作区域

      headerDom: null, //第一个表格表头
      fixedHeaderLeftDom: null, //第一个表格左侧的固定头部
      fixedHeaderRightDom: null, //第一个表格右侧的固定头部
      tableFixedLeftDom: null, //第一个表格左左侧侧固定元素
      tableFixedLeftBodyDom: null, //第一个表格左侧固定内容
      tableFixedRightDom: null, //第一个表格右侧固定元素
      tableFixedRightBodyDom: null, //第一个表格右侧固定元素
      tabLeftDom: null, //页面左滑按钮

      // 整个页面参数
      pageLeftWIdth: 0,

      // 该页面的参数
      tabChangeTop: -100,
      tabChangeLeft: 0,
      isFixed: false,
      tabChangeShow: false,
      tabHeight: null,
    }
  },
  computed: {
  },
  created() {
    this.editionId = Number(this.$route.query.id)
    this.fromDetailFlag = this.$route.query.fromDetailFlag
    this.buttonActive = Number(this.$route.query.buttonActive)
    this.changeActive = Number(this.$route.query.changeActive)
    this.indexYear = this.$route.query.indexYear
    this.indexMonth = this.$route.query.indexMonth
    // if (this.$route.query.jumpPageConfig) {
    //   this.editionId = Number(this.$route.query.jumpPageConfig.versionId)
    //   this.buttonActive = Number(this.$route.query.buttonActive)
    // }
    this.submitStatus = this.$route.query.submitStatus
    // if (this.fromDetailFlag == 'sumInsum') {
    //   this.tabLabel = {
    //     0: 'Summary',
    //     1: 'OP111,112,121,126,132,141,162',
    //     2: 'OP131',
    //     3: 'OP151',
    //     4: 'OP152'
    //   }
    //   if (
    //     this.$route.query.summaryStatus &&
    //     this.$route.query.summaryStatus === '1'
    //   ) {
    //     this.summaryStatus = true
    //   }
    //   this.tabLabelArr = [0, 1, 2, 3, 4]
    //   this.chooseTab = 0
    // }

    // true就是财务用户，返回false是其他用户
    // 财务用户，只显示前面两个选项卡
    getIsFinance().then(res => {
      if (res.data) {
        this.tabLabelArr = [5, 0]
        this.chooseTab = 5
      } else {
        this.tabLabelArr = [5, 0, 1, 2, 3, 4]
        this.chooseTab = 1
        let pcTrackerChooseTab__yc = getItem('pcTrackerChooseTab__yc')
        if (pcTrackerChooseTab__yc) {
          this.chooseTab = Number(pcTrackerChooseTab__yc)
        }
      }
      this.$nextTick(() => {
        this.filterQuery()
      })
    })
  },
  methods: {
    backIndex() {
      //返回上一级
      // this.$router.go(-1)
      this.$router.push({
        path: '/pcTracker/pcTracker'
      })
    },
    newIcCharge() {
      this.$refs.refOp131.newIcCharge()
    },
    isEdit() {
      // if (this.submitStatus === '已提交') {
      //   this.$message.warning('数据已提交，暂不可编辑')
      //   return false
      // } else {
      //   return true
      // }
      return true
    },
    chargeOut() {
      this.$refs.ChargeOp131DialogRef.show()
    },
    addFreelancer() {
      if (this.isEdit()) {
        this.$refs.refOp152.addFreelancer()
      }
    },
    addContract() {
      if (this.isEdit()) {
        this.$refs.refOp151.addFreelancer()
      }
    },
    changeTab(activeTab) {
      this.chooseTab = activeTab
      setItem('pcTrackerChooseTab__yc', activeTab)
      this.$nextTick(() => {
        this.refresh()
        this.setTabStyle();
      })
    },

    // 下载功能
    async downloadTrackerData() {
      // var params = this.$refs.refsConfig1.getFilters()
      // params.versionId = this.editionId
      // params.type = parseInt(this.buttonActive) - 1
      // if (this.buttonActive == '2') {
      //   if (this.changeActive == '1') {
      //     params.type = 0
      //   } else if (this.changeActive == '2') {
      //     params.type = 1
      //   }
      // }
      // this.exportLoading = true
      // //  判断
      // if (!this.selectofferProposalIdArr.length) {
      //   this.$message({
      //     type: 'error',
      //     message: '报表数据为空，无法导出'
      //   })
      //   this.exportLoading = false
      //   return
      // }
      // await this.$Api.staff.downloadTrackerData({
      //   ...params
      // })
      // 2023-12 修改
      await this.$Api.staff.downloadDynamicPctrackerData({
        id:this.editionId,
        isRfVersion:this.buttonActive === 1,
        year:this.indexYear,
      })
    },
    exportShowTip() {
      this.exportMsg = '数据'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    },
    //提交汇总
    async submitAll() {
      //
      //   this.gatherFlag = true
      // :fromSumType="'nowSubmit'"
      //   ref="finance-modalRef"
      this.$refs['finance-modalRef'].show('nowSubmit')
    },
    //保存sum合计&&summary

    async saveOp111() {
      //保存合计
      // let editAlldata = this.$refs.refOp111.getData()
      // console.log(editAlldata)
      // const { data, code } = await this.$Api.salary.editOp111Sum({
      //   ...editAlldata
      // })
      // if (code === 200) {
      //   this.$message({
      //     type: 'success',
      //     message: '保存成功'
      //   })
      // }
      this.$refs.refOp111.save()
    },
    titleCase(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
    },
    async summarySave() {
      //保存summary
      console.log("summarySave")
      let editAlldata = this.$refs.refSummary.tableAllData
      let keys = Object.keys(editAlldata[0])
      let params = {}
      // editAlldata.forEach((data) => {
      //   if (data.key === 'pc') {
      //     for (let key in keys) {
      //       if (!['id', 'totalCost', 'key', 'title'].includes(keys[key])) {
      //         params[
      //           'pcPer' +
      //           keys[key]
      //             .toLowerCase()
      //             .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
      //         ] = data[keys[key]]
      //       }
      //     }
      //   }
      //   if (data.key === 'revenceAndPc') {
      //     for (let key in keys) {
      //       if (
      //         !['id', 'totalCost', 'key', 'title', 'summaryVersionId'].includes(
      //           keys[key]
      //         )
      //       ) {
      //         params[
      //           'revenue' +
      //           keys[key]
      //             .toLowerCase()
      //             .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
      //         ] = Number(data[keys[key]])
      //       }
      //     }
      //     params['id'] = data['id']
      //     params['summaryVersionId'] = data['summaryVersionId']
      //   }
      // })
      let editRow = ['revenceAndPc',
        // 'op141',
        // 'op162',
        // 'op163',
        // 'op164',
        // 'op165',
        // 'op166',
        // 'op172',
        // 'op161',
        // 'op171',
        // 'op168',
        // 'op10RevenceAndPc',
        // 'op10TotalCostMonth'
      ]
      editAlldata.forEach((item)=>{
        if(editRow.includes(item.key)){
          if(item.key==='op10TotalCostMonth'){
            let a = 'op10t'
            params[`${a}Jan`] = item.jan
            params[`${a}Feb`] = item.feb
            params[`${a}Mar`] = item.mar
            params[`${a}Apr`] = item.apr
            params[`${a}May`] = item.may
            params[`${a}Jun`] = item.jun
            params[`${a}Jul`] = item.jul
            params[`${a}Aug`] = item.aug
            params[`${a}Sep`] = item.sep
            params[`${a}Oct`] = item.oct
            params[`${a}Dec`] = item.dec
            params[`${a}Nov`] = item.nov
            params[`${a}Total`] = item.totalCost
          }else if(item.key==='revenceAndPc'){
            let a = 'revenue'
            params[`${a}Jan`] = item.jan
            params[`${a}Feb`] = item.feb
            params[`${a}Mar`] = item.mar
            params[`${a}Apr`] = item.apr
            params[`${a}May`] = item.may
            params[`${a}Jun`] = item.jun
            params[`${a}Jul`] = item.jul
            params[`${a}Aug`] = item.aug
            params[`${a}Sep`] = item.sep
            params[`${a}Oct`] = item.oct
            params[`${a}Dec`] = item.dec
            params[`${a}Nov`] = item.nov
            params[`${a}Total`] = item.totalCost
          }else if(item.key==='op10RevenceAndPc'){
            let a = 'revenueDown'
            params[`${a}Jan`] = item.jan
            params[`${a}Feb`] = item.feb
            params[`${a}Mar`] = item.mar
            params[`${a}Apr`] = item.apr
            params[`${a}May`] = item.may
            params[`${a}Jun`] = item.jun
            params[`${a}Jul`] = item.jul
            params[`${a}Aug`] = item.aug
            params[`${a}Sep`] = item.sep
            params[`${a}Oct`] = item.oct
            params[`${a}Dec`] = item.dec
            params[`${a}Nov`] = item.nov
            params[`${a}Total`] = item.totalCost
          }else {
            params[`${item.key}Jan`] = item.jan
            params[`${item.key}Feb`] = item.feb
            params[`${item.key}Mar`] = item.mar
            params[`${item.key}Apr`] = item.apr
            params[`${item.key}May`] = item.may
            params[`${item.key}Jun`] = item.jun
            params[`${item.key}Jul`] = item.jul
            params[`${item.key}Aug`] = item.aug
            params[`${item.key}Sep`] = item.sep
            params[`${item.key}Oct`] = item.oct
            params[`${item.key}Dec`] = item.dec
            params[`${item.key}Nov`] = item.nov
            params[`${item.key}Total`] = item.totalCost
          }
        }
      })
      params['versionId'] = this.editionId
      const { code } = await this.$Api.salary.editSumVersionSummaryDetail(
        params
      )
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.$refs.refSummary.filterQuery()
      }
    },
    async rfSave() {
      let tableAllData = this.$refs.rfRelatedRef.tableAllData
      let releatedDetailList = tableAllData.filter(a => ['OpsFixedPersonnelCosts-Oneoff', 'OpsFixedPersonnelCosts-Socialinsurancesubsidy', 'ICproductionplatformrecharge'].includes(a.accountCode))
        .map(a => {
          if (a.accountCode === 'OpsFixedPersonnelCosts-Oneoff') {
            a.type = 'OpsFixedPersonnelCosts-Oneoff'
          } else if (a.accountCode === 'OpsFixedPersonnelCosts-Socialinsurancesubsidy') {
            a.type = 'OpsFixedPersonnelCosts-Socialinsurancesubsidy'
          } else if (a.accountCode === 'ICproductionplatformrecharge') {
            a.type = 'ICproductionplatformrecharge'
          }
          return a
        })
      let params = {
        "id": this.editionId,
        "releatedDetailList": releatedDetailList
      }
      const { code } = await this.$Api.salary.saveRfReleated(
        params
      )
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.$refs.rfRelatedRef.queryApi()
      }


    },
    refresh() {
      this.$refs.refsConfig1.refreshFilter()
      if (this.chooseTab === 1) {
        this.$refs.refOp111.filterQuery()
      } else if (this.chooseTab === 2) {
        this.$refs.refOp131.filterQuery()
      } else if (this.chooseTab === 3) {
        this.$refs.refOp151.filterQuery()
      } else if (this.chooseTab === 4) {
        this.$refs.refOp152.filterQuery()
      } else if (this.chooseTab === 5) {
        this.$refs.rfRelatedRef.filterQuery()
      }else if (this.chooseTab === 0) {
        this.$refs.refSummary.filterQuery()
      }
      // this.$refs.refOp131.filterQuery()
    },
    closeModal() {
      this.filterQuery()
    },
    // 查询按钮
    filterQuery() {
      this.pageConfig.pageNum = 1
      this.filterConfig = this.$refs.refsConfig1.getFilters()
      if (this.chooseTab === 1) {
        this.$refs.refOp111.filterQuery(this.filterConfig)
      } else if (this.chooseTab === 2) {
        this.$refs.refOp131.filterQuery(this.filterConfig)
      } else if (this.chooseTab === 3) {
        this.$refs.refOp151.filterQuery(this.filterConfig)
      } else if (this.chooseTab === 4) {
        this.$refs.refOp152.filterQuery(this.filterConfig)
      }else if (this.chooseTab === 5) {
        this.$refs.rfRelatedRef.filterQuery()
      }else if (this.chooseTab === 0) {
        this.$refs.refSummary.filterQuery()
      }

      // this.$refs..filterQuery()
    },

    /* PC Track 样式调整 START */
    initPageDom() {
      this.leftmenuDom = window.document.getElementById('poc-scrollbar-default');
      this.firstDom = this.$refs.contentTable.$el.getElementsByClassName('show-table')[0].getElementsByClassName('poc-table')[0];
      this.filterContainerDom = this.$refs.filterContainer.$el;
      this.scrollDom = window.document.getElementsByClassName('el-scrollbar__wrap')[1];
      this.headerDom = this.$refs.contentTable.$el.getElementsByClassName('el-table__header-wrapper')[0];
      this.fixedHeaderLeftDom = window.document.getElementsByClassName('el-table__fixed-header-wrapper')[0];
      this.fixedHeaderRightDom = window.document.getElementsByClassName('el-table__fixed-header-wrapper')[1];
      this.tableFixedLeftDom = window.document.getElementsByClassName('el-table__fixed')[0];
      this.tableFixedLeftBodyDom = this.tableFixedLeftDom.getElementsByClassName('el-table__fixed-body-wrapper')[0];
      this.tableFixedRightDom = window.document.getElementsByClassName('el-table__fixed-right')[0];
      if(this.tableFixedRightDom){
        this.tableFixedRightBodyDom = this.tableFixedRightDom.getElementsByClassName('el-table__fixed-body-wrapper')[0];
      }
      this.opeartionDom = window.document.getElementById('opeartionBox');
      this.tabLeftDom = window.document.getElementById('tab-left');
    },
    showTabScroll() {
      if(this.tabLeftDom){
        let tab_bottom = this.tabLeftDom.getBoundingClientRect().bottom;
        this.tabHeight = this.tabHeight > tab_bottom ? this.tabHeight : tab_bottom;
        let first_bottom = this.firstDom.getBoundingClientRect().bottom;
        if(first_bottom + 50 >= this.tabHeight) {
          this.tabChangeShow = true;
        } else {
          this.tabChangeShow = false;
        }
      }
    },
    // 设置切换tab样式
    setTabStyle() {
      if (!this.$refs.refOp111 || this.$refs.refOp111.pageConfig.total <= 4) return
      if (this.$refs.contentTable) {
        let window_height = window.innerHeight;
        let filter_height = this.filterContainerDom.offsetHeight;
        let content_height = window_height - 60 - filter_height - 15;
        let first_height = this.firstDom.clientHeight;
        let _height = content_height > first_height ? first_height : content_height;
        this.tabChangeTop = (_height / 2 + 40) + 60 + filter_height + 15;
        this.tabChangeLeft = this.leftmenuDom.offsetWidth + 15;
        let scrollTop = this.scrollDom.scrollTop;
        if (scrollTop >= 163) {
          this.fixOpeartionBox()
          this.fixTableHeader();
        } else {
          this.removeFixOpeartionBox();
          this.removeFixHeader();
        }
        this.changeTableFixedColumn('must')
      }
    },
    changeTabBtn(type) {
      /* 判断如果是第一页或最后一页，分别不让往左切和往右切 start */
      /* if((type =='left' && this.tabLabelArr[0] == this.chooseTab) || (type == 'right' && this.tabLabelArr[this.tabLabelArr.length - 1] == this.chooseTab)) {
        return;
      }
      let selsctIndex = 0;
      selsctIndex = this.tabLabelArr.findIndex(i => i === this.chooseTab);
      type == 'left' ? this.changeTab(this.tabLabelArr[selsctIndex - 1]) : this.changeTab(this.tabLabelArr[selsctIndex + 1]) */
      /* 判断如果是第一页或最后一页，分别不让往左切和往右切 end */

      /* 控制滚动条 Start */
      let fisterScrollDom = this.firstDom.querySelector('.el-table__body-wrapper');
      let index = 0
      let bigIndex = parseInt((this.firstDom.clientWidth - 400 - 24) / 10);
      let scroll_length = 0
      let Scroll = setInterval(() => {
        fisterScrollDom.scrollLeft = type == 'right' ? fisterScrollDom.scrollLeft + 10 : fisterScrollDom.scrollLeft - 10;
        scroll_length += 10;
        index++;
        if (index == (bigIndex - 1)) {
          clearInterval(Scroll);
        }
      }, 15)
      /* 控制滚动条 End */


    },
    onResize() {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    },
    changeScroll() {
      // this.handleScroll()
      (ScrollRaf(this.handleScroll))()
    },
    // 滚动的时候
    handleScroll(event) {
      this.$nextTick(() => {
        this.showTabScroll();
        if (!this.$refs.refOp111 || this.$refs.refOp111.pageConfig.total <= 4) return
        // 滚动条距文档顶部的距离
        let scrollTop = this.scrollDom.scrollTop;
        if (scrollTop >= 163 && !this.isFixed) {
          this.fixOpeartionBox()
          this.fixTableHeader();
          this.changeTableFixedColumn();
          this.isFixed = true;
        } else if (this.isFixed && scrollTop < 163) {
          this.removeFixOpeartionBox();
          this.removeFixHeader();
          this.changeTableFixedColumn();
          this.isFixed = false;
        }
      })
    },
    // 固定操作区域
    fixOpeartionBox() {
      this.opeartionDom.style.width = (window.innerWidth - this.leftmenuDom.offsetWidth - 30) + 'px';
      this.opeartionDom.style.left = (this.leftmenuDom.offsetWidth + 15) + 'px';
    },
    // 移除操作区域
    removeFixOpeartionBox() {
      this.opeartionDom.style = '';
    },
    // 固定表格头部
    fixTableHeader() {
      if (this.headerDom) {
        this.headerDom.style.cssText += `width: ${this.firstDom.offsetWidth}px;left: ${this.firstDom.getBoundingClientRect().left}px;`;
        this.headerDom.className += ' fixTable';
      }
    },
    // 移除固定表格头部
    removeFixHeader() {
      this.headerDom.className = 'el-table__header-wrapper';
      this.headerDom.style.cssText = ''
    },
    // 表格左侧/右侧固定列
    changeTableFixedColumn(type = '') {
      let scrollTop = this.scrollDom.scrollTop;
      if (scrollTop >= 163 && (!this.isFixed || type == 'must')) {
        // 固定头部
        let menu_width = this.leftmenuDom.offsetWidth;

        this.fixedHeaderLeftDom.style.cssText = `position: fixed;top: 140px;left: ${menu_width + 15 + 20}px;overflow: hidden;width: 173px;z-index: 10000;`

        this.fixedHeaderRightDom.style.cssText = `position: fixed;top: 140px;right: 34px;overflow: hidden;width: 150px;z-index: 10000;display: flex;flex-direction: row-reverse;`
        // fixedHeaderRight.style.top = (scrollTop - 163 + 29) + 'px';
        // fixedHeaderRight.style.zIndex = 10000;

        // 固定左部
        this.tableFixedLeftBodyDom.style.top = '0px';
        // 固定右部
        this.tableFixedRightDom.className += ' fixTableRight';
        // this.tableFixedRightDom.style.right = '0px';
        this.tableFixedRightBodyDom.style.top = '0px';
      } else if ((this.isFixed || type == 'must') && scrollTop < 163) {
        // 解除头部
        this.fixedHeaderLeftDom.style.cssText = ``;
        this.fixedHeaderRightDom.style.cssText = ``;

        if(/mac os/.test(navigator.userAgent.toLowerCase())){
          // 解除左部
          this.tableFixedLeftBodyDom.style.top = '55px';
          // 解除右部
          this.tableFixedRightDom.className = 'el-table__fixed-right';
          this.tableFixedRightDom.style.right = '0px';
          this.tableFixedRightBodyDom.style.top = '55px';
        }else {
          // 解除左部
          this.tableFixedLeftBodyDom.style.top = '43px';
          // 解除右部
          this.tableFixedRightDom.className = 'el-table__fixed-right';
          this.tableFixedRightDom.style.right = '0px';
          this.tableFixedRightBodyDom.style.top = '43px';
        }
      }
    },

    /* PC Track 样式调整 END */
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {

    // const resizeObserver = new ResizeObserver(debounce(entries => {
    //   this.setTabStyle()
    // }, 200, true))
    const resizeObserver = new ResizeObserver(throttle(entries => {
      // this.tabChangeShow = false;
      this.setTabStyle();
      setTimeout(() => {
        this.showTabScroll()
      }, 500)
    }, 200, true))
    resizeObserver.observe(document.getElementById('contentTable'))
    this.$nextTick(() => {
      this.initPageDom();
    })
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.changeScroll, true)
  }
}
</script>
<style lang="scss" scoped>
.buttonrefresh {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 0 9px;
}

::v-deep .el-card__body {
  padding: 20px;
}

.pcTrackerClass {

  //overflow-y: scroll;
  //overflow-x: hidden;
  ::v-deep .contentTable {
    position: relative;

    .el-card__body {
      overflow-x: hidden;
      overflow-y: hidden;
      padding: 20px !important;
    }
  }
}

::v-deep .el-button {
  margin: 0 0 0 12px !important;
}

.flexLeft {
  display: flex;
  justify-content: flex-start;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}

.filterContainer {
  // background: red;
  margin-bottom: 15px;
}

.flexBetw {
  display: flex;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.AlCenter {
  align-items: center;
}

.iconImg {
  width: 16px;
  height: 16px;
}

.flex {
  display: flex;
}

.opeartionBox {
  padding: 0;
  box-sizing: border-box;
}

.opeartionBoxFixed {
  position: fixed;
}

.tableBodyBox {
  margin-top: 50px;
}

.buttonBox {
  margin-top: 16px;
}

.buttonBigunActive {
  width: 136px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}

.buttonActive {
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 20px;
}

.scopeItemBox {
  width: 150px;
  cursor: pointer;
}

.scopeItem {
  width: 130px;
  color: #828282;
  font-size: 14px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;

  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}

.active {
  color: #303133;
  text-align: center;
}

.unactive {
  color: #e9eaeb;
  text-align: center;
}

.scopeContainer {
  margin-top: 23px;
}

.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 150px;
  overflow: hidden;
}

.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .guidaoImg {
    overflow: hidden;
  }
}

.pcth-title-left {
  width: 175px;
  height: 29px;
  font-size: 21px;
  font-weight: 400;
  color: #868686;
  line-height: 29px;
}

:deep(.buttonClass) {
  height: 15px;
  border-radius: 2px;
  margin-right: 6px;

  span {
    font-size: 10px;
    display: block;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    position: relative;
    top: -5.5px;
  }
}

:deep(.buttonClass:nth-of-type(3)) {
  span {
    color: white;
  }
}

:deep(.buttonClass:nth-of-type(2)) {
  span {
    color: white;
  }
}

::v-deep input[type='number'] {
  text-align: right !important;
}

//表格切换
// .tab-change-btn {
//   width: 72px;
//   height: 72px;
//   border-radius: 50%;
//   background: rgba($color: #000000, $alpha: 0.07);
//   z-index: 1000;
// }

.tab-change-btn-left {
  position: fixed;
  z-index: 4;

}

.tab-change-btn-right {
  position: fixed;
  right: 14px;
  z-index: 4;
}

.tab-change-img {
  width: 36px;
  height: 72px;
}

.no-active-icon {
  color: #ccc !important;
}

.fixBox {
  position: fixed;
  top: 60px;
  background-color: #fff;
  z-index: 2000;
  padding: 20px;
  box-sizing: border-box;
}

:deep(.fixTable) {
  position: fixed !important;
  top: 140.1px !important;
  z-index: 2000 !important;
  width: 100%;
}

:deep(.fixTableRight) {
  right: 0px!important;
}
</style>
