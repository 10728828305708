export const nowEditionColumnConfig = [
  {
    key: 'hcStatus',
    name: 'HC Status',
    dataType: 'string',
    isYear: false,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'englishName',
    isYear: false,
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true, // 固定列的位置(left, right)
    align: 'left',
    width: 118 // 默认宽度，像素
  },
  {
    key: 'startDate',
    isYear: false,
    name: 'Start Date',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 93 // 默认宽度，像素
  },
  // {
  //   key: 'totalHc',
  //   name: 'HC Total',
  //   isYear: false,
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 100 // 默认宽度，像素
  // },
  {
    key: 'hcJan',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcFeb',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 2,
    name: 'HC Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcMar',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 3,
    name: 'HC Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcApr',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcMay',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcJun',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcJul',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcAug',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcSep',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcOct',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcNov',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'hcDec',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'HC Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110
  },
  {
    key: 'totalCostJan',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    isYear: true,
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'Total Cost Jan.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100
  },
  {
    key: 'totalCostFeb',
    childrenKey: 'totalCostFebPer',
    childrenForKey: 'totalCostFebForest',
    moreRow: true, //需要处理多行
    monthName: 2,
    isYear: true,
    name: 'Total Cost Feb.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostMar',
    childrenKey: 'totalCostMarPer',
    childrenForKey: 'totalCostMarForest',
    moreRow: true, //需要处理多行
    monthName: 3,
    isYear: true,
    name: 'Total Cost Mar.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostApr',
    childrenKey: 'totalCostAprPer',
    childrenForKey: 'totalCostAprForest',
    moreRow: true, //需要处理多行
    monthName: 4,
    isYear: true,
    name: 'Total Cost Apr.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostMay',
    childrenKey: 'totalCostMayPer',
    childrenForKey: 'totalCostMayForest',
    moreRow: true, //需要处理多行
    monthName: 5,
    isYear: true,
    name: 'Total Cost May.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    //  moreRow:true,//需要处理多行
  },

  {
    key: 'totalCostJun',
    childrenKey: 'totalCostJunPer',
    childrenForKey: 'totalCostJunForest',
    moreRow: true, //需要处理多行
    monthName: 6,
    isYear: true,
    name: 'Total Cost Jun.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostJul',
    childrenKey: 'totalCostJulPer',
    childrenForKey: 'totalCostJulForest',
    moreRow: true, //需要处理多行
    monthName: 7,
    isYear: true,
    name: 'Total Cost Jul.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostAug',
    childrenKey: 'totalCostAugPer',
    childrenForKey: 'totalCostAugForest',
    moreRow: true, //需要处理多行
    monthName: 8,
    isYear: true,
    name: 'Total Cost Aug.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostSep',
    childrenKey: 'totalCostSepPer',
    childrenForKey: 'totalCostSepForest',
    moreRow: true, //需要处理多行
    monthName: 9,
    isYear: true,
    name: 'Total Cost Sep.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostOct',
    childrenKey: 'totalCostOctPer',
    childrenForKey: 'totalCostOctForest',
    moreRow: true, //需要处理多行
    monthName: 10,
    isYear: true,
    name: 'Total Cost Oct.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostNov',
    childrenKey: 'totalCostNovPer',
    childrenForKey: 'totalCostNovForest',
    moreRow: true, //需要处理多行
    monthName: 11,
    isYear: true,
    name: 'Total Cost Nov.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostDec',
    childrenKey: 'totalCostDecPer',
    childrenForKey: 'totalCostDecForest',
    moreRow: true, //需要处理多行
    monthName: 12,
    isYear: true,
    name: 'Total Cost Dec.',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'salaryFullYear',
    name: 'Salary Full year',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  },

  {
    key: 'subTotalBasePayrollOp111',
    name: 'Sub-Total Base payroll',
    dataType: 'number',
    visible: true,
    sortable: false,

    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 130 // 默认宽度，像素
  },
  {
    key: 'subTotalThirteenPayrollOp111',
    name: 'Sub-Total 13th payroll',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'subTotalHolidayPayOp112',
    name: 'Sub-Total Holiday pay',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'subTotalSalesCommOp113',
    name: 'Sub-Total Sales Comm-OP113',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 170 // 默认宽度，像素
  },
  // {
  //   key: 'subTotalSocialChargesBase',
  //   name: 'Sub-Total Social Charges',
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 200 // 默认宽度，像素
  // },
  {
    key: 'socialChargesBase',
    name: 'Social Charges base',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'housingFundBase',
    name: 'Housing Fund base',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'subTotalSocialChargesOp121',
    name: 'Sub-Total Social ChargesOp121',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 180 // 默认宽度，像素
  },

  {
    key: 'subTotalMedicalInsuranceOp126',
    name: 'Sub-Total Medical Insurance',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 160 // 默认宽度，像素
  },
  {
    key: 'subTotalCommInsuranceIitOp126FullYear',
    name: 'Sub-Total CommInsuranceIITOp126',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  // {
  //   key: '',
  //   name: 'Sub-total Social Commerical',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   width: 230 // 默认宽度，像素
  // },
  {
    key: 'subTotalHomeLeaveOp132',
    name: 'Sub-Total HomeLeave',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'subTotalHousingSchoolFeeOthersOp132',
    name: 'Sub-Total Housing School Fee',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 170 // 默认宽度，像素
  },
  {
    key: 'subTotalRelocationOp132',
    name: 'Sub-Total Relocation',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110 // 默认宽度，像素
  },
  {
    key: 'subTotalFellingfeeOthersOp132',
    name: 'Sub-Total Filling Fee&Others',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 160 // 默认宽度，像素
  },
  {
    key: 'subTotalSeveranceOp141FullYear',
    name: 'Sub-Total Severance',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 110 // 默认宽度，像素
  },
  {
    key: 'subTotalBonusOp162Op164FullYear',
    name: 'Sub-Total Bonus',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  }
]
export const sumColumnConfig = [
  {
    key: 'title',
    name: ' ',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: 'left', // 固定列的位置(left, right)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'totalCostJan',
    monthName: 1,
    name: 'Total Cost Jan.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostFeb',
    monthName: 2,
    name: 'Total Cost Feb.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostMar',
    monthName: 3,
    name: 'Total Cost Mar.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostApr',
    monthName: 4,
    name: 'Total Cost Apr.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostMay',
    monthName: 5,
    name: 'Total Cost May.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },

  {
    key: 'totalCostJun',
    monthName: 6,
    name: 'Total Cost Jun.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostJul',
    monthName: 7,
    name: 'Total Cost Jul.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostAug',
    monthName: 8,
    name: 'Total Cost Aug.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostSep',
    monthName: 9,
    name: 'Total Cost Sep.',
    dataType: 'number',
    visible: true,
    showInput: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostOct',
    monthName: 10,
    name: 'Total Cost Oct.',
    dataType: 'number',
    showInput: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostNov',
    monthName: 11,
    name: 'Total Cost Nov.',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'totalCostDec',
    monthName: 12,
    name: 'Total Cost Dec-',
    showInput: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'salaryFullYear',
    name: 'Salary Full year',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  }
]

export const getNowEditionColumnConfig = () => {
  const res = []

  nowEditionColumnConfig.forEach((item) => {
    res.push(JSON.stringify(item))
  })
  return res
}
