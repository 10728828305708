<template>
  <div class="editOp151Modal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      :title="modalTitle"
      @close="close"
    >
      <div style="text-align: center; font-size: 20px;margin-top: 20px">
        <div class="selectBox flexRow AlCenter">
          <div class="flex-1">
            <el-form label-position="left" label-width="30px" >
              <div class="flex-1 flexRow">
                <div class="flex-1">
                  <el-form-item label="Jan" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入Jan"
                      v-model="selectEditMsg.janCost.janCost"
                      @input="$event?selectEditMsg.janCost.janCost = $event:selectEditMsg.janCost.janCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Jul" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Jan"
                      v-model="selectEditMsg.julCost.julCost"
                      @input="$event?selectEditMsg.julCost.julCost = $event:selectEditMsg.julCost.julCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="flex-1">
                  <el-form-item label="Feb" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入Feb"
                      v-model="selectEditMsg.febCost.febCost"
                      @input="$event?selectEditMsg.febCost.febCost = $event:selectEditMsg.febCost.febCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >       
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Aug" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Aug"
                      v-model="selectEditMsg.augCost.augCost"
                      @input="$event?selectEditMsg.augCost.augCost = $event:selectEditMsg.augCost.augCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="flex-1">
                  <el-form-item label="Mar" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入Mar"
                      v-model="selectEditMsg.marCost.marCost"
                      @input="$event?selectEditMsg.marCost.marCost = $event:selectEditMsg.marCost.marCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Sep" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Sep"
                      v-model="selectEditMsg.sepCost.sepCost"
                      @input="$event?selectEditMsg.sepCost.sepCost = $event:selectEditMsg.sepCost.sepCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1 flexRow flexBetw">
                <div class="flex-1">
                  <el-form-item label="Apr" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入Apr"
                      v-model="selectEditMsg.aprCost.aprCost"
                      @input="$event?selectEditMsg.aprCost.aprCost = $event:selectEditMsg.aprCost.aprCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Oct" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Oct"
                      v-model="selectEditMsg.octCost.octCost"
                      @input="$event?selectEditMsg.octCost.octCost = $event:selectEditMsg.octCost.octCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="flex-1">
                  <el-form-item label="May" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入May"
                      v-model="selectEditMsg.mayCost.mayCost"
                      @input="$event?selectEditMsg.mayCost.mayCost = $event:selectEditMsg.mayCost.mayCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Nov" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Nov"
                      v-model="selectEditMsg.novCost.novCost"
                      @input="$event?selectEditMsg.novCost.novCost = $event:selectEditMsg.novCost.novCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1 flexRow">
                <div class="flex-1">
                  <el-form-item label="Jun" class="w100 pr24" prop="BPName">
                    <el-input
                      placeholder="请输入Jun"
                      v-model="selectEditMsg.junCost.junCost"
                      @input="$event?selectEditMsg.junCost.junCost = $event:selectEditMsg.junCost.junCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
                <div class="flex-1">
                  <el-form-item label="Dec" class="w100 pl24" prop="BPName">
                    <el-input
                      placeholder="请输入Dec"
                      v-model="selectEditMsg.decCost.decCost"
                      @input="$event?selectEditMsg.decCost.decCost = $event:selectEditMsg.decCost.decCost = 0.00"
                      type="number"
                    >
                    </el-input
                    >
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <div style="text-align: right; margin-top: 16px">
        <el-button
          @click="close"
          >取 消</el-button
        >
        <el-button
          class="buttonActive"
          type="primary"
          @click="sure"
          >确 定</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const { Vuex } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},
  props: {
    RowId: {
      type: String
    }
  },

  data() {
    return {
      modalTitle: 'Total Cost-2023',
      dialogVisible: true,
      queryLoading: false,
      monthDisabled: true,
      selectEditMsg: {
        aprCost: '',
        augCost: '',
        decCost: '',
        febCost: '',
        janCost: '',
        julCost: '',
        junCost: '',
        marCost: '',
        mayCost: '',
        novCost: '',
        octCost: '',
        sepCost: ''
      },
      MonthArr: [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ],
      selectEditMsgArr: [],
      nowMonthNumber: '',
      refId: ''
    }
  },
  computed: {},

  created() {
    var date = new Date()
    this.nowMonthNumber = date.getMonth() + 1
    this.getCostDetail()
    this.modalTitle = `Total Cost-` + this.$route.query.indexYear
  },
  methods: {
    async getCostDetail() {
      const { data, code } = await this.$Api.salary.getCostDetail({
        id: this.RowId
      })
      if (code === 200) {
        let res = {}
        this.selectEditMsg = data
        this.refId = data.refId
        for (let i in this.selectEditMsg) {
          if (i != 'id' && i != 'refId') {
            let obj = {}
            obj[i] = this.selectEditMsg[i]
            var nowMonth = i.split('Cost')[0]
            var MontnIndex =
              this.MonthArr.map((item) => item).indexOf(nowMonth) + 1
            obj['MontnIndex'] = MontnIndex
            this.selectEditMsg[i] = obj
          }
        }
      } else {
        this.showMessage('')
      }
    },

    async sure() {
      var editObj = {}
      for (let i in this.selectEditMsg) {
        editObj[i] = Number(this.selectEditMsg[i][i])
      }

      const { data, code } = await this.$Api.salary.editFreeLancer({
        ...editObj,
        id: this.selectEditMsg.id,
        refId: this.refId
      })
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '调整成功'
        })
        this.$emit('closeModal')
        this.$emit('getIndexData')
      } else {
        this.$message({
          type: 'error',
          message: '调整失败'
        })
      }
    },
    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 10px;
  box-sizing: border-box;
}
.pl24 {
  padding-left: 10px;
  box-sizing: border-box;
}
.modalTitle {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #777;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.buttonunActive {
  width: 68px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.buttonActive {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
::v-deep .el-dialog .el-dialog__header{
  padding: 20px 20px 0 20px;
}
::v-deep .el-dialog .el-dialog__body{
  padding: 16px 20px 20px 20px;
}
::v-deep .el-form .el-form-item {
  margin-bottom: 16px !important;
  margin-right: 0 !important;
}
::v-deep .el-dialog .el-dialog__headerbtn {
  right: 20px;
  top: 25px;
}
::v-deep .el-button{
  margin: 0 0 0 20px !important;
}
</style>
<style lang="scss">
.editOp151Modal {
  .el-dialog__title {
    line-height: 24px;
    font-size: 21px;
    color: #303133;
  }
  .el-dialog .el-dialog__body {
    padding: 0 20px 20px 20px;
  }
  .el-button + .el-button {
    margin-left: 12px;
  }
}
</style>
