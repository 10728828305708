<template>
  <div class="rfRelatedTable">
    <PocTable
      v-loading="queryLoading"
      :data="tableAllData"
      size="mini"
      :max-height="999999"
      hidepage
      stripe
      class-name="columnClass"
      ref="multipleAllTable"
      row-key="id"
      :cell-style="tableCellStyle"
    >
      <!--   -->
      <!-- :row-style="funclass"  -->
      <el-table-column
        v-for="(item,index) in columnOptions"
        :key="index"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
        :align="item.align"
        header-align="center"
      >
        <template slot-scope="scope">
<!--          v-if="scope.row['accountCode'] !== 'OpsFixedPersonnelCosts-Oneoff' ||-->
<!--          scope.row['accountCode'] !== 'OpsFixedPersonnelCosts-Socialinsurancesubsidy' ||-->
<!--          scope.row.accountCode !== 'ICproductionplatformrecharge'-->
<!--          "-->
          <el-input v-if="['OpsFixedPersonnelCosts-Oneoff','OpsFixedPersonnelCosts-Socialinsurancesubsidy','ICproductionplatformrecharge'].includes(scope.row['accountCode'])
            && item.key!=='accountCode'" v-model="tableAllData[scope.$index][item.key]"
                    @input="$event?tableAllData[scope.$index][item.key]=$event:tableAllData[scope.$index][item.key]=0"
                    type="number"></el-input>
          <span :class="{'title-column':item.key==='accountCode','total-column':item.key==='total','format-number':item.dataType === 'number'}"
               v-else
          >{{ item.dataType == 'number' ? currency(scope.row[item.key]): changeTitle(scope.row[item.key])}}</span
          >
        </template>
      </el-table-column>
    </PocTable>
  </div>
</template>
<script>
import currency from "currency.js";
import { columnOptions, titleList } from "@/views/pcTrackerTrends/optionsData/rfRelatedOptions";
import { rfReleated } from "@/api/pctrakerRfReleated";
export default {
  name:'rfRelatedTable',
  data(){
    return{
      queryLoading:false,
      tableAllData:[],
      funclass:'',
      id:'',
      isRfVersion:'',
      year:'',
    }
  },
  computed:{
    columnOptions(){
      return columnOptions(this.year)
    }
  },
  created() {
    this.id = this.$route.query.id
    this.isRfVersion = Number(this.$route.query.buttonActive) === 1
    this.year = this.$route.query.indexYear
    this.queryApi()
  },
  methods: {
    currency,
    queryApi(){
      let params = {
        id:this.id,
        isRfVersion:this.isRfVersion,
        year:this.year,
      }
      this.queryLoading = true
      rfReleated(params).then(res=>{
        this.queryLoading = false
        if(res.code === 200){
          this.tableAllData = res.data
        }
      })
    },
    changeTitle(val){
     return titleList[val]
    },
    filterQuery() {
      this.queryApi()
    },
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      let accountCode = [
        'OpsFixedPersonnelCosts',
        'SocialInsurance',
        'OpsFreelance',
        'ICproductionplatformrecharge',
        'OpsSeveranceCosts',
      ];
      if (column.property === 'accountCode') {
        if(accountCode.includes(row.accountCode)){
          return { 'background': "rgba(186, 151, 101, 0.4)" };
        }
        return {}
      } else {
        return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rfRelatedTable{
  margin-top: 16px;
}
.title-column{
  color: #BA9765;
}
.total-column{
  color: #000000;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.AlCenter {
  align-items: center;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.iconImg180 {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}

.flex {
  display: flex;
}
.columnClass {
}
.editGreenData {
  color: #538a51;
}
.editRedData {
  color: red;
}
.editAfterData {
  color: #ba9765;
}
.opeartionBox {
  padding: 7px 0 16px 0px;
  box-sizing: border-box;
}
.scopeItemBox {
  width: 170px;
}
.scopeItem {
  width: 130px;
  // padding: 0 20px 0 20px;
  // margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;

  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}
.active {
  color: #303133;
  text-align: center;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 170px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.el-table__indent {
  padding-left: 0!important;
}
.format-number{
  margin-right: 31px;
}
</style>
