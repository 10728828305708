export const columnOptions = (year='2023') =>{
  let selfYear = year.slice(-2)
  return[
    {
      key: 'accountCode',
      name: '',
      dataType: 'string',
      visible: true,
      sortable: false,
      fixed: 'left', // 固定列的位置(left, right)
      align: 'left',
      width: 220 // 默认宽度，像素
    },
    {
      key: 'jan',
      name: `${selfYear}-Jan`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'feb',
      name: `${selfYear}-Feb`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'mar',
      name: `${selfYear}-Mar`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'apr',
      name: `${selfYear}-Apr`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'may',
      name: `${selfYear}-May`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'jun',
      name: `${selfYear}-Jun`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'jul',
      name: `${selfYear}-Jul`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'aug',
      name: `${selfYear}-Aug`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'sep',
      name: `${selfYear}-Sep`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'oct',
      name: `${selfYear}-Oct`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'nov',
      name: `${selfYear}-Nov`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'dec',
      name: `${selfYear}-Dec`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
  ]
}

export const titleList = {
  "OpsFixedPersonnelCosts":'Ops Fixed Personnel Costs',
  "OpsFixedPersonnelCosts-Existing":'- Existing',
  "OpsFixedPersonnelCosts-TBH(NewHire)":'- TBH (New Hire)',
  "OpsFixedPersonnelCosts-TBH(Replacement)":'- TBH（Replacement）',
  "OpsFixedPersonnelCosts-PCPlug":'- PC Plug',
  "OpsFixedPersonnelCosts-Salaryraisepool":'- Salary raise pool',
  "OpsFixedPersonnelCosts-HolidayPayprovision":'- Holiday Pay provision',
  "OpsFixedPersonnelCosts-Oneoff":'- One-off',
  "OpsFixedPersonnelCosts-Interco.staffrechargeinc./(exp.)":'- Interco.steff recharge inc./(exp.)',
  "OpsFixedPersonnelCosts-SalesCommission":'- Sales Commission',
  "OpsFixedPersonnelCosts-Socialinsurancesubsidy":'- Social insurance subsidy',
  "SocialInsurance":'Social Insurance',
  "SocialInsurance-Existing":'- Existing',
  "SocialInsurance-TBH(NewHire)":'- TBH(New Hire)',
  "SocialInsurance-TBH(Replacement)":'- TBH(Replacement)',
  "SocialInsurance-PCPlug":'- PC Plug',
  "OpsFreelance":'Ops Freelance',
  "ICproductionplatformrecharge":'IC Production platform recharge',
  "OpsSeveranceCosts":'Ops Severance Costs',
  "OpsFTE(HFM)":'Ops FTE(HFM)',
  "ExistingFTE":'Existing FTE',
  "TBH(Newhire)FTEYTD":'TBH(New Hire) FTE - YTD',
  "TBH(Replacement)FTEYTD":'TBH(Replacement) FTE - YTD',
  "TBH(Newhire)FTEMTD":'TBH(New Hire) FTE - MTD',
  "TBH(Replacement)FTEMTD":'TBH(Replacement) FTE - MTD',
  "OpsFTE(ManagementView)":'Ops FTE(Management View)',
  "ICHCAdjustment":'IC HC Adjustment',
  "OpsFreelanceHC":'Ops Freelance HC',
  "OpsInternHC":'Ops Intern HC',
}
