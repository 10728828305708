import request from '@/utils/request'


export const rfReleated = (data) => {
  return request({
    url: '/dynamicPctracker/rfReleated',
    method: 'Get',
    params: {
      ...data
    }
  })
}
