import { getItem } from '@/utils/storage'
import {
  ops131CostTableDataConfig,
  ops131HcTableDataConfig
} from '@/views/salaryManagement/editionData/ops131tableData'

export const nowEditionColumnConfig = [
  {
    key: 'nameEn',
    name: 'Name EN',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: 'left', // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'nameCn',
    name: 'Name CN',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'jobTitle',
    name: 'Job Title',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'assigmentProjectLevel2',
    name: 'Level 2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'legalEntity',
    name: 'Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'workLocation',
    name: 'Work Location',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'department',
    name: 'Department',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'ceoName',
    name: 'CEO',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'contractStartDate',
    name: 'ContractStartDate',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'contractEndDate',
    name: 'ContractEndDate',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  // {
  //   key: 'salaryFullYear',
  //   isYear: true,
  //   isTotal: true,
  //   name: '2023-Total',
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 150 // 默认宽度，像素
  // },
  {
    key: 'janCost',
    isYear: true,
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'febCost',
    isYear: true,
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'marCost',
    isYear: true,
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'aprCost',
    isYear: true,
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'mayCost',
    isYear: true,
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'junCost',
    isYear: true,
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'julCost',
    isYear: true,
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'augCost',
    isYear: true,
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'sepCost',
    isYear: true,
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'octCost',
    isYear: true,
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'novCost',
    isYear: true,
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'decCost',
    isYear: true,
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  // {
  //   key: 'salaryFullYear',
  //   name: 'Full Year',
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 150 // 默认宽度，像素
  // }
]
export const nowEditionColumnConfigHc = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 0 // 默认宽度，像素
  },
  {
    key: 'assigmentProjectLevel2',
    name: 'assigmentProjectLevel2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'department',
    name: 'Department',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'position',
    name: 'Position',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'nameEn',
    name: 'Name (English)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 140 // 默认宽度，像素
  },
  {
    key: 'nameCn',
    name: 'Name (Chinese)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 140 // 默认宽度，像素
  },
  {
    key: 'contractStartDate',
    name: 'ContractStartDate',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'contractEndDate',
    name: 'ContractEndDate',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'janHcCost',
    isYear: true,
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'febHcCost',
    isYear: true,
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'marHcCost',
    isYear: true,
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'aprHcCost',
    isYear: true,
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'mayHcCost',
    isYear: true,
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'junHcCost',
    isYear: true,
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'julHcCost',
    isYear: true,
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'augHcCost',
    isYear: true,
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'sepHcCost',
    isYear: true,
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'octHcCost',
    isYear: true,
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'novHcCost',
    isYear: true,
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'decHcCost',
    isYear: true,
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'hcFullYear',
    name: 'Full Year',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
  }
]
export const changeOp151Year = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    nowEditionColumnConfig.forEach((item) => {
      if (item.isYear) {
        if(item.isTotal){
          let name = item.name.split('-')[0]
          item.name = year + ' Total'
        }else {
          let name = item.name.split('-')[0]
          item.name = name + '-' + year
        }
      }
    })
    nowEditionColumnConfigHc.forEach((item) => {
      if (item.isYear) {
        let name = item.name.split('-')[0]
        item.name = name + '-' + year
      }
    })
  }
}
