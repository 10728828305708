<template>
  <div class="selectIcChargeTable">
    <el-form
      :model="formData"
      inline
      label-position="right"
      label-width="80px"
      size="small"
      ref="selectIcChargeTableForm"
    >
      <el-form-item label="HFM Code" prop="versionName">
        <el-select
          v-model="formData.hfmCode"
          clearable
          filterable
          placeholder="Version Name"
        >
          <el-option
            v-for="item in hfmCodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 2" prop="periodRange">
        <el-select
          v-model="formData.level2"
          clearable
          filterable
          @change="changeLevel2"
          placeholder="Version Name"
        >
          <el-option
            v-for="item in level2List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Level 3" prop="description">
        <el-select
          v-model="formData.level3"
          clearable
          filterable
          placeholder="Version Name"
        >
          <el-option
            v-for="item in level3List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="queryApi">查询</el-button>
    </el-form>
    <div style="display: flex; justify-content: space-between">
      <el-checkbox
        v-model="checked"
        v-if="tableData.length"
        style="margin-left: 10px; margin-bottom: 6px"
        @change="checkBoxChange"
        >全选</el-checkbox
      >
      <div style="font-weight: bold; font-size: 16px">
        共选择{{ maxNum }}条数据
      </div>
    </div>

    <vxe-table
      show-header-overflow
      ref="xTable4"
      border="none"
      @checkbox-all="selectAllEvent"
      @checkbox-change="selectChangeEvent"
      stripe
      :max-height="tableHeight"
      :loading="loading"
      show-footer
      :footer-method="footerMethod1"
      row-id="id"
      size="mini"
      :checkbox-config="checkboxConfig"
      :data="tableData"
    >
      <vxe-column
        v-if="!checked"
        width="55"
        type="checkbox"
        fixed="left"
      ></vxe-column>
      <vxe-column
        v-for="item in columnList"
        :field="item.key"
        :title="item.name"
        :width="item.width"
        :fixed="item.fixed ? 'left' : null"
      >
        <template v-if="item.fold" #header>
          <span>{{ item.name }}</span>
          <!--          <i-->
          <!--          :visible="item.visible"-->
          <!--            style="cursor: pointer"-->
          <!--            :class="-->
          <!--              item.collapsable-->
          <!--                ? 'vxe-icon-square-minus'-->
          <!--                : 'vxe-icon-square-plus'-->
          <!--            "-->
          <!--            @click="item.collapsableEvent(item, $refs.xTable4)"-->
          <!--          ></i>-->
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      :loading="loading"
      size="mini"
      :current-page="tablePage1.pageNum"
      :page-size="tablePage1.pageSize"
      :total="tablePage1.total"
      :layouts="[
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Sizes',
        'Total'
      ]"
      @page-change="handlePageChange1"
    >
    </vxe-pager>
  </div>
</template>
<script>
import { ops131CostTableDataConfig } from '@/views/salaryManagement/editionData/ops131tableData'
import { getLevel3 } from '@/api/personnelRequisition'
import { getOp131SelectList, op131TotalCost } from '@/api/pctrakerop131'
import { mapState } from 'vuex'

export default {
  name: 'selectIcChargeTable',
  data() {
    return {
      formData: {
        hfmCode: '',
        level2: '',
        level3: ''
      },
      checked: false,
      hfmCodeList: [],
      level2List: [],
      level3List: [],
      tableData: [],
      checkboxConfig: {
        reserve: true,
        checkMethod: this.checkMethod
      },
      loading: false,
      tablePage1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      selectVal: [],
      MonthArr: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      tableHeight: 500,
      maxNum: 0,
      totalCost: [
        ['', 'Total Intercompany Recharge (Cost)', '', '', '', '', '']
      ],
      buttonActive: '',
      columnList: ops131CostTableDataConfig
    }
  },
  computed: {
    ...mapState(['chargeOp131'])
  },
  created() {
    this.getFormLine()
    this.getTotalLength()
    this.queryApi()
  },
  mounted() {},
  methods: {
    footerMethod1() {
      return this.totalCost
    },
    getTotalLength() {
      const data1 = {
        id: this.$route.query.id || '',
        period: this.chargeOp131.chargeOp131FormData.period
      }
      op131TotalCost(data1).then((res) => {
        if (res && res.code === 200) {
          this.totalCost[0].push(res.data[`totalCost`])
          this.MonthArr.forEach((monthArr) => {
            this.totalCost[0].push(res.data[`totalCost${monthArr}`])
          })
        }
      })
    },
    submit() {},
    checkMethod(row) {
      return !row.row.hcDisable
    },
    selectAllEvent(val) {
      this.selectVal = val
      this.maxNum = this.getCheckBoxData().length
    },
    selectChangeEvent(val) {
      this.selectVal = val
      this.maxNum = this.getCheckBoxData().length
    },
    async getOp111Table() {
      this.loading = true
      const data = {
        ...this.formData,
        period: this.chargeOp131.chargeOp131FormData.period,
        ...this.tablePage1
      }
      getOp131SelectList(data)
        .then((res) => {
          this.loading = false
          if (res && res.code === 200) {
            this.tableData = res.data.list || []
            this.tablePage1.total = res.data.total
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    async getEntityHfmCode() {
      const { code, data } = await this.$Api.induction.getEntityHfmCode({})
      if (code === 200) {
        this.hfmCodeList = data
      }
    },
    async getAssigmentProjectLevel2() {
      const { code, data } =
        await this.$Api.induction.getAssigmentProjectLevel2({})
      if (code === 200) {
        this.level2List = data
      }
    },
    getFormLine() {
      this.getAssigmentProjectLevel2()
      this.getEntityHfmCode()
      getLevel3().then((res) => {
        if (res && res.code === 200) {
          this.level3List = res.data
        }
      })
    },
    changeLevel2(val) {
      const data = {
        level2Id: val
      }
      getLevel3(data).then((res) => {
        if (res && res.code === 200) {
          this.level3List = res.data
        }
      })
    },
    queryApi() {
      this.getOp111Table()
    },
    getCheckBoxData() {
      return [
        ...this.$refs.xTable4.getCheckboxReserveRecords(),
        ...this.selectVal.records
      ]
    },
    checkBoxChange(val) {
      if (val) {
        this.maxNum = this.tablePage1.total
      } else {
        this.maxNum = 0
      }
      // this.$refs.xTable4.setAllCheckboxRow(val)
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.tablePage1.pageNum = currentPage
      this.tablePage1.pageSize = pageSize
      this.queryApi()
    }
  }
}
</script>
<style lang="scss" scoped>
.selectIcChargeTable {
}
</style>
