export const summaryColumnConfig = (year)=>{
  let selfYear = year.slice(-2)
  return   [
    {
      key: 'title',
      name: '',
      dataType: 'string',
      visible: true,
      sortable: false,
      fixed: 'left', // 固定列的位置(left, right)
      align: 'left',
      width: 300 // 默认宽度，像素
    },
    {
      key: 'totalCost',
      name: 'Total',
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: 'left', // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'jan',
      monthName: 1,
      name: `Total Cost Jan-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'feb',
      monthName: 2,
      name: `Total Cost feb-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'mar',
      monthName: 3,
      name: `Total Cost mar-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'apr',
      monthName: 4,
      name: `Total Cost apr-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'may',
      monthName: 5,
      name: `Total Cost may-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },

    {
      key: 'jun',
      monthName: 6,
      name: `Total Cost jun-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'jul',
      monthName: 7,
      name: `Total Cost jul-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'aug',
      monthName: 8,
      name: `Total Cost aug-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'sep',
      monthName: 9,
      name: `Total Cost sep-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'oct',
      monthName: 10,
      name: `Total Cost oct-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'nov',
      monthName: 12,
      name: `Total Cost nov-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    },
    {
      key: 'dec',
      monthName: 11,
      name: `Total Cost dec-${selfYear}`,
      dataType: 'number',
      visible: true,
      sortable: false,
      fixed: false, // 固定列的位置(left, right)
      align: 'right',
      width: 150 // 默认宽度，像素
    }
  ]
}


export const tableTitle = {
  op10RevenceAndPc: 'Revenue',
  op10TotalCostMonth: 'OP10T Personnel Costs',
  op111: 'OP111 Salaries - Payroll',
  op112: 'OP112 Provision for holiday pay',
  op113: 'OP113 Sales Commission',
  op121: 'OP121 Social charges on salaries',
  op126: 'OP126 Health Insurance',
  op131: 'OP131 Interco. staff recharge inc. / (exp.)',
  op132: 'OP132 Other employee related costs',
  op141: 'OP141 Redundancy indemnities',
  op151: 'OP151 LT Temporaries and Freelancers (>90 days)',
  op152: 'OP152 ST Freelancers and Temporaries (<90 days)',
  op161: 'OP161 French cos only:participation salari+Üs',
  op162: 'OP162 Contractual bonus',
  op163: 'OP163 Bonus pool',
  op164: 'OP164 Share based incentives',
  op165: 'OP165 Discretionary bonus',
  op166: 'OP166 Spot Bonus',
  op168: 'OP168 Earn Out Compensation',
  op171: 'OP171 Social Charges on shared based incentives',
  op172: 'OP172 Social Charges on bonus',
  revenceAndPc: 'Revenue',
  totalCostMonth: 'Fixed Personnel Costs + Freelance',
  pc: 'PC%',
  op10PC: 'PC%'
}
