import request from '@/utils/request'


export const getYear = (params) => {
  return request.get('/dynamicPctracker/getYear',{params:params})
}

export const list = (params) => {
  return request.get('/dynamicPctracker/list',{params:params})
}
export const ceoList = (params) => {
  return request.get('/dynamicPctracker/ceo/list',{params:params})
}
export const detail = (params) => {
  return request.get('/dynamicPctracker/detail',{params:params})
}
export const getIsFinance = (params) => {
  return request.get('/dynamicPctracker/getIsFinance', {})
}
export const refresh = (data) => {
  return request({
    url: '/dynamicPctracker/refresh',
    method: 'POST',
    data
  })
}
export const refreshCEO = (data) => {
  return request({
    url: '/dynamicPctracker/ceo/refresh',
    method: 'POST',
    data
  })
}
export const fileBackList = (params) => {
  return request.get('/dynamicPctracker/fileBack/list',{params:params})
}
export const backup = (data) => {
  return request({
    url: '/dynamicPctracker/fileBack/backup',
    method: 'POST',
    data,
    timeout: 24 * 60 * 60 * 1000
  })
}
export const backupCEO = (data) => {
  return request({
    url: '/dynamicPctracker/ceo/backup',
    method: 'POST',
    data,
    timeout: 24 * 60 * 60 * 1000
  })
}
