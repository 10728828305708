<template>
  <div class="SelectionVersion">
    <el-form
      :model="formData"
      :label-position="'right'"
      label-width="100px"
      size="small"
      :rules="rules"
      ref="SelectionVersionForm"
    >
      <el-form-item label="Period" prop="period">
        <el-date-picker
          v-model="formData.period"
          type="month"
          align="right"
          placeholder="Period"
          value-format="yyyy-MM"
          clearable
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: ['type'],
  data() {
    return {
      formData: {
        period: ''
      },
      rules: {}
    }
  },
  computed: {
    ...mapState(['chargeOp131'])
  },
  created() {
    this.formData.period = this.chargeOp131.chargeOp131FormData.period || ''
    Object.keys(this.formData).forEach((item) => {
      this.rules[item] = [
        {
          required: true
        }
      ]
    })
  },
  methods: {
    checkFrom() {
      return new Promise((resolve, reject) => {
        this.$refs.SelectionVersionForm.validate((valid) => {
          if (valid) {
            resolve(true)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SelectionVersion {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-250px, -250px);
  .el-form {
    width: 500px;
    .el-form-item {
      width: 100%;
      margin-bottom: 30px !important;
      .el-input,
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
