<template>
  <div class="ops1512">
    <!--    <div v-if="buttonActive !== 3"  style="padding: 16px">-->
    <!--          <el-button-->
    <!--            class="buttonBigunActive"-->
    <!--            :loading="queryLoading"-->
    <!--            role="add freeLancer"-->
    <!--            v-permission-->
    <!--            @click="addFreelancer"-->

    <!--            >Add Freelancer</el-button-->
    <!--          >-->
    <!--    </div>-->
    <PocTable
      v-loading="queryLoading"
      :data="tableData"
      size="mini"
      stripe
      show-summary
      border
      sum-text="Total intercompany recharge (Cost)"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      :max-height="100000000000000000000000"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @selection-change="handleSelectionChange"
      @header-click="headerExpend"
      :summary-method="getSummaries"
      ref="multipleTable"
      row-key="id"
    >
      <!--         :sortable="item.sortable"-->
      <el-table-column fixed="left" type="selection" width="55" />
      <el-table-column
        v-for="item in columnOptions"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.width"
        :align="item.align"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{
            item.dataType == 'number'
              ? currency(scope.row[item.key])
              : scope.row[item.key]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="buttonActive != 3"
        label="Operation"
        width="200"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <span
            class="editSpan pointer"
            role="adjust"
            v-if="adjustDis()"
            style="margin-right: 16px; cursor: pointer"
            @click.stop="editdRow(scope.row)"
            >调整</span
          >
          <el-popconfirm
            style="margin-right: 16px; cursor: pointer"
            title="确定删除吗？"
            role="delete"
            v-permission
            @confirm="deleteOp151(scope.row)"
          >
            <span class="editSpan" style="cursor: pointer" slot="reference"
              >删除</span
            >
          </el-popconfirm>
          <span
            class="editSpan pointer"
            v-if="adjustDis()"
            style="margin-right: 16px; cursor: pointer"
            @click.stop="editHistory(scope.row)"
            >调整历史
          </span>
          <!--          <span class="editSpan pointer" @click.stop="deleteOp151(scope.row)"-->
          <!--            >删除-->
          <!--          </span>-->
          <!-- <el-tooltip content="调整" placement="top">
                <img
                  class="iconImg pointer"
                  src="@/assets/img/edit.png"
                  @click.stop="editdRow(scope.row)"
                  alt=""
                />
              </el-tooltip> -->
          <!--            <el-col :span="12">-->
          <!--              <span class="editSpan pointer" role="adjustHistory"-->
          <!--              v-permission @click.stop="editHistory(scope.row)">调整历史 </span>-->
          <!-- <el-tooltip content="调整历史" placement="top">
                <img
                  class="iconImg pointer"
                  src="@/assets/img/edit.png"
                  @click.stop="editHistory(scope.row)"
                  alt=""
                />
              </el-tooltip> -->
          <!--            </el-col>-->
        </template>
      </el-table-column>
    </PocTable>
    <editOp151Modal
      v-if="editOp151Flag"
      :RowId="rowId"
      @closeModal="closeModal"
      @getIndexData="getIndexData"
    ></editOp151Modal>
    <add-freelancer-modal
      v-if="freelancerFlag"
      @closeModal="closeModal"
      @getIndexData="getIndexData"
      :is-summary="isSummary"
      v-bind:version="versionId"
      v-bind:empType="empType"
    />
  </div>
</template>

<script>
import currency from 'currency.js'
import {
  changeOp151Year,
  nowEditionColumnConfig,
  nowEditionColumnConfigHc
} from '../../editionData/ops1512tableData'

import editOp151Modal from '../../components/modal/editOp151Modal.vue'
import addFreelancerModal from '../../components/modal/addFreeLancherModal.vue'
import {
  deleteOp131Op151Op152ById,
  eleteOp131Op151Op152ById
} from '@/api/pctrakerop131'
import { getItem, setItem } from '@/utils/storage'
import { titleCase } from '@/utils/util'

const { Vuex } = $PCommon
const { mapGetters } = Vuex

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    editOp151Modal,
    addFreelancerModal
  },

  props: {
    editionId: {
      type: Number
    },
    buttonActive: {
      type: Number
    },
    changeActive: {
      type: Number
    },
    chooseTab: {
      type: Number
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 440 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      tableData: [],
      columnConfig: nowEditionColumnConfig,
      editOp151Flag: false,
      jumpParams: {},
      editHistoryArr: [
        '待提交',
        '待汇总',
        '已汇总',
        'BP leader驳回',
        'Finance审批不通过'
      ],
      selectedItems: [],
      versionId: '',
      empType: '',
      freelancerFlag: '',
      rowId: '',
      id: '',
      summaries: {
        totalCost: {},
        hcTotalCost: {}
      },
      submitStatus: '',
      filterConfig: {},
      isSummary: false
    }
  },
  computed: {
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    }
  },

  created() {
    changeOp151Year()
    this.id = this.$route.query.id
    this.versionId = this.$route.query.id
    if (this.chooseTab == 3) {
      this.empType = 0
    }

    if (this.chooseTab == 4) {
      this.empType = 1
    }
    if (this.buttonActive == 2 && this.changeActive == 2) {
      this.isSummary = true
    } else {
      this.isSummary = false
    }
    this.submitStatus = this.$route.query.submitStatus
    // this.filterQuery();
  },
  methods: {
    currency(value) {
      return currency(value, { symbol: '' }).format()
    },
    adjustDis() {
      // if (this.buttonActive == '1') {
      //   return ['待提交'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '1') {
      //   return ['待提交', '待汇总'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '2') {
      //   return ['已汇总', 'BPLeader驳回', 'Finance审批不通过'].includes(
      //     this.submitStatus
      //   )
      // } else {
      //   return false
      // }
      return true
    },
    deleteOps(val) {
      const data = {
        id: val.id,
        type: 'op151op152',
        tabOneType: this.buttonActive - 1
      }
      eleteOp131Op151Op152ById(data).then((res) => {
        if (res && res.code === 200) {
          this.filterQuery()
        }
      })
    },
    getRowClass() {
      return 'background:#F1EAE0'
    },
    addFreelancer() {
      this.freelancerFlag = true
    },
    closeModal() {
      this.freelancerFlag = false
      this.editOp151Flag = false
    },
    expendCounfig(row) {},
    async filterQuery(filterConfig) {
      this.filterConfig = filterConfig ? filterConfig : this.filterConfig
      this.pageConfig.pageNum = 1
      if (this.buttonActive != '3') {
        await this.getOp1512Totalcost()
      }
      await this.queryApi(this.filterConfig)
    },
    getIndexData() {
      this.filterQuery()
    },

    // 获取基础数据维护的列表
    async queryApi(filterConfig) {
      filterConfig = filterConfig || this.filterConfig

      this.queryLoading = true
      // if (this.buttonActive == 3) {
      //   //历史版本进来 另一个接口
      //
      //   this.getHistoryOp1512Table(filterConfig)
      // } else if (this.buttonActive == 2 && this.changeActive == 2) {
      //   //汇总里的汇总
      //   this.getsumInsumOp1512Table(filterConfig)
      // } else {
      //   this.getOp1512Table(filterConfig)
      // }
      await this.getOp1512Table(filterConfig)

    },
    async getOp1512Table(filterConfig) {
      const { data, code } = await this.$Api.salary.getOp1512Table({
        ...filterConfig,
        ...this.pageConfig,
        ...{
          versionId: this.versionId,
          type: this.empType
        }
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getHistoryOp1512Table(filterConfig) {
      const { data, code } = await this.$Api.salary.getHistoryOp1512Table({
        ...filterConfig,
        ...this.pageConfig,
        ...{
          versionId: this.versionId,
          type: this.empType
        }
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        this.point()
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    async getsumInsumOp1512Table(filterConfig) {
      const { data, code } = await this.$Api.salary.getsumInsumOp1512Table({
        ...filterConfig,
        ...this.pageConfig,
        ...{
          versionId: this.versionId,
          type: this.empType
        }
      })
      if (code === 200) {
        this.tableData = data.list || []
        this.pageConfig.total = data.total
        this.point()
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    getSummaries(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      let self = this
      if (this.selectedItems.length > 0) {
        columns.forEach((column, index) => {
          if (index === 1) {
            // sums[index] = 'Total Intercompany Recharge(Cost) '
            // sums[index] = 'Total Intercompany Recharge (Cost)'
            sums[index] = (() => {
              let el = (
                <div class="count_row">
                  Total Cost
                  <br />
                  Total HC
                </div>
              )
              return el
            })()
            //如果是最后一列，索引为列数-1，则显示计算总和
          } else if (12 < index && index < len - 1) {
            let CostNumber = 0
            let HcNumber = 0
            const values = this.selectedItems.map((item) => {
              if (column.property === 'salaryFullYear') {
                return Number(item['salaryFullYear'])
              } else {
                return Number(item[column.property])
              }
            })
            if (!values.every((value) => isNaN(value))) {
              CostNumber = self.currency(
                values.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              CostNumber = 'N/A'
            }
            const hcTotal = this.selectedItems.map((item) => {
              if (column.property === 'salaryFullYear') {
                return Number(item[`hcFullYear`])
              } else {
                return Number(item[`${column.property.substring(0, 3)}HcCost`])
              }
            })
            if (!hcTotal.every((value) => isNaN(value))) {
              HcNumber = self.currency(
                hcTotal.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              HcNumber = 'N/A'
            }
            sums[index] = (
              <div class="count_row">
                {CostNumber}
                <br />
                {HcNumber}
              </div>
            )
          }
        })
      } else {
        columns.forEach((column, index) => {
          //如果是第一列，则最后一行展示为“总计”两个字
          if (index === 1) {
            // sums[index] = 'Total Intercompany Recharge(Cost)'
            // sums[index] = 'Total Intercompany Recharge (Cost)'
            sums[index] = (() => {
              let el = (
                <div class="count_row">
                  Total Cost
                  <br />
                  Total HC
                </div>
              )
              return el
            })()
            //如果是最后一列，索引为列数-1，则显示计算总和
          } else if (13 < index && index < len - 1) {
            let str = 'totalCost' + titleCase(column.property.substring(0, 3))
            let cost = this.currency(
              parseFloat(this.summaries.totalCost[str]) || 0
            )
            let hcCost = parseFloat(this.summaries.hcTotalCost[str]) || 0
            sums[index] = (
              <div class="count_row">
                {cost}
                <br />
                {hcCost}
              </div>
            )
          } else if (index === 13) {
            let salaryFullYear = this.currency(
              parseFloat(this.summaries.totalCost['salaryFullYear']) || 0
            )
            let hcFullYear = this.currency(
              parseFloat(this.summaries.hcTotalCost['salaryFullYear']) || 0
            )
            sums[index] = (
              <div class="count_row">
                {salaryFullYear}
                <br />
                {hcFullYear}
              </div>
            )
          } else {
            sums[index] = ''
          }
        })
      }
      return sums
    },
    handleSelectionChange(val) {
      this.selectedItems = val
      // 选的第一个
      // if (val.length) {
      //   this.selectedItems = val
      //   this.selecthcIdIds = this.selectedItems.map((v) => v.hcId).join()
      //   this.selectofferProposalIds = this.selectedItems
      //     .map((v) => v.offerProposalId)
      //     .join()
      // } else {
      //   this.selecthcIdIds = ''
      //   this.selectofferProposalIds = ''
      // }
    },
    headerExpend(row) {
      console.log(row.label)
      if (row.label == 'English Name') {
        let arr = [
          {
            key: 'jobTitle',
            name: 'HC Nature222',
            dataType: 'string',
            visible: true,
            sortable: false,
            fixed: false, // 固定列的位置(left, right)
            width: 100 // 默认宽度，像素
          },
          {
            key: 'jobTitle',
            name: 'HC Nature333',
            dataType: 'string',
            visible: true,
            sortable: false,
            fixed: false, // 固定列的位置(left, right)
            width: 100 // 默认宽度，像素
          }
        ]
        this.columnConfig.splice(2, 0, ...arr)
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = {
        jumpFlag: 'goDetail',
        hcId: row.hcId,
        offerProposalId: row.offerProposalId,
        offerStatusNumber: row.offerStatusNumber
      }
    },
    // 编辑信息
    editdRow(row) {
      this.editOp151Flag = true
      this.rowId = row.id
    },
    point() {
      let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
      if (pcTrackerOp111Row__yc) {
        let row = this.tableData.find(
          (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
        )
        if (row) {
          this.$refs.multipleTable.setCurrentRow(row, true)
        }
      }
    },
    deleteOp151(row) {
      const data = {
        tabOneType: this.buttonActive - 1,
        type: 'op151op152',
        id: row.id
      }
      deleteOp131Op151Op152ById(data).then((res) => {
        if (res && res.code === 200) {
          this.$message('删除成功')
          this.queryApi()
        }
      })
    },
    editHistory(row) {
      setItem('pcTrackerOp111Row__yc', row.id)
      let tabOneType = ''
      if (this.$route.query.buttonActive == '2') {
        if (this.$route.query.changeActive == '2') {
          tabOneType = '1'
        } else {
          tabOneType = '0'
        }
      }
      const tabTwoType = 'op151'
      const id = row.id
      if (id) {
        this.$router.push({
          path: '/pcTracker/editHistory',
          query: {
            tabOneType,
            tabTwoType,
            id,
            target: 'op151',
            versionId: this.$route.query.id
          }
        })
      } else {
        this.$message.error('缺少id')
      }
    },
    // getSummaries(param) {
    //   const { columns, data } = param
    //   //接口返给我类似数据
    //   const sums = ['Total intercompany recharge (Cost)', 378, '', '', 8]
    //   return sums
    // }
    async getOp1512Totalcost() {
      const data1 = {
        id: this.id,
        tabOneType: this.buttonActive - 1
      }
      if (this.buttonActive == '2') {
        if (this.changeActive == '1') {
          data1.tabOneType = 0
        } else if (this.changeActive == '2') {
          data1.tabOneType = 1
        }
      }
      const { data, code } = await this.$Api.salary.getOp1512Totalcost(data1)
      if (code === 200) {
        if (data.op151TotalCost) {
          this.summaries.totalCost = data.op151TotalCost
        }
        if (data.op151HcTotalCost) {
          this.summaries.hcTotalCost = data.op151HcTotalCost
        }
      }
    }
  },
  beforeDestroy() {}
}
</script>
<style lang="scss" scoped>
.ops1512 {
  margin-top: 16px;
}
:deep(.displayTable) {
  .el-table__header {
    display: none;
  }
}
.buttonBigunActive {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 20px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.AlCenter {
  align-items: center;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.flex {
  display: flex;
}
.opeartionBox {
  padding: 7px 0 16px 0px;
  box-sizing: border-box;
}
.scopeItemBox {
  width: 170px;
}
.scopeItem {
  width: 130px;
  // padding: 0 20px 0 20px;
  // margin-right: 72px;
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;

  overflow: hidden; //溢出隐藏
  text-overflow: ellipsis; //显示省略符号来代表被修剪的文本。
  white-space: nowrap; //文字不换行
}
.active {
  color: #303133;
  text-align: center;
}
.unactive {
  color: #e9eaeb;
  text-align: center;
}
.scopeContainer {
  margin-top: 23px;
}
.guidaoactive {
  padding-top: 8px;
  box-sizing: border-box;
  width: 170px;
  overflow: hidden;
}
.guidao {
  font-size: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .guidaoImg {
    overflow: hidden;
  }
}
.editSpan {
  font-size: 12px;
  color: #ba9765;
}
.pointer {
  cursor: pointer;
}
</style>
<style lang="scss"></style>
