<template>
  <div class="ops131">
    <PocTable
      ref="multipleTable"
      class="positionTable"
      v-loading="queryLoading"
      :data="tableData6"
      show-summary
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      stripe
      :max-height="100000000000000000000000"
      size="mini"
      @size-change="pageSizeChange"
      @selection-change="handleSelectionChange"
      @page-current-change="pageChange"
      highlight-current-row
      :header-cell-style="getRowClass"
      :summary-method="getSummaries"
    >
      <el-table-column fixed="left" type="selection" width="55" />
      <el-table-column
        v-for="item in costColumnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        :align="item.align"
        :show-overflow-tooltip="item.key!=='description'"
      >
        <!--                :sortable="item.sortable"-->
        <template slot-scope="scope">
          <span v-if="item.key === 'description'">
            <el-tooltip
              v-if="scope.row[item.key]"
              popper-class="workorder-reason-popper"
              effect="dark"
              :content="scope.row[item.key]"
              placement="top">
                 <span>{{ scope.row[item.key] | ellipsis}}</span>
                </el-tooltip>
            <span v-else>{{scope.row[item.key]}}</span>
          </span>
          <span v-else>{{
            item.dataType == 'number'
              ? currency(scope.row[item.key])
              : scope.row[item.key]
          }}</span>

        </template>
      </el-table-column>

      <el-table-column
        label="Operations"
        width="150"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            <span>
              <span
                class="editSpan"
                v-if="adjustDis()"
                style="margin-right: 10px; cursor: pointer"
                @click="edit(scope.row)"
                >调整</span
              >
<!--              <span-->
<!--                class="editSpan"-->
<!--                style="margin-right: 10px; cursor: pointer"-->
<!--                @click="editSubmit(scope.row)"-->
<!--                role="op131Submit"-->
<!--                v-permission-->
<!--                >提交</span-->
<!--              >-->
              <el-popconfirm
                title="确定删除吗？"
                style="margin-right: 10px; cursor: pointer"
                role="delete"
                v-permission
                @confirm="deleteOps(scope.row)"
              >
                <span class="editSpan" style="cursor: pointer" slot="reference"
                  >删除</span
                >
              </el-popconfirm>
            </span>

            <span
              class="editSpan pointer"
              v-if="adjustDis()"
              @click.stop="editHistory(scope.row)"
              >调整历史
            </span>
          </div>
<!--          <div-->
<!--            v-else-if="scope.row.isToBp"-->
<!--            style="display: flex; margin-left: 17px"-->
<!--          >-->
<!--            <span-->
<!--              type="text"-->
<!--              style="margin-left: 13px; cursor: pointer"-->
<!--              class="editSpan"-->
<!--              @click="chat(scope.row)"-->
<!--              >查看</span-->
<!--            >-->
<!--            <span-->
<!--              v-permission-->
<!--              role="op131Accept"-->
<!--              type="text"-->
<!--              style="margin-left: 13px; cursor: pointer"-->
<!--              class="editSpan"-->
<!--              @click="accept(scope.row)"-->
<!--              >接受</span-->
<!--            >-->
<!--            <span-->
<!--              v-permission-->
<!--              role="op131Reject"-->
<!--              type="text"-->
<!--              style="margin-left: 16px; cursor: pointer"-->
<!--              class="editSpan"-->
<!--              @click="recive(scope.row)"-->
<!--              >驳回</span-->
<!--            >-->
<!--          </div>-->
        </template>
      </el-table-column>
      <div class="bottomTable"></div>
    </PocTable>
    <PocTable
      ref="multipleTable"
      class="displayTable"
      show-summary
      v-loading="queryLoading"
      :data="tableData6"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      stripe
      :max-height="100000000000000000000000"
      size="mini"
      @selection-change="handleSelectionChange1"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      highlight-current-row
      :header-cell-style="getRowClass"
      :summary-method="getSummariesHc"
    >
      <el-table-column fixed="left" type="selection" width="55" />
      <el-table-column
        v-for="item in hcColumnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        :align="item.align"
        :show-overflow-tooltip="item.key!=='description'"
      >
        <!--           :sortable="item.sortable"-->
        <template slot-scope="scope">
          <span v-if="item.key === 'description'">
            <el-tooltip
              v-if="scope.row[item.key]"
              popper-class="workorder-reason-popper"
              effect="dark"
              :content="scope.row[item.key]"
              placement="top">
                 <span>{{ scope.row[item.key] | ellipsis}}</span>
                </el-tooltip>
            <span v-else>{{scope.row[item.key]}}</span>
          </span>
          <span v-else>{{
            item.dataType == 'number'
              ? currency(scope.row[item.key])
              : scope.row[item.key]
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-if="false"
        label="Operations"
        width="150"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            <span>
              <span
                class="editSpan"
                style="margin-right: 10px; cursor: pointer"
                v-if="adjustDis()"
                @click="edit(scope.row)"
                >调整</span
              >
<!--              <span-->
<!--                class="editSpan"-->
<!--                style="margin-right: 10px; cursor: pointer"-->
<!--                role="op131Submit"-->
<!--                v-permission-->
<!--                @click="editSubmit(scope.row)"-->
<!--                >提交</span-->
<!--              >-->
              <el-popconfirm
                title="确定删除吗？"
                style="margin-right: 10px; cursor: pointer"
                role="delete"
                v-permission
                @confirm="deleteOps(scope.row)"
              >
                <span class="editSpan" style="cursor: pointer" slot="reference"
                  >删除</span
                >
              </el-popconfirm>
            </span>
            <span
              class="editSpan pointer"
              v-if="adjustDis()"
              @click.stop="editHistory(scope.row)"
              >调整历史
            </span>
          </div>
          <!--                     -->
<!--          <div-->
<!--            v-else-if="scope.row.isToBp"-->
<!--            style="display: flex; margin-left: 22px"-->
<!--          >-->
<!--            <el-button class="editSpan" type="text" @click="chat(scope.row)"-->
<!--              >查看</el-button-->
<!--            >-->
<!--            <el-button class="editSpan" type="text" @click="accept(scope.row)"-->
<!--              >接受</el-button-->
<!--            >-->
<!--            <el-button class="editSpan" type="text" @click="recive(scope.row)"-->
<!--              >驳回</el-button-->
<!--            >-->
<!--          </div>-->
        </template>
      </el-table-column>
    </PocTable>
    <addIcChargeModal
      v-bind:periods="period"
      v-bind:versionId="versionid"
      v-bind:editType="editType"
      :is-summary="isSummary"
      v-if="newIcChargeFlag"
      @closeModal="closeModal"
    />
    <chargeOutDialog ref="chargeOutDialogRef" @close="filterQuery" />
    <ChargeOp131Dialog ref="ChargeOp131DialogRef" @close="filterQuery" />
  </div>
</template>

<script>
import chargeOutDialog from '@/views/salaryManagement/components/modal/ChargeOutDialog/ChargeOutDialog.vue'
import currency from 'currency.js'
import {
  changeOp131Year,
  ops131CostTableDataConfig,
  ops131HcTableDataConfig
} from '../../editionData/ops131tableData'
import addIcChargeModal from '@/views/salaryManagement/components/modal/addIcChargeModal.vue'
import addFreelancerModal from '@/views/salaryManagement/components/modal/addFreeLancherModal.vue'
import ChargeOp131Dialog from '@/views/salaryManagement/components/modal/ChargeOp131Dialog/ChargeOp131Dialog.vue'

import {
  accept, deleteOp131,
  eleteOp131Op151Op152ById,
  getHisOp131List,
  getOp131List,
  op131Submit,
  reject
} from "@/api/pctrakerop131";
import { getItem, setItem } from '@/utils/storage'
import { titleCase } from '@/utils/util'

const { Vuex } = $PCommon
const { mapGetters } = Vuex

export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {
    ChargeOp131Dialog,
    addIcChargeModal,
    addFreelancerModal,
    chargeOutDialog
  },

  props: {
    editionId: {
      type: Number
    },
    buttonActive: {
      type: Number
    },
    changeActive: {
      type: Number
    },
    indexYear: {
      type: String
    },
  },
  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 740 + 'px'
    return {
      period: {},
      maxHeight: h,
      queryLoading: false,

      tableData6: [
        {
          assigmentProjectLevel2: 'Edge',
          hfmCode: 'CN81',
          description: 'IC charge of Three Zhang',
          totalCost: 12000,
          janCost: 10000,
          febCost: 10000,
          marCost: 10000,
          aprCost: 10000,
          mayCost: 10000,
          junCost: 10000,
          julCost: 10000,
          augCost: 10000,
          sepCost: 10000,
          octCost: 10000,
          novCost: 10000,
          decCost: 10000,
          totalHc: 12000,
          janHc: 1,
          febHc: 2,
          marHc: 3,
          aprHc: 4,
          mayHc: 5,
          junHc: 6,
          julHc: 7,
          augHc: 8,
          sepHc: 9,
          octHc: 10,
          novHc: 11,
          decHc: 12,
          visible: true
        }
      ],
      costColumnConfig: ops131CostTableDataConfig,
      hcColumnConfig: ops131HcTableDataConfig,
      manageShow: false,
      jumpParams: {},
      selectedItems: [],
      newIcChargeFlag: false,
      versionid: '',
      editType: '',
      id: '',
      summaries: {
        totalCost: {},
        hcTotalCost: {}
      },
      filterConfig: {},
      submitStatus: '',
      isSummary: false,
      selectVal: [],
      selectVal1: []
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    }
  },
  computed: {
    columnOptions() {}
  },
  created() {
    this.versionid = this.$route.query.id
    this.id = this.$route.query.id
    this.editType = 'new'
    if (this.buttonActive == 2 && this.changeActive == 2) {
      this.isSummary = true
    } else {
      this.isSummary = false
    }
    changeOp131Year()
    this.submitStatus = this.$route.query.submitStatus
    // this.filterQuery()
  },
  methods: {
    adjustDis() {
      // if (this.buttonActive == '1') {
      //   return ['待提交'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '1') {
      //   return ['待提交', '待汇总'].includes(this.submitStatus)
      // } else if (this.buttonActive == '2' && this.changeActive == '2') {
      //   return ['已汇总', 'BPLeader驳回', 'Finance审批不通过'].includes(
      //     this.submitStatus
      //   )
      // } else {
      //   return false
      // }
      return true
    },
    accept(val) {
      const data = {
        id: val.id,
        versionId: this.versionid
      }
      accept(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('接受成功')
          this.filterQuery()
        }
      })
    },
    editSubmit(val) {
      const data = {
        id: val.id,
        versionId: this.versionid
      }
      op131Submit(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('提交成功')
          this.filterQuery()
        }
      })
    },
    async getOp1512Totalcost() {
      const data1 = {
        id: this.id,
        tabOneType: this.buttonActive - 1
      }
      if (this.buttonActive == '2') {
        if (this.changeActive == '1') {
          data1.tabOneType = 0
        } else if (this.changeActive == '2') {
          data1.tabOneType = 1
        }
      }
      const { data, code } = await this.$Api.salary.getOp1512Totalcost(data1)
      if (code === 200) {
        if (data.op131TotalCost) {
          this.summaries.totalCost = data.op131TotalCost
        }
        if (data.op131HcTotalCost) {
          this.summaries.hcTotalCost = data.op131HcTotalCost
        }
      }
    },
    recive(val) {
      const data = {
        id: val.id
      }
      reject(data).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('驳回成功')
          this.filterQuery()
        }
      })
    },
    chat(val) {
      this.period = val
      this.editType = 'chat'
      this.$store.dispatch('ChargeOut/changeChargeType', 'chat')
      // this.newIcChargeFlag = true
      const newDara = JSON.parse(val.icChargeFront)
      this.$store
        .dispatch('ChargeOut/formatCellDisplay', newDara)
        .then((res) => {
          if (res) {
            console.log(res)
            this.$store.dispatch('ChargeOut/changeTableData', res)
            this.$store.dispatch('ChargeOut/changeActive', 1)
            const data = {
              buttonActive: this.buttonActive,
              editionId: this.editionId,
              showButtonCall: true
            }
            this.$refs.chargeOutDialogRef.show(data)
          }
        })
    },
    editHistory(row) {
      setItem('pcTrackerOp111Row__yc', row.id)
      let tabOneType = ''
      if (this.$route.query.buttonActive == '2') {
        if (this.$route.query.changeActive == '2') {
          tabOneType = '1'
        } else {
          tabOneType = '0'
        }
      }
      const tabTwoType = 'op131'
      const id = row.id
      if (id) {
        this.$router.push({
          path: '/pcTracker/editHistory',
          query: {
            tabOneType,
            tabTwoType,
            id,
            target: 'op112',
            versionId: this.$route.query.id
          }
        })
      } else {
        this.$message.error('缺少id')
      }
    },
    currency(value) {
      return currency(value, { symbol: '' }).format()
    },
    newIcCharge() {
      this.period = {}
      this.newIcChargeFlag = true
    },
    getSummaries(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      let self = this
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 1) {
          // sums[index] = 'Total Intercompany Recharge(Cost) '
          sums[index] = 'Total Intercompany Recharge (Cost)'
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else if (7 < index && index < len -1) {
          if (this.selectVal.length > 0) {
            const values = this.selectVal.map((item) =>
              Number(item[column.property])
            )
            if (!values.every((value) => isNaN(value))) {
              sums[index] = self.currency(
                values.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              sums[index] = 'N/A'
            }
          } else {
            if (column.property.substring(0, 3) === 'tot') {
              sums[index] = this.currency(
                parseFloat(this.summaries.totalCost['salaryFullYear']) || 0
              )
            } else {
              let str = 'totalCost' + titleCase(column.property.substring(0, 3))
              sums[index] = this.currency(
                parseFloat(this.summaries.totalCost[str]) || 0
              )
            }
          }

          //如果是除了第一列和最后一列的其他列，则显示为空
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleSelectionChange1(val) {
      this.selectVal1 = val
    },
    getSummariesHc(param) {
      //此处打印param可以看到有两项，一项是columns，一项是data，最后一列可以通过columns.length获取到。
      const { columns, data } = param
      const len = columns.length
      const sums = []
      let self = this
      columns.forEach((column, index) => {
        //如果是第一列，则最后一行展示为“总计”两个字
        if (index === 1) {
          // sums[index] = 'Total Intercompany Recharge(Cost) '
          sums[index] = 'Total Intercompany Recharge (HC)'
          //如果是最后一列，索引为列数-1，则显示计算总和
        } else if (7 < index && index < len) {
          if (this.selectVal1.length > 0 && this.buttonActive == '1') {
            const values = this.selectVal1.map((item) =>
              Number(item[column.property])
            )
            if (!values.every((value) => isNaN(value))) {
              sums[index] = self.currency(
                values.reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
              )
            } else {
              sums[index] = 'N/A'
            }
          } else {
            if (column.property.substring(0, 3) === 'tot') {
              sums[index] = this.currency(
                parseFloat(this.summaries.hcTotalCost['salaryFullYear']) || 0
              )
            } else {
              let str = 'totalCost' + titleCase(column.property.substring(0, 3))
              sums[index] = this.currency(
                parseFloat(this.summaries.hcTotalCost[str]) || 0
              )
            }
          }

          //如果是除了第一列和最后一列的其他列，则显示为空
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    edit(val) {
      this.$refs.ChargeOp131DialogRef.show(val.id)
      // this.period = val
      // this.editType = 'edit'
      // this.$store.dispatch('ChargeOut/changeChargeType', 'edit')
      // // this.newIcChargeFlag = true
      // const newDara = JSON.parse(val.icChargeFront)
      // console.log(newDara)
      // this.$store
      //   .dispatch('ChargeOut/formatCellDisplay', newDara)
      //   .then((res) => {
      //     if (res) {
      //       console.log(res)
      //       this.$store.dispatch('ChargeOut/changeTableData', res)
      //       this.$store.dispatch('ChargeOut/changeActive', 1)
      //       const data = {
      //         buttonActive: this.buttonActive,
      //         editionId: this.editionId,
      //         showButtonCall: true
      //       }
      //       this.$refs.chargeOutDialogRef.show(data)
      //     }
      //   })
    },
    deleteOps(val) {
      console.log(val)
      deleteOp131({id:val.id}).then(res=>{
        if(res.code === 200){
          this.$message.success(res.msg)
          this.queryApi()
        }
      })
      // if (this.submitStatus === '已提交') {
      //   this.$message.warning('数据已提交，暂不可删除')
      //   return false
      // } else {
      //   const data = {
      //     id: val.id,
      //     type: 'op131',
      //     tabOneType: this.buttonActive - 1
      //   }
      //   eleteOp131Op151Op152ById(data).then((res) => {
      //     if (res && res.code === 200) {
      //       this.filterQuery()
      //     }
      //   })
      // }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === this.tableData6.length - 2) {
        return 'warning-row'
      }
    },

    closeModal() {
      this.newIcChargeFlag = false
      this.filterQuery()
    },

    getRowClass() {
      return 'background:#F1EAE0'
    },

    expendCounfig(row) {
      console.log('=========', row.label)
    },
    filterQuery(filterConfig) {
      this.filterConfig = filterConfig
      this.pageConfig.pageNum = 1
      this.getOp1512Totalcost()
      this.queryApi(filterConfig)
    },

    // 获取基础数据维护的列表
    async queryApi(filterConfig) {
      filterConfig = filterConfig || this.filterConfig
      const versiodInfo = {
        versionId: this.versionid
      }
      if (this.buttonActive != 3) {
        let type = ''
        if (this.buttonActive == 1) {
          type = '0'
        } else if (this.buttonActive == 2 && this.changeActive == 1) {
          type = '0'
        } else if (this.buttonActive == 2 && this.changeActive == 2) {
          type = '1'
        }
        this.queryLoading = true
        await getOp131List({
          ...filterConfig,
          ...this.pageConfig,
          ...versiodInfo,
          id:this.editionId,
          isRfVersion:this.buttonActive === 1,
          year:this.indexYear,
          type: type
        }).then((res) => {
          //jacob添加
          this.tableData6 = res.data.list ? res.data.list : []
          this.pageConfig.total = res.data.total || 0
          this.point()
          // this.tableData6.forEach((item) => {
          //   item.totalCost =
          //     item.janCost +
          //     item.febCost +
          //     item.marCost +
          //     item.aprCost +
          //     item.mayCost +
          //     item.junCost +
          //     item.julCost +
          //     item.augCost +
          //     item.sepCost +
          //     item.octCost +
          //     item.novCost +
          //     item.decCost
          //   item.totalHc =
          //     item.janHc +
          //     item.febHc +
          //     item.marHc +
          //     item.aprHc +
          //     item.mayHc +
          //     item.junHc +
          //     item.julHc +
          //     item.augHc +
          //     item.sepHc +
          //     item.octHc +
          //     item.novHc +
          //     item.decHc
          // })
        })
      } else {
        this.queryLoading = true
        await getHisOp131List({
          ...filterConfig,
          ...this.pageConfig,
          ...versiodInfo,
          type: ''
        }).then((res) => {
          //jacob添加
          this.tableData6 = res.data.list ? res.data.list : []
          this.pageConfig.total = res.data.total || 0
          this.point()
          // this.tableData6.forEach((item) => {
          //   item.totalCost =
          //     item.janCost +
          //     item.febCost +
          //     item.marCost +
          //     item.aprCost +
          //     item.mayCost +
          //     item.junCost +
          //     item.julCost +
          //     item.augCost +
          //     item.sepCost +
          //     item.octCost +
          //     item.novCost +
          //     item.decCost
          //   item.totalHc =
          //     item.janHc +
          //     item.febHc +
          //     item.marHc +
          //     item.aprHc +
          //     item.mayHc +
          //     item.junHc +
          //     item.julHc +
          //     item.augHc +
          //     item.sepHc +
          //     item.octHc +
          //     item.novHc +
          //     item.decHc
          // })
        })
      }

      this.queryLoading = false
    },
    point() {
      let pcTrackerOp111Row__yc = getItem('pcTrackerOp111Row__yc')
      if (pcTrackerOp111Row__yc) {
        let row = this.tableData6.find(
          (item) => Number(item.id) === Number(pcTrackerOp111Row__yc)
        )
        if (row) {
          this.$refs.multipleTable.setCurrentRow(row, true)
        }
      }
    },
    handleSelectionChange(val) {
      // 选的第一个
      this.selectVal = val
      // if (val.length) {
      //   this.selectedItems = val
      //   this.selecthcIdIds = this.selectedItems.map((v) => v.hcId).join()
      //   this.selectofferProposalIds = this.selectedItems
      //     .map((v) => v.offerProposalId)
      //     .join()
      // } else {
      //   this.selecthcIdIds = ''
      //   this.selectofferProposalIds = ''
      // }
    }
  },
  beforeDestroy() {},
  mounted() {
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
    let contentTable = document.getElementById('contentTable')
    let body = contentTable && contentTable.querySelector('.el-card__body')
    let bodyWrapper = body
      .querySelector('.poc-table')
      .querySelector('.el-table__body-wrapper')
    let bodyWrapper2 = body
      .querySelector('.displayTable')
      .querySelector('.el-table__body-wrapper')
    bodyWrapper.addEventListener('scroll', function (event) {
      let tableHeader = body.querySelector('.el-table__body-wrapper')
      bodyWrapper2.scrollLeft = bodyWrapper.scrollLeft
    })
    bodyWrapper2.addEventListener('scroll', function (event) {
      let tableHeader = body.querySelector('.el-table__body-wrapper')
      bodyWrapper.scrollLeft = bodyWrapper2.scrollLeft
    })
    body.addEventListener('scroll', function (event) {
      let tableHeader = body.querySelector('.el-table__body-wrapper')
      console.dir(body)
      console.dir(tableHeader)

      // console.log(body.scrollTop, tableHeader)
      // if (body.scrollTop >= 55) {
      //   tableHeader.style = 'position: fixed;top:239px;left:266px;z-index:99'
      // } else if (body.scrollTop <= 55) {
      //   tableHeader.style = 'position: null'
      // }
    })
  }
}
</script>

<style lang="scss" scoped>
.ops131 {
  margin-top: 16px;
  ::v-deep .el-table__body-wrapper {
    z-index: 2 !important;
  }
  :deep(.displayTable) {
    margin-top: 20px;
    //.el-table__header {
    //  display: none;
    //}
  }
  :deep(.positionTable) {
    position: relative;
    .el-table__header {
      //position: fixed;
    }
  }
  :deep(.el-table) {
    .el-table__header-wrapper {
      .el-table__cell {
        .cell {
          font-size: 10px;
          transform: scale(0.9);
          -webkit-transform: scale(0.9);
        }
      }
    }
    .bottomTable {
      height: 30px;
    }
  }
  :deep(.buttonActive1) {
    height: 15px;
    border-radius: 2px;
    margin-right: 6px;
    span {
      font-size: 10px;
      display: block;
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: white;
      position: relative;
      top: -5.5px;
    }
  }
  .positionIc {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}
.buttonBigunActive {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 20px;
}
.pointer {
  cursor: pointer;
}
.editSpan {
  font-size: 12px;
  color: #ba9765;
}
</style>
