<template>
  <div class="now-edition-page">
    <pocSlider
      minheight="60px"
      maxHeight="350px"
      btntype="primary"
      :is-show="isShow"
    >
      <el-form
        class="filter-form rpm-query-from"
        label-position="top"
        ref="formRef"
      >
        <div class="form-grid">
          <div v-if="[1,2,3,4].includes(chooseTab)">
            <el-form-item label="HFM Code">
              <el-select
                clearable
                v-model="filterConfig.hfmCode"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in entityHfmCodeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="[1,3,4].includes(chooseTab)">
            <el-form-item label="Assigment/project (Level2)">
              <el-select
                clearable
                v-model="filterConfig.assigmentProjectLevel2"
                filterable
                placeholder="请选择"
                class="industry-selector"
                @change="getLevel3List"
              >
                <el-option
                  v-for="item in assigmentProjectLevel2List"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="[1].includes(chooseTab)">
            <el-form-item label="Level 3">
              <el-select
                  clearable
                  v-model="filterConfig.level3"
                  filterable
                  placeholder="请选择"
                  class="industry-selector"
              >
                <el-option
                    v-for="item in level3List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="[1].includes(chooseTab)">
            <el-form-item label="BU">
              <el-select
                class="tags-select-input"
                clearable
                v-model="businessUnitName"
                multiple
                collapse-tags
                placeholder="请选择"
                @change="changeBusinessUnitName"
              >
                <el-option
                  v-for="item in businessUnitIdList"
                  :key="item.value + Math.random()"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="[1, 2, 3, 4].includes(chooseTab)">
            <el-form-item label="Chinese Name">
              <el-input
                placeholder="请输入Chinese Name"
                v-model="filterConfig.chineseName"
                class="industry-selector"
              >
              </el-input>
            </el-form-item>
          </div>
          <div v-if="[1, 2, 3, 4].includes(chooseTab)">
            <el-form-item label="English Name">
              <el-input
                placeholder="请输入English Name"
                v-model="filterConfig.englishName"
                class="industry-selector"
              >
              </el-input>
            </el-form-item>
          </div>
          <div v-if="[1,2, 3, 4].includes(chooseTab)">
            <el-form-item label="Title EN">
              <el-input
                placeholder="请输入Title EN"
                clearable
                v-model="filterConfig.titleEn"
              >
              </el-input>
            </el-form-item>
          </div>
<!--          <div v-if="[1].includes(chooseTab)">-->
<!--            <el-form-item label="HC Nature">-->
<!--              <el-select-->
<!--                clearable-->
<!--                v-model="filterConfig.hcNature"-->
<!--                filterable-->
<!--                placeholder="请选择"-->
<!--                class="industry-selector"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in hcNatureList2"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
          <div v-if="[1].includes(chooseTab)">
            <el-form-item label="HC Status">
              <el-select
                clearable
                v-model="filterConfig.hcStatus"
                filterable
                placeholder="请选择"
                class="industry-selector"
              >
                <el-option
                  v-for="item in hcNatureList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

<!--          <div v-if="[1].includes(chooseTab)">-->
<!--            <el-form-item label="Team">-->
<!--              <el-input-->
<!--                placeholder="请输入Team"-->
<!--                clearable-->
<!--                v-model="filterConfig.team"-->
<!--              >-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--          <div v-if="[1].includes(chooseTab)">-->
<!--            <el-form-item label="Local/Expat">-->
<!--              <el-select-->
<!--                clearable-->
<!--                v-model="filterConfig.localOrExpat"-->
<!--                filterable-->
<!--                placeholder="请选择"-->
<!--                class="industry-selector"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in localOrExpatList"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--          <div v-if="[1].includes(chooseTab)">-->
<!--            <el-form-item label="Location">-->
<!--              <el-select-->
<!--                clearable-->
<!--                v-model="filterConfig.location"-->
<!--                filterable-->
<!--                placeholder="请选择"-->
<!--                class="industry-selector"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in locationList"-->
<!--                  :key="item.workLocation"-->
<!--                  :label="item.workLocation"-->
<!--                  :value="item.workLocation"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--          <div v-if="[3, 4].includes(chooseTab)">-->
<!--            <el-form-item label="Department">-->
<!--              <el-input-->
<!--                clearable-->
<!--                placeholder="请输入"-->
<!--                v-model="filterConfig.department"-->
<!--              >-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--          <div v-if="[3, 4].includes(chooseTab)">-->
<!--            <el-form-item label="Client">-->
<!--              <el-input-->
<!--                clearable-->
<!--                placeholder="请输入"-->
<!--                v-model="filterConfig.client"-->
<!--              >-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </div>-->
        </div>
      </el-form>
    </pocSlider>
  </div>
</template>
<script>
import pocSlider from '@/components/poc-slider/poc-slider'
export default {
  name: 'nowEdition',
  components: { pocSlider },
  props: {
    chooseTab: {
      type: Number
    }
  },

  data() {
    return {
      upFlag: false,
      isShow: true,
      businessUnitName: '',
      locationList: [],
      filterConfig: {
        assigmentProjectLevel2: '',
        hcStatus: '',
        team: '',
        localOrExpat: '',
        englishName: '',
        chineseName: '',
        businessUnitName: '',
        versionId: '',
        type: 0,
        location: '',
        department: '',
        client: '',
        titleEn: '',
        level3: '',
      },
      assigmentProjectLevel2List: [],
      level3List: [],
      entityHfmCodeList: [], //entityHfmCodeList
      businessUnitIdList: [],
      /*
      TBH Unapproved、HC Approved、Deleted、Active、Recruiting
       */
      hcNatureList: [
        {
          label: 'TBH Unapproved',
          value: 'TBH Unapproved'
        },
        {
          label: 'HC Approved',
          value: 'HC Approved'
        },
        {
          label: 'Recruiting',
          value: 'Recruiting'
        },
        {
          label: 'Deleted',
          value: 'Deleted'
        },
        {
          label: 'Active',
          value: 'Active'
        }
      ],
      hcNatureList2: [
        {
          label: 'New Hire',
          value: 'New Hire'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        },
        {
          label: 'Transfer in',
          value: 'Transfer in'
        },
        {
          label: 'Transfer in with HC',
          value: 'Transfer in with HC'
        },
        {
          label: 'Transfer in without HC',
          value: 'Transfer in without HC'
        },
        {
          label: 'Transfer out with HC',
          value: 'Transfer out with HC'
        },
        {
          label: 'Transfer out without HC',
          value: 'Transfer out without HC'
        },
        {
          label: 'HC Delete',
          value: 'HC Delete'
        }
      ],
      dialogVisible: false,
      localOrExpatList: [
        {
          label: 'Expat',
          value: 'Expat'
        },
        {
          label: 'Local',
          value: 'Local'
        }
      ],
      props: { multiple: true }
    }
  },
  computed: {},
  watch: {
    chooseTab(newValue, oldValue) {
      if (newValue == 1) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  },
  created() {
    this.getAssigmentProjectLevel2()
    this.getLevel3List()
    this.getEntityHfmCode()
    this.getDepartmentIdList()
    this.getLocationList()
  },
  methods: {
    changeBusinessUnitName() {
      this.filterConfig.businessUnitName = this.businessUnitName.join(',')
      console.log('businessUnitName', this.filterConfig.businessUnitName)
    },
    getFilters() {
      return this.filterConfig
    },
    async getAssigmentProjectLevel2() {
      const { code, data } =
        await this.$Api.induction.getAssigmentProjectLevel2({})
      if (code === 200) {
        this.assigmentProjectLevel2List = data
      }
    },
    async getLevel3List(level2id) {
      const { code, data } =
        await this.$Api.induction.getLevel3({level2id:level2id})
      if (code === 200) {
        this.level3List = data
      }
    },
    async getLocationList() {
      const { code, data } = await this.$Api.induction.getLocation({})
      if (code === 200) {
        this.locationList = data
      }
    },
    async getEntityHfmCode() {
      const { code, data } = await this.$Api.induction.getEntityHfmCode({})
      if (code === 200) {
        this.entityHfmCodeList = data
      }
    },
    async getDepartmentIdList() {
      const { code, data } =
        await this.$Api.induction.buList({})
      if (code === 200) {
        this.businessUnitIdList = data
      }
    },
    unfold() {
      this.dialogVisible = true
    },
    upRow() {
      this.upFlag = !this.upFlag
    },
    refreshFilter() {
      this.filterConfig = {
        assigmentProjectLevel2: '',
        hcStatus: '',
        team: '',
        localOrExpat: '',
        englishName: '',
        chineseName: '',
        businessUnitName: '',
        location: '',
        department: '',
        client: '',
        titleEn: '',
        level3: '',
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  padding-right: 16px;
  margin-bottom: 16px !important;
}
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 175px);
  grid-template-rows: repeat(auto-fill, 58px);
  grid-row-gap: 12px;
  grid-column-gap: 16px;
}
.AlCenter {
  align-items: center;
}
.industry-selector {
  width: 100%;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.iconImg {
  width: 16px;
  height: 16px;
}
.flex {
  display: flex;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.upWord {
  color: #ba9765;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  span {
    font-weight: 400;
    color: #303133;
  }
}
.up {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(186, 151, 101, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .strangle {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: transparent transparent #ba9765 transparent;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3px;
    &.down {
      border-color: #ba9765 transparent transparent transparent;
      top: 3px;
    }
  }
}
::v-deep .el-form .el-form-item {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
}
::v-deep .poc-slider .poc-slider-btns .el-button {
  margin-top: 28px !important;
}
::v-deep .tags-select-input {
  .el-select__tags {
    white-space: nowrap;
    overflow: hidden;
    flex-wrap: nowrap;
  }
  .el-select__tags-text {
    display: inline-block;
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss" scoped>
.now-edition-page {
  margin-top: 16px;
  .el-table__header-wrapper .el-checkbox {
    display: none;
  }
  .el-card__header {
    border-bottom: none;
  }
}
</style>
