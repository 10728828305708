<template>
  <div class="assignModal">
    <el-dialog
      :visible.sync="dialogVisible"
      width="977px"
      destroy-on-close
      :title="modalTitle"
      :before-close="close"
    >
      <div style="text-align: center; font-size: 20px">
        <div class="selectBox flexRow AlCenter">
          <div class="flex-1">
            <el-form class="flexRow">
              <div class="flex-1">
                <el-form-item label="Name/tel" class="w100 pr24">
                  <el-input
                    placeholder="请输入Name/tel"
                    v-model="selectEditMsg.userName"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="refreshBox">
            <el-button
              role="export"
              :loading="queryLoading"
              @click="initQuery"
              type="primary"
              >查询</el-button
            >
            <el-button @click="refresh">刷新</el-button>
          </div>
        </div>

        <PocTable
          v-loading="queryLoading"
          :data="tableData"
          stripe
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          hidepage
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
          :max-height="maxHeight"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          row-key="id"
        >
          <el-table-column
            type="selection"
            width="30"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sortable="item.sortable"
            :width="item.width"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </PocTable>
      </div>
      <div style="text-align: right; margin-top: 38px">
        <el-button @click="close" style="margin-bottom: 20px">取 消</el-button>
        <el-button type="primary" @click="sure" style="margin-bottom: 20px"
          >确 定</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const { Vuex } = $PCommon
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: {},
  props: {
    selectParentIds: {
      type: [String, Number]
    },
    userRole: {},
    type: {}
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 440 + 'px'
    return {
      maxHeight: h,
      tableData: [],
      modalTitle: '',
      dialogVisible: false,
      queryLoading: false,
      selectEditMsg: {
        userName: '',
        hfmcode: ''
      },
      columnConfig: [
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'userName',
          name: '用户名',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'roleName',
          name: '岗位',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'mobile',
          name: '手机号',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'email',
          name: '邮箱',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        }
      ]
    }
  },
  computed: {
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    }
  },

  async created() {},
  methods: {
    async show(fromSumType) {
      this.fromSumType = fromSumType
      this.dialogVisible = true
      if (fromSumType == 'nowSubmit') {
        this.modalTitle = '选择BP'
        await this.getFinanceList('3')
        // 1-1
      } else if (fromSumType == 'submitBPLeader') {
        //第一次进来弹框选择HRBP  。HRBP进来选择FInance
        this.modalTitle = '选择Finance'
        // 1-1
        await this.getFinanceList('1')
      } else if (fromSumType == 'submitFinance') {
        this.modalTitle = '选择BRBP Leader'
        await this.getFinanceList('2')
        // 1-多
      } else {
        // Finance角色不用弹框
      }
    },
    //获取BPList
    async getBPList() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.getBPList({
        ...this.selectEditMsg
      })
      if (code === 200) {
        this.tableData = data
      }
      this.queryLoading = false
    },
    // pctrackerVersion/
    async getFinanceList(type) {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.getFinanceList({
        ...this.selectEditMsg,
        userType: type
      })
      if (code === 200) {
        this.tableData = data
        // this.pageConfig.total = data.total
        this.$forceUpdate()
      }
      this.queryLoading = false
    },
    sure() {
      console.log('999999', this.fromSumType)
      if (this.fromSumType == 'nowSubmit') {
        this.nowSubmitSum()
      } else if (this.fromSumType == 'submitFinance') {
        //BPLeader提交BPl
        this.submitSum()
      } else {
        //Finance提交
        this.BPLeaderSubmit()
      }
    },
    async submitSum() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.submitSum({
        id: Number(this.selectParentIds) || 0,
        financeIds: this.selectSonIds,
        type: 'bpLeader'
      })
      if (code === 200) {
        this.showMessage('successFlag')

        this.close()
      } else {
        this.showMessage('')
      }
      this.queryLoading = false
    },
    async nowSubmitSum() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.nowSubmitSum({
        id: Number(this.selectParentIds) || 0,
        bpLeaderId: this.selectSonIds,
        type: this.type
      })
      if (code === 200) {
        this.showMessage('successFlag')

        this.close()
      } else {
        this.showMessage('')
      }
      this.queryLoading = false
    },
    async BPLeaderSubmit() {
      this.queryLoading = true
      const { data, code } = await this.$Api.salary.BPLeaderSubmit({
        versionId: Number(this.selectParentIds) || 0,
        financeIds: this.selectSonIds,
        type: 'finance'
      })
      if (code === 200) {
        this.showMessage('successFlag')

        this.close()
      }
      this.queryLoading = false
    },

    showMessage(flag) {
      let msg = ''
      if (this.userRole == '1') {
        msg = '提交'
      } else {
        msg = '通过'
      }
      if (flag == 'successFlag') {
        this.$message({
          type: 'success',
          message: msg + '成功'
        })
      }
    },
    close() {
      this.dialogVisible = false
      this.$emit('closeModal')
    },
    refresh() {
      this.selectEditMsg = {
        userName: '',
        hfmcode: ''
      }
      this.initQuery()
    },
    initQuery() {
      if (this.fromSumType == 'nowSubmit') {
        this.modalTitle = '选择BP'
        this.getFinanceList('3')
        // 1-1
      } else if (this.fromSumType == 'submitBPLeader') {
        //第一次进来弹框选择HRBP  。HRBP进来选择FInance
        this.modalTitle = '选择Finance'
        // 1-1
        this.getFinanceList('1')
      } else if (this.fromSumType == 'submitFinance') {
        this.modalTitle = '选择BRBP Leader'
        this.getFinanceList('2')
        // 1-多
      } else {
        // Finance角色不用弹框
      }
    },
    handleSelectionChange(val) {
      // 选的第一个
      if (
        this.fromSumType == 'nowSubmit' ||
        this.fromSumType == 'submitBPLeader'
      ) {
        //当前版本的提交&&BP leader--此处只能选一个

        this.tableData.forEach((item) => {
          if (val[val.length - 1] === item) {
            this.$refs.multipleTable.toggleRowSelection(item, true)
          } else {
            this.$refs.multipleTable.toggleRowSelection(item, false)
          }
        })
        this.selectSonIds = val[0].id
        console.log('666666666666', this.selectSonIds)
      } else {
        //
        if (val.length) {
          this.selectedItems = val
          this.selectSonIds = this.selectedItems.map((v) => v.id).join()
        } else {
          this.selectSonIds = ''
        }
      }
    }
  },
  beforeDestroy() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.modalTitle {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-bottom: 1px solid #777;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.refreshBox {
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.buttonunActive {
  width: 68px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ba9765;
  border-radius: 4px;
  color: #ba9765;
  font-size: 14px;
  line-height: 15px;
}
.buttonActive {
  width: 68px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}
</style>
<style lang="scss">
.assignModal {
  .el-dialog__title {
    line-height: 24px;
    font-size: 21px;
    color: #303133;
  }
  .el-dialog .el-dialog__body {
    padding: 0 20px 20px 20px;
  }
  .el-button + .el-button {
    margin-left: 12px;
  }
  .el-select {
    width: 100%;
  }
}
</style>
