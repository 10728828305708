<template>
  <el-dialog
    title="IC Charge"
    :visible.sync="dialogTableVisible"
    v-if="dialogTableVisible"
    width="75%"
    custom-class="ChargeOp131"
    append-to-body
    :before-close="close"
  >
    <div>
      <SelectHc />
     <div class="btn-box">
       <el-button @click="close" style="margin-left: 12px"
       >取消</el-button>
       <el-button type="primary" @click="submit" style="margin-left: 12px"
       >确定</el-button>
     </div>
    </div>
  </el-dialog>
</template>
<script>

import SelectHc from "@/views/salaryManagement/components/modal/ChargeOutDialog/components/SelectHc.vue";
import { mapState } from "vuex";

export default {
  name: "CharageHcTable",
  components: { SelectHc },
  data(){
    return{
      dialogTableVisible:false,
    }
  },
  computed:{
    ...mapState(['ChargeOut']),
  },
  methods:{
    close(){
      this.dialogTableVisible = false
    },
    show(){
      this.dialogTableVisible = true
    },
    submit(){
      if(this.ChargeOut.selectVal.length > 1){
        this.$message.error('只能选择一个HC')
        return
      }
      this.$emit('selectHc')
      this.close()
    }
  }

};
</script>

<style scoped lang="scss">
.btn-box{
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: flex-end;
  button{
    position: relative;
    top: -15px;
  }
}
</style>
